<template>
    <div class="pagination">
        <p @click="setPage(this.currentPage*1 - 1)" :class="this.currentPage <= 1 ? 'disable' : ''" >
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#555" alt="앞페이지로 이동">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"/>
            </svg>
        </p>
        <div class="pages">
            <!-- Math.min( 최대 페이지 수 - 이전 페이지 수, 한 화면 최대 페이지수 )만큼 반복 -->
            <p class="nums"
                v-for="i in Math.min( this.maxPage - this.beforePage, this.maxShowPage)"
                :key="i"
                
                :class="(this.beforePage + i) ==this.currentPage ? 'current' : ''"

                @click="setPage(this.calcPage(i))"
            >
                {{this.calcPage(i)}}
            </p>
        </div>
        <p @click="setPage(this.currentPage*1 + 1)" :class="this.currentPage >= this.maxPage ? 'disable' : ''">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#555" alt="뒤페이지로 이동">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>
            </svg>
        </p>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'currentPage',
        event: 'change'
    },
    props : {
        currentPage : Number,   // 현재 페이지
        listLength : Number,    // 리스트 총 개수
        maxShowPage : Number,   // 한 화면 최대 페이지수
        maxShowList : Number,   // 한 페이지에 보여질 리스트 수
    },
    data() {
        return {
        }
    },
    computed : {
        maxPage(){    // 총 페이지 수
            return this.listLength == 0 ? 1
                : Math.ceil(this.listLength/this.maxShowList);
        },
        beforePage(){   // 이전화면 페이지들 개수
            return this.currentPage <= this.maxShowPage ? 0
                : Math.floor((this.currentPage-1)/this.maxShowPage) * this.maxShowPage;
        },
    },
    methods : {
        // 페이지 변경
        setPage(num){
            // 0보단 크고, 최대 페이지 이하일 때
            if(num > 0 && num <= this.maxPage){
                this.$emit('setPage', num)
            }
        },
        // 보여질 페이지 숫자 계산(maxShowPage에서 다음으로 넘어갔을 때)
        calcPage(i){
            return Math.floor(this.beforePage + i);
        }
    }
}
</script>

<style scoped>
.pagination{
    display: flex;
    margin: 1rem auto;
    justify-content: center;
}
.pagination p{
    display: inline-block;
    width: 3rem;
    height: 3rem;
    user-select: none;
    text-align: center;
}
.pagination p svg{
    width: 100%;
    height: 100%;
}
.pagination p:hover svg{
    fill: var(--primary-color);
}
.pagination p.disable svg{
    fill: var(--base-color1);
}
.pagination p.disable:hover svg{
    fill: var(--base-color1);
}
.pagination .pages p:hover{
    background-color: var(--point-color);
    color: #fff;
}

.pagination .pages .current{
    background-color: var(--primary-color1);
    color: #fff;
}

</style>