<template>
    <div class="content_container mypage">
        <div class="inner">
            <!-- 좌측 메뉴 -->
            <MypageLeftTab />

            <!-- 홈 -->
            <MypageHome v-if="!this.page" />

            <!-- 회원정보 관리 -->
            <MypageUserInfo v-if="this.page == 'MypageUserInfo'" />

            <!-- 프로필 관리 -->
            <MypagePartnerInfo v-if="this.page == 'MypagePartnerInfo'" />

            <!-- 거래 관리 (판매자) -->
            <ManageMatching v-if="this.page == 'ManageMatching'" />
            <SupplySeller v-if="this.page == 'SupplySeller'" />
            <RequestSeller v-if="this.page == 'RequestSeller'" />
            <ProposalSeller v-if="this.page == 'ProposalSeller'" />
            <CompleteSeller v-if="this.page == 'CompleteSeller'" />

            <!-- 샘플 관리 (판매자) -->
            <SampleSeller v-if="this.page == 'SampleSeller'" />

            <!-- 거래 관리 (공급자) -->
            <ManageProduct v-if="this.page == 'ManageProduct'" />
            <SupplyProvider v-if="this.page == 'SupplyProvider'" />
            <RequestProvider v-if="this.page == 'RequestProvider'" />
            <ProposalProvider v-if="this.page == 'ProposalProvider'" />
            <CompleteProvider v-if="this.page == 'CompleteProvider'" />

            <!-- 광고 관리 - 공급자 -->
            <AdRegistration v-if="this.page == 'AdRegistration'" />
            <AdDetail v-if="this.page == 'AdDetail'" />
            <!-- 결제 관리 -->
            <MyToken v-if="this.page == 'myToken'" />
            <CardManage v-if="this.page == 'cardManage'" />
            <AccountManage v-if="this.page == 'accountManage'" />

            <!-- 결제 관리 - 계좌관리 (공급자) -->

            <!-- 토큰 구매 -->
            <MypageBuyToken v-if="this.page == 'MypageBuyToken'" />

            <!-- 스토어 관리 - 공급자 -->
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import MypageLeftTab from '@/components/Mypage/MypageLeftTab';
import MypageHome from '@/components/Mypage/MypageHome';
import MypageUserInfo from '@/components/Mypage/MypageUserInfo';
import MypagePartnerInfo from '@/components/Mypage/MypagePartnerInfo';
import ManageMatching from '@/components/Mypage/Seller/ManageMatching';
import SupplySeller from '@/components/Mypage/Seller/SupplySeller';
import RequestSeller from '@/components/Mypage/Seller/RequestSeller';
import ProposalSeller from '@/components/Mypage/Seller/ProposalSeller';
import CompleteSeller from '@/components/Mypage/Seller/CompleteSeller';
import SampleSeller from '@/components/Mypage/Seller/SampleSeller';
import ManageProduct from '@/components/Mypage/Provider/ManageProduct';
import SupplyProvider from '@/components/Mypage/Provider/SupplyProvider';
import RequestProvider from '@/components/Mypage/Provider/RequestProvider';
import ProposalProvider from '@/components/Mypage/Provider/ProposalProvider';
import CompleteProvider from '@/components/Mypage/Provider/CompleteProvider';
import MyToken from '@/components/Mypage/PayManage/MyToken';
import CardManage from '@/components/Mypage/PayManage/CardManage';
import AccountManage from '@/components/Mypage/PayManage/AccountManage';
import MypageBuyToken from '@/components/Mypage/PayManage/MypageBuyToken';
import AdRegistration from '@/components/Mypage/Ad/AdRegistration';
import AdDetail from '@/components/Mypage/Ad/AdDetail';

export default {
    props: {
        page: String, // vue-router 파라미터를 props로 받음
    },
    components: {
        MypageLeftTab, // 좌측 메뉴
        MypageHome, // 마이페이지 홈
        MypageUserInfo, // 회원정보 관리
        MypagePartnerInfo, // 프로필 관리
        ManageMatching, // 매칭 관리 - (판매자)
        SupplySeller, // 공급가 요청 - (판매자)
        RequestSeller, // 제안한 거래 - (판매자)
        ProposalSeller, // 제안받은 거래 - (판매자)
        CompleteSeller, // 완료된 거래 - (판매자)
        SampleSeller, // 샘플거래 - (판매자)
        ManageProduct, // 상품관리 - (공급자)
        SupplyProvider, // 공급가 요청 - (공급자)
        RequestProvider, // 제안한 거래 - (공급자)
        ProposalProvider, // 제안받은 거래 - (공급자)
        CompleteProvider, // 완료된 거래 - (공급자)
        MyToken, // 마이토큰
        CardManage, // 카드 관리
        AccountManage, // 계좌 관리
        MypageBuyToken, // 토큰 구매
        AdRegistration,
        AdDetail,
    },
    computed: {
        ...mapState('user', ['user']),
    },
    watch: {
        $route(to) {
            if (to.path === '/mypage/MypageUserInfo') {
                this.$store.state.checkPass = false;
            }
        },
    },
    methods: {},
};
</script>

<style src="@/assets/css/layout_mypage.css"></style>
