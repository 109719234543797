<template>
    <div class="payManage accountManage">
        <div>
            <h1>내 계좌 관리</h1>
            <!-- 공급자 - 은행 계좌 시작 -->
            <div class="info">
                <div class="fill">
                    <h2 class="">내 계좌 정보</h2>
                    <div class="fill_select">
                        <h1>은행<span class="star">*</span></h1>
                        <select class="bank" v-model="bank" id="bank">
                            <option value="">선택</option>
                            <option
                                v-for="(item, i) in this.basicData.bank_arr"
                                :key="i" :value="item.bank_pk"
                            >
                                {{ item.content }}
                            </option>
                        </select>
                    </div>

                    <div class="fill_text">
                        <h1>예금주<span class="star">*</span></h1>
                        <input
                            type="text"
                            id="accholder"
                            placeholder="ex) 홍길동"
                            v-model="accholder"
                            maxlength="15"
                        />
                    </div>

                    <div class="fill_text">
                        <h1>계좌 번호<span class="star">*</span></h1>
                        <input
                            type="text"
                            id="accnum"
                            placeholder="숫자만 입력해주세요"
                            v-model="accnum"
                            maxlength="18"
                        />
                    </div>
                </div>
                <div class="button_wrap">
                    <button class="common_button"
                        :disabled="isUnchanged"
                        @click="saveAccount"
                    >
                        수정저장
                    </button>
                </div>
            </div>
            <!-- 공급자 - 은행 계좌 끝 -->
        </div>
    </div>
</template>

<script>
import { fetchData } from "@/utils/fetchData";
import { postData } from "@/utils/postData";

export default {
    mixins: [ fetchData, postData ],
    watch: {
        // 예금주
        accholder(newName) {
            // 특수문자 제거
            this.accholder = newName.replace(/[^가-힣a-zA-Z0-9]/g, "");
        },
        // 계좌번호
        accnum(newNum) {
            this.accnum = newNum.replace(/[^0-9]/g, ""); //숫자만
        },
    },
    computed: {
        isUnchanged(){
            return (this.bank == this.basicData.bank
                && this.accholder == this.basicData.accholder
                && this.accnum == this.basicData.accnum);
        }
    },
    data(){
        return {
            bank: "", // 공급자 - 은행 (*)
            accholder: "", // 공급자 - 예금주 (*)
            accnum: "", // 공급자 - 계좌 번호 (*)

            basicData : { 
                bank_arr: [],
                bank: "",
                accholder: "",
                accnum: "",
            },
        }
    },
    created(){
        this.fetchBasicData();
    },
    methods: {
        // 기본 목록 데이터 불러오기
        fetchBasicData() {
            this.fetchData("/getAccount", (data) => {
                this.basicData = data.account;
                this.bank = this.basicData.bank ?? '';
                this.accholder = this.basicData.accholder ?? '';
                this.accnum = this.basicData.accnum ?? '';
            });
        },
        saveAccount(){
            if(!this.bank || !this.accholder || !this.accnum ){
                this.$store.commit("alert", "필수 정보를 입력해주세요");
            } else if(!this.isUnchanged) {
                this.postData("/saveAccount", {
                    bank_pk: this.bank,
                    accholder: this.accholder,
                    accnum: this.accnum
                }, () => {
                    this.$store.commit("alert", "계좌 정보를 저장하였습니다.");
                    this.fetchBasicData();
                });
            }
            
        }
    }
}
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_pay.css"></style>