<template>
    <!-- 상품관리 리스트 시작 -->
    <div class="right_con">
        <div class="common_table p_manage">
            <ul class="top">
                <li class="product">상품명</li>
                <li class="w100">공급가</li>
                <li class="w100">공개여부</li>
                <li class="w80">공개요청 수</li>
                <li class="w80">신청자 수</li>
                <li class="w80">상태</li>
                <li class="w100">마감일</li>
                <!-- <th class="date">등록일</th> -->
                <li class="w60">관리</li>
            </ul>
            <ul v-for="(item, i) in sliceList" :key="i" class="content">
                <li class="product">
                    <p>{{ item.name }}</p>
                </li>
                <li class="w100 sub">
                    <p>
                        {{ this.numberFormat(item.supply_price) }}
                    </p>
                </li>
                <li class="w100 sub">
                    <p>
                        {{ item.supply_price_exposure_yn }}
                    </p>
                </li>
                <li class="w80 sub">
                    <p>
                        {{ this.numberFormat(item.exposure_request_count) }}
                    </p>
                </li>
                <li class="w80 sub">
                    <p>
                        {{ this.numberFormat(item.request_count) }}
                    </p>
                </li>
                <li class="w80 sub">
                    <p>
                        {{ item.state }}
                    </p>
                </li>
                <li class="w100 sub">
                    <p>
                        {{ this.dateFormat(item.finish_date) }}
                    </p>
                </li>
                <li class="w60 icon" @click="openPopup(item.product_pk)">
                    <img src="@/assets/images/mypage/icon_manage.svg" alt="관리" />
                </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>등록 상품이 없습니다.</li>
            </ul>
            <div class="button_wrapper">
                <button
                    class="sub_button addNew"
                    @click="()=>{this.$router.push('/register/provider')}"
                >
                    새 상품 등록
                </button>
            </div>
            <BoardPagination
                :listLength="this.list.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
        </div>
    </div>
    <!-- 상품관리 리스트 끝 -->

    <!--팝업 시작-->
    <div class="screen_filter" v-if="this.showPopup"></div>
    <div class="popup popup_provider" v-if="this.showPopup">
        <button type="button" @click="closePopup">
            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
        </button>
        <div class="inner">
            <!--상품명, 버튼 시작-->
            <div class="product_title">
                <div class="user user_seller">
                    <span>요청자 수</span>
                    <p>{{ this.numberFormat(this.popupData.request_count) }} 명</p>
                </div>
                <div class="btn_wrap">
                    <button type="button" class="common_button" @click="modifyProduct(this.popupData.product_pk)">
                        <p>상품 수정</p>
                    </button>
                    <button
                        type="button"
                        class="common_button"
                        @click="saveAllExposure(true, this.popupData.product_pk)"
                    >
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/icon_done_w.svg" alt="all" />
                        </div>
                        <p>전체승인</p>
                    </button>
                    <button type="button" class="sub_button" @click="saveAllExposure(false, this.popupData.product_pk)">
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
                        </div>
                        <p>전체거부</p>
                    </button>
                </div>
                <h1>{{ this.popupData.product_name }}</h1>
            </div>
            <!--상품명, 버튼 끝-->

            <!--내용 시작-->
            <div class="product">
                <div class="ask">
                    <div class="ask_icon">
                        <img src="@/assets/images/common/icon_price_w.svg" alt="ticket" />
                    </div>
                    <h1>공급가 요청 처리</h1>
                    <p>
                        요청한 판매자의 프로필을 확인하시고 <br />
                        승인된 파트너에게 공급가를 공개하실 수 있습니다.
                    </p>
                </div>

                <div class="list_wrap">
                    <div class="common_table">
                        <ul v-for="(item, i) in this.popupData.list" :key="i">
                                <li class="profile w100">
                                    <div>
                                        <img
                                            v-if="item.original_name"
                                            :src="item.path + item.save_name"
                                            :alt="item.original_name"
                                        />
                                        <img v-else src="@/assets/images/common/charactor_01.png" />
                                    </div>
                                </li>
                                <li class="seller">{{ item.name }}</li>
                                <li class="w100">{{ this.numberFormat(item.token) }} 개</li>
                                <li class="w100">{{ this.dateFormat(item.enrollment_date) }}</li>
                                <li class="w110 button">
                                    <button
                                        @click="saveExposure(true, item.exposure_request_pk, i)"
                                        class="common_button"
                                    >
                                        승인
                                    </button>
                                    <button
                                        @click="saveExposure(false, item.exposure_request_pk, i)"
                                        class="sub_button"
                                    >
                                        거부
                                    </button>
                                </li>
                        </ul>
                        <ul v-if="this.popupData.list.length == 0" class="default">
                            <li>공개 요청이 없습니다.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!--내용 끝-->
        </div>
        <!--팝업 끝-->
    </div>
    <!--팝업 끝-->
</template>

<script>
import BoardPagination from '@/components/BoardPagination'
import { mapState } from "vuex";
import { fetchData } from "@/utils/fetchData";
import { postData } from "@/utils/postData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";

export default {
    mixins: [fetchData, postData, numberFormat, dateFormat],
    components : {
        BoardPagination,
    },
    computed: {
        ...mapState("user", ["user"]),
        sliceList(){
            return this.list.length == 0 ? [] : this.list.slice( (this.currentPage-1)*this.maxShowList, this.currentPage*this.maxShowList );
        },
    },
    data() {
        return {
            showPopup: false,

            list: [],
            /* 
        [{
          exposure_request_count: 0,
          finish_date: new Date().toLocaleString(), // "2021-12-28 00:00:00"
          name: "",
          product_pk: 0,
          request_count: 0,
          state: "", // 매칭중, 마감
          supply_price: 0,
          supply_price_exposure_yn: "", // 회원공개
        }]
      */
            popupData: {
                list: [],
            },

            currentPage : 1,    // 현재 페이지
            maxShowList : 10,   // 한 페이지 보일 최대 리스트수
            maxShowPage : 5,    // 한 화면에 보일 최대 페이지수
        };
    },
    created() {
        this.fetchListData();
    },
    methods: {
        fetchListData() {
            this.fetchData(
                "/getMyProduct",
                (data) => {
                    this.list = data.list.sort((a,b)=>{
                        const a_date = new Date(a.finish_date);
                        const b_date = new Date(b.finish_date);
                        return a_date > b_date ? -1 : a_date < b_date ? 1 : 0;
                    }).sort((a,b)=>{
                        const a_state = a.state == '매칭중' ? 1 : 0;
                        const b_state = b.state == '매칭중' ? 1 : 0;
                        return b_state - a_state;
                    });
                },
            );
        },
        openPopup(pk) {
            this.fetchData(
                `/getMyProductExposure/${pk}`,
                (data) => {
                    this.popupData = data;
                },
            );
            this.showPopup = true;
        },
        saveExposure(state, exposure_request_pk, i) {
            this.postData(
                "/saveExposure",
                {
                    state: state,
                    exposure_request_pk: exposure_request_pk,
                },
                () => {
                    this.popupData.list.splice(i, 1);
                },
            );
        },
        saveAllExposure(state, product_pk) {
            this.postData(
                "/saveAllExposure",
                {
                    product_pk: product_pk,
                    state: state,
                },
                () => {
                    this.popupData.list = [];
                },
            );
        },
        // 페이지 변경
        setPage(num){
            this.currentPage = num;
        },
        closePopup() {
            this.showPopup = false;
            this.popupData = {
                list: [],
            };
        },
        modifyProduct(pk) {
            this.$store.commit("setUpdate", true);
            this.$store.dispatch("UPDATE_PRODUCT", pk);
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_matching.css"></style>
<style scoped src="@/assets/css/components/commonPopup_v2.css"></style>
