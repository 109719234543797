<template>
    <!-- <UserCheck v-if="!this.$store.state.checkPass"/>  -->
    <!-- <div v-if="this.$store.state.checkPass" class="mypage_userinfo fill"> -->
    <div class="mypage_userinfo fill">
        <!--회원 정보 관리 시작-->
        <div class="info_wrap">
            <!-- <h1 class="fill_box_title">회원정보 관리</h1> -->

            <!--가입유형 시작-->
            <div class="register_type_wrap">
                <h1 class="fill_box_title">가입유형</h1>
                <div class="icon_box_wrap">
                    <div class="icon_box" :class="this.user.sign_path == 'google' ? 'used' : ''">
                        <img src="@/assets/images/login/ci_google.svg" alt="google" />
                    </div>
                    <div class="icon_box" :class="this.user.sign_path == 'naver' ? 'used' : ''">
                        <img src="@/assets/images/login/ci_naver.svg" alt="naver" />
                    </div>
                    <div class="icon_box" :class="this.user.sign_path == 'kakao' ? 'used' : ''">
                        <img src="@/assets/images/login/ci_kakao.svg" alt="kakao" />
                    </div>
                    <div class="icon_box" :class="this.user.sign_path == '메타바이셀' ? 'used' : ''">
                        <img src="@/assets/images/login/icon_email.svg" alt="email" />
                    </div>
                </div>
            </div>
            <!--가입유형 끝-->

            <!--메일 시작-->
            <div class="mail_wrap">
                <h1 class="fill_box_title">메일</h1>
                <div class="fill_text">
                    <input
                        type="text"
                        class="email"
                        placeholder="identity@naver.com"
                        :value="this.user.email"
                        disabled
                    />
                    <!-- <p>identity@naver.com</p> -->
                </div>
            </div>
            <!--메일 끝-->

            <!--개인정보 유효기간 시작-->
            <!-- <div class="term_wrap fill_check">
            <h1 class="fill_box_title">개인정보 유효 기간</h1>
            <div class="type">
                <label>
                    <input type="checkbox">
                    1년
                </label>
                <label>
                    <input type="checkbox">
                    3년
                </label>
                <label>
                    <input type="checkbox">
                    5년
                </label>
            </div>
        </div> -->
            <!--개인정보 유효기간 끝-->

            <!--마케팅 수신 동의 시작-->
            <div class="agree_wrap fill_check">
                <h1 class="fill_box_title">마케팅 수신 동의</h1>
                <div class="type">
                    <label>
                        <input type="checkbox" id="marketing_check" v-model="marketing_yn" @click="checkMarketing" />
                        동의
                    </label>
                </div>
            </div>
            <!--마케팅 수신 동의 끝-->
        </div>
        <!--회원 정보 관리 끝-->

        <!--비밀번호 변경 시작-->
        <div class="profile_wrap">
            <h1 class="fill_box_title">비밀번호 수정</h1>
            <div v-if="this.user.sign_path == '메타바이셀'" class="password_wrap">
                <div class="fill_password">
                    <div class="fill_text">
                        <h1>기존 비밀번호<span class="star">*</span></h1>
                        <input
                            type="password"
                            minlength="8"
                            required
                            placeholder="소문자+대문자+숫자+특수문자 조합 8글자 이상"
                            v-model="currentPassword"
                            @keyup.enter="passEnter"
                            :disabled="this.user.sign_path != '메타바이셀'"
                        />
                    </div>
                    <div id="current_show" class="passShow" @click="togglePassShow($event)">
                        <i class="far fa-eye"></i>
                        <i class="fas fa-eye-slash on"></i>
                    </div>
                </div>

                <div class="fill_password">
                    <div class="fill_text">
                        <h1>신규 비밀번호<span class="star">*</span></h1>
                        <input
                            type="password"
                            minlength="8"
                            required
                            placeholder="소문자+대문자+숫자+특수문자 조합 8글자 이상"
                            v-model="newPassword"
                            :disabled="this.user.sign_path != '메타바이셀'"
                            @keyup.enter="passEnter"
                        />
                    </div>
                    <div id="new_show" class="passShow" @click="togglePassShow($event)">
                        <i class="far fa-eye"></i>
                        <i class="fas fa-eye-slash on"></i>
                    </div>
                </div>

                <div class="fill_password">
                    <div class="fill_text">
                        <h1>비밀번호 확인 <span class="star">*</span></h1>
                        <input
                            class="password"
                            type="password"
                            minlength="8"
                            required
                            placeholder="비밀번호를 한번 더 입력해주세요"
                            :disabled="this.user.sign_path != '메타바이셀'"
                            v-model="newPasswordCheck"
                        />
                    </div>
                    <div id="new_check_show" class="passShow" @click="togglePassShow($event)">
                        <i class="far fa-eye"></i>
                        <i class="fas fa-eye-slash on"></i>
                    </div>
                </div>
            </div>
            <div v-else class="password_wrap">
                <div class="fill_password">
                    소셜로그인은 비밀번호 변경이 불가능합니다.
                </div>
            </div>

            <span @click="resign">회원탈퇴</span>
        </div>
        <!--비밀번호 변경 끝-->

        <div class="btn_wrap">
            <button type="button" class="common_button" @click="modifyUserInfo">저장</button>
            <button type="button" class="sub_button" @click="goMypageHome">취소</button>
        </div>
    </div>
</template>

<script>
import { postData } from '@/utils/postData';
import { mapState } from 'vuex';

// import UserCheck from "@/components/Mypage/MypageUserInfoCheck.vue";

export default {
    components: {
        // UserCheck,
    },
    mixins: [postData],
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            newPasswordCheck: '',
            curPwValid: false,
            passValid: false,
            passReValid: false,
            marketing_yn: false,
        };
    },
    created() {
        this.marketing_yn = this.user.marketing_yn;
    },
    mounted() {
        const element = document.getElementById('marketing_check');

        if (this.marketing_yn) {
            element.parentElement.classList.add('selected');
        } else {
            element.parentElement.classList.remove('selected');
        }
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('access_token', ['access_token']),
    },
    watch: {
        currentPassword(value) {
            if (this.checkPwFormat(value)) {
                this.curPwValid = true;
            } else {
                this.curPwValid = false;
            }
        },
        newPassword(newPW) {
            if (this.checkPwFormat(newPW)) {
                this.passValid = true;
                if (newPW === this.newPasswordCheck) {
                    this.passReValid = true;
                } else {
                    this.passReValid = false;
                }
            } else {
                this.passValid = false;
                this.passReValid = false;
            }
        },
        newPasswordCheck(newPW) {
            if (newPW === '') {
                this.passReValid = false;
            }
            if (this.newPassword === newPW && this.passValid) {
                this.passReValid = true;
            } else {
                this.passReValid = false;
            }
        },
    },
    methods: {
        passEnter() {
            document.getElementById('pass_check').focus();
        },
        // 비밀번호 '보기/가리기' 토글
        togglePassShow(event) {
            const target = event.currentTarget;

            if (target.previousElementSibling.querySelector('input').type == 'text') {
                target.previousElementSibling.querySelector('input').type = 'password';
            } else {
                target.previousElementSibling.querySelector('input').type = 'text';
            }
            target.children[0].classList.toggle('on');
            target.children[1].classList.toggle('on');
        },
        checkPwFormat(value) {
            const lower = /[a-z]/;
            const upper = /[A-Z]/;
            const number = /[0-9]/;
            const special = /[`~!@#$%^&*()_|+\-=?;:'",.<>]/;

            if (
                value.length >= 8 &&
                value.match(lower) &&
                value.match(upper) &&
                value.match(number) &&
                value.match(special)
            ) {
                return true;
            } else {
                return false;
            }
        },
        modifyUserInfo() {
            const data = {
                pre_password: this.currentPassword,
                password: '',
                marketing_yn: this.marketing_yn,
            };
            if (this.currentPassword !== '' && this.curPwValid) {
                if (this.newPassword === '' && this.newPasswordCheck === '') {
                    data.password = '';
                    this.postUserInfoData(data);
                } else {
                    // 비밀번호 유효성 검사
                    data.password = this.newPassword;
                    if (this.passValid && this.passReValid) {
                        this.postUserInfoData(data);
                    } else {
                        this.$store.commit('alert', '비밀번호를 확인해주세요');
                    }
                }
            } else {
                this.$store.commit('alert', '비밀번호를 확인해주세요');
            }
        },
        postUserInfoData(data) {
            this.$store.commit('confirm', {
                content: '회원정보를 수정하시겠습니까?',
                callback: () => {
                    this.$store.commit('setCheckPass', true);
                    this.postData('/saveUser', data, () => {
                        this.$store.commit('user/myPageLogout');
                    });
                },
            });
        },
        goMypageHome() {
            this.$router.push('/mypage');
        },
        checkMarketing(e) {
            const element = document.getElementById('marketing_check');
            this.marketing_yn = e.currentTarget.checked;
            if (this.marketing_yn) {
                element.parentElement.classList.add('selected');
            } else {
                element.parentElement.classList.remove('selected');
            }
        },
        resign() {
            if (this.currentPassword === '') {
                this.$store.commit('alert', '기존 비밀번호를 입력해주세요.');
            } else if (!this.curPwValid) {
                this.$store.commit('alert', '기존 비밀번호를 확인해주세요.');
            } else {
                const formData = new FormData();
                // formData.append('access_token', this.access_token);
                formData.append('password', this.currentPassword);

                this.$store.commit('confirm', {
                    content: '회원 탈퇴를 하시겠습니까?',
                    callback: () => {
                        this.$store.commit('resign', {
                            content: '회원 탈퇴 사유를 입력해주세요.',
                            callback: () => {
                                formData.append('leave_memo', this.$store.state.resignMessage);
                                this.$store.commit('clearResignMessage');

                                /* TODO : 회원 탈퇴 API */

                                this.postData('/resign', formData, (data) => {
                                    if (data.success) {
                                        this.$store.commit('setCheckPass', true);
                                        this.$store.commit('alert', data.message);
                                        this.$store.commit('user/logout');
                                    }
                                });
                            },
                        });
                    },
                });
            }
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_userinfo.css"></style>
