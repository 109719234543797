<template>
    <div class="right_con ad_detail">
        <div class="toggle_wrap">
            <div class="toggle" :style="toggleStyle"></div>
            <ul class="main_menu">
                <li :class="this.whichTab === 'calendar' ? 'on' : ''" @click="this.whichTab = 'calendar'">달력 보기</li>
                <li :class="this.whichTab === 'calendar' ? '' : 'on'" @click="this.whichTab = 'list'">리스트 보기</li>
            </ul>
        </div>
        <div class="ad_calendar" v-if="this.whichTab === 'calendar'">
            <FullCalendar :options="calendarOptions" />
        </div>

        <div class="common_table" v-if="this.whichTab === 'list'">
            <ul class="top">
                <li class="product">{{this.user.user_division == '판매자' ? '위치' : '상품'}}</li>
                <li class="w80">토큰/가격</li>
                <li class="w80">상태</li>
                <li class="w100">{{this.user.user_division == '판매자' ? '구매방법' : '위치'}}</li>
                <li class="w100">시작일</li>
                <li class="w100">마감일</li>
                <li class="w60">관리</li>
            </ul>
            <ul class="content" v-for="item in this.sliceList(this.list)" :key="item.ad_pk">
                <li class="product">{{ this.user.user_division == '판매자' ?  item.division : item.name}}</li>
                <li class="w80">{{ item.token ? numberFormat(item.token)+'개' : numberFormat(item.price)+'원' }}</li>
                <li class="w80">{{ item.state }}</li>
                <li class="w100">{{ this.user.user_division == '판매자' ? item.purchase_way : item.division }}</li>
                <li class="w100">
                    {{ item.start_date ? dateFormat(item.start_date) : "-" }}
                </li>
                <li class="w100">
                    {{ item.finish_date ? dateFormat(item.finish_date) : "-" }}
                </li>
                <li class="w60 icon" @click="openPopup(item)">
                    <img src="@/assets/images/mypage/icon_manage.svg" alt="관리" />
                </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>진행 중인 광고가 없습니다.</li>
            </ul>
            <BoardPagination
                :listLength="this.list.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
        </div>
        <AdPopup
            v-if="this.showPopup == true"
            :popupData="this.popupData"
            @closePopup="closePopup"
        />
    </div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from "@fullcalendar/vue3";
// import { CalendarOptions, EventApi, DateSelectArg, EventClickArg } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
// import koLocale from "@fullcalendar/core/locales/ko";

import BoardPagination from '@/components/BoardPagination'
import AdPopup from '@/components/Mypage/Ad/AdPopup'
import { mapState } from "vuex";
import { fetchData } from "@/utils/fetchData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";

export default {
    components : {
        FullCalendar,
        BoardPagination,
        AdPopup,
    },
    mixins: [fetchData, numberFormat, dateFormat],
    computed: {
        ...mapState("user", ["user"]),
        eventList(){
            return this.list.filter(el=>el.state == '승인');
        },
        toggleStyle() {
            return this.whichTab === 'calendar' ? { left: "0px" } : { left: "120px" };
        },
    },
    data() {
        return {
            whichTab: 'calendar',
            list: [],

            currentPage : 1,    // 현재 페이지
            maxShowList : 10,   // 한 페이지 보일 최대 리스트수
            maxShowPage : 5,    // 한 화면에 보일 최대 페이지수

            popupData : {},
            showPopup : false,

            calendarOptions: {  // 캘린더 옵션
                plugins: [ dayGridPlugin ],
                headerToolbar: {
                    left: "prev,next",
                    center: "title",
                    right: "today",
                },
                weekends: true,
                // locale: koLocale,
                // events: (info, successCallback)=>{
                //     let events = [];
                //     this.fetchData(
                //         "/getAd",
                //         (data) => {
                //             let approvedList = data.list.filter(el=>el.state == '승인');
                //             console.log('approvedList', approvedList);
                //             events = approvedList.map(el=>{
                //                 return {
                //                     title: `${el.name} (${el.division})`,
                //                     start: this.dateFormat(el.start_date).replaceAll('.','-'),
                //                     end: this.dateFormat(el.finish_date).replaceAll('.','-'),
                //                     color: el.division == '상단' ? "#FF8AAE"
                //                         : el.division == '중앙' ? "#96CEB4"
                //                         : "#9ADCFF"
                //                 }
                //             })
                //             successCallback(events);
                //         }
                //     );
                // },
            },
        };
    },
    created() {
        this.fetchAdData();
    },
    methods: {
        fetchAdData() {
            this.fetchData(
                "/getAd",
                (data) => {
                    // '대기'가 최상, '승인'이 다음
                    this.list = data.list.sort((a,b)=>{
                        if(a.state == b.state) {
                            return 0;
                        } else if(a.state == '대기' || b.state == '대기') {
                            return a.state == '대기' ? -1 : 1;
                        } else if(a.state == '승인' || b.state == '승인') {
                            return a.state == '승인' ? -1 : 1;
                        } else {
                            return 0;
                        }
                    });

                    const approvedList = this.list.filter(el=>el.state == '승인');
                    this.calendarOptions = {
                        ...this.calendarOptions,
                        events: approvedList.map(el=>{
                            return {
                                title: `${el.name} (${el.division})`,
                                start: this.dateFormat(el.start_date).replaceAll('.','-'),
                                end: this.dateFormat(el.finish_date).replaceAll('.','-'),
                                color: el.division == '상단' ? "#ff9595"    // 분홍색
                                    : el.division == '중앙' ? "#96CEB4"     // 연두색
                                    : "#84d4ff"     // 하늘색
                            }
                        })
                    }
                }
            );
        },
        // 페이지 변경
        setPage(num){
            this.currentPage = num;
        },
        sliceList(arr){
            return arr.slice( (this.currentPage-1)*this.maxShowList, this.currentPage*this.maxShowList )
        },
        openPopup(item) {
            this.popupData = item;
            this.showPopup = true;
        },
        closePopup(refresh) {
            this.popupData = {};
            this.showPopup = false;
            if(refresh){
                this.fetchAdData();
            }
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_ad.css"></style>
