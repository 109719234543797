<template>
  <div class="right_con">
        <div class="toggle_wrap">
            <div class="toggle" :style="toggleStyle"></div>
                <ul class="main_menu">
                    <li :class="this.whichTab === 0 ? 'on' : ''" @click="clickTab(0)">상품 제안</li>
                    <li :class="this.whichTab === 0 ? '' : 'on'" @click="clickTab(1)">매칭 제안</li>
                </ul>
        </div>
        <div class="common_table s_proposal_01" v-if="this.whichTab === 0" >
            <ul class="top">
                <li class="w120" >공급자</li>
                <li class="product" >상품명</li>
                <li class="w100">공급가</li>
                <li class="w100">입찰토큰</li>
                <li class="w70">상태</li>
                <li class="w110">마감일</li>
                <li class="w110">등록일</li>
                <li class="w60">보기</li>
            </ul>
            <ul class="content" v-for="(item) in this.sliceList" :key="item">
                <li class="w120 name sub"><p>{{ item.provider_name }}</p></li>
                <li class="product">
                    <p>{{ item.product_name }}</p>
                </li>
                <li class="w100 sub">
                    <p>{{ this.numberFormat(item.supply_price) }}원</p>
                </li>
                <li class="w100 sub2"><p>{{ this.numberFormat(item.request_token) }}개</p></li>
                <li class="w70 sub3"><p>{{ item.state }}</p></li>
                <li class="w110 sub4">
                    <p>{{ item.finish_date ? this.dateFormat(item.finish_date) : '-' }}</p>
                </li>
                <li class="w110 sub4">
                    <p>{{ this.dateFormat(item.request_date) }}</p>
                </li>
                <li class="w60 icon" @click="openPopup(item)">
                    <img src="@/assets/images/common/search.svg" alt="돋보기" />
                </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>제안받은 내역이 없습니다.</li>
            </ul>
        </div>

        <div class="common_table s_proposal_02" v-else>
            <ul class="top">
                <li class="w120">공급자</li>
                <li class="product">상품명</li>
                <li class="w100">입찰토큰</li>
                <li class="w70">상태</li>
                <li class="w110">마감일</li>
                <li class="w110">제안일</li>
                <li class="w60">보기</li>
            </ul>
            <ul v-for="(item) in this.sliceList" :key="item">
                <li class="w120 name">{{ item.provider_name }}</li>
                <li class="product">
                    <p>{{ item.matching_name }}</p>
                </li>
                <li class="w100">{{ this.numberFormat(item.request_token) }}개</li>
                <li class="w70">{{ item.state }}</li>
                <li class="w110">
                    {{ item.finish_date ? this.dateFormat(item.finish_date) : '-' }}
                </li>
                <li class="w110">
                    {{ this.dateFormat(item.request_date) }}
                </li>
                <li class="w60 icon" @click="openPopup(item)">
                    <img src="@/assets/images/common/search.svg" alt="돋보기" />
                </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>제안받은 내역이 없습니다.</li>
            </ul>
        </div>

      <BoardPagination
        :listLength="this.list.length"
        :maxShowList="maxShowList"
        :maxShowPage="maxShowPage"
        :currentPage="currentPage"
        @setPage="setPage"
      />
  </div>

  <!--팝업 시작-->
  <div class="screen_filter" v-if="this.showPopup"></div>
  <div class="popup popup_seller" v-if="this.showPopup">
    <button type="button" @click="closePopup">
      <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
    </button>
    
    <!-- 상품 제안 팝업 시작 -->
    <div class="inner" v-if="this.whichTab == 0">
        <!--상품명, 버튼 시작-->
        <div class="product_title">
            <div class="user user_provider">
                <span>공급자</span>
                <p>{{ popupData.provider_name }}</p>
            </div>
            <div class="btn_wrap">
                <button type="button" class="sub_button" @click="refuseMatching(false)">
                    <div class="icon_wrap">
                        <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
                    </div>
                    <p>거래거부</p>
                </button>
                <button type="button" class="common_button" @click="acceptMatching(false)">
                    <div class="icon_wrap">
                        <img src="@/assets/images/common/icon_check.svg" alt="all" />
                    </div>
                    <p>거래완료</p>
                </button>
            </div>
            <h1>{{ popupData.product_name }}</h1>
        </div>
        <!--상품명, 버튼 끝-->

        <!--내용 시작-->
        <div class="product">
            <div class="slide_wrap">
                <div
                    v-if="popupData.product_image.length == 0"
                    class="product_image default"
                >
                    <img src="@/assets/images/common/no_image.svg" />
                </div>
                <div
                    v-else-if="popupData.product_image.length == 1"
                    class="product_image"
                >
                    <img
                    :src="
                        popupData.product_image[0].path +
                        this.popupData.product_image[0].save_name
                    "
                    :alt="popupData.product_image[0].original_name"
                    />
                </div>
                <swiper v-else v-bind="swiperOption">
                    <swiper-slide v-for="(item, i) in popupData.product_image" :key="i">
                    <div class="product_image">
                        <img
                        :src="item.path + item.save_name"
                        :alt="item.original_name"
                        />
                    </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div class="scale_wrap">
                <div class="scale">
                    <p>최소구매수량</p>
                    <h1>{{ popupData.product_moq }}</h1>
                </div>
                <div class="scale">
                    <p>예상마진</p>
                    <h1>{{ popupData.product_margin }}</h1>
                </div>
                <div class="scale">
                    <p>공급가</p>
                    <h1>{{ numberFormat(popupData.supply_price) }}<span>원</span></h1>
                </div>
                <div class="scale">
                    <p>소비자가</p>
                    <h1>{{ numberFormat(popupData.consumer_price) }}<span>원</span></h1>
                </div>
            </div>

            <div class="info_wrap">
                <div class="info">
                    <p>마감일</p>
                    <h1>
                    {{ this.dateFormat(popupData.finish_date) }}
                    </h1>
                </div>
                <div class="info">
                    <p>제안일</p>
                    <h1>
                    {{ this.dateFormat(popupData.request_date) }}
                    </h1>
                </div>
                <div class="info">
                    <p>입찰한 토큰</p>
                    <h1>{{ numberFormat(popupData.request_token) }}<span>개</span></h1>
                </div>
            </div>
        </div>
        <!--내용 끝-->
    </div>
    <!-- 상품 제안 팝업 끝 -->


    <!-- 매칭 제안 팝업 시작 -->
    <div class="inner" v-else>
        <!--상품명, 버튼 시작-->
        <div class="product_title">
            <div class="user user_provider">
                <span>공급자</span>
                <p>{{ popupData.provider_name }}</p>
            </div>
            <div class="btn_wrap">
                <button type="button" class="sub_button" @click="refuseMatching(true)">
                    <div class="icon_wrap">
                        <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
                    </div>
                    <p>거래거부</p>
                </button>
                <button type="button" class="common_button" @click="acceptMatching(true)">
                    <div class="icon_wrap">
                        <img src="@/assets/images/common/icon_check.svg" alt="all" />
                    </div>
                    <p>거래완료</p>
                </button>
            </div>
            <h1>{{ popupData.matching_name }}</h1>
        </div>
        <!--상품명, 버튼 끝-->

      <!--내용 시작-->
      <div class="product">
            <div class="slide_wrap">
                <div v-if="popupData.matching_image.length == 0" class="product_image default">
                    <img src="@/assets/images/common/no_image.svg" />
                </div>
                <div v-else-if="popupData.matching_image.length == 1" class="product_image">
                    <img
                    :src="
                        popupData.matching_image[0].path +
                        this.popupData.matching_image[0].save_name
                    "
                    :alt="popupData.matching_image[0].original_name"
                    />
                </div>
                <swiper v-else v-bind="swiperOption">
                    <swiper-slide v-for="(item, i) in popupData.matching_image" :key="i">
                    <div class="product_image">
                        <img
                        :src="item.path + item.save_name"
                        :alt="item.original_name"
                        />
                    </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div class="scale_wrap">
                <div class="scale">
                    <p>최소요청수량</p>
                    <h1>{{ popupData.moq }}</h1>
                </div>
                <div class="scale">
                    <p>판매가능시점</p>
                    <h1>{{ popupData.start }}</h1>
                </div>
                <div class="scale">
                    <p>상품단가</p>
                    <h1>{{ popupData.price }}</h1>
                </div>
            </div>

            <div class="info_wrap">
                <div class="info">
                    <p>마감일</p>
                    <h1>
                    {{ this.dateFormat(popupData.finish_date) }}
                    </h1>
                </div>
                <div class="info">
                    <p>제안일</p>
                    <h1>
                    {{ this.dateFormat(popupData.request_date) }}
                    </h1>
                </div>
                <div class="info">
                    <p>입찰한 토큰</p>
                    <h1>{{ numberFormat(popupData.request_token) }}<span>개</span></h1>
                </div>
            </div>
      </div>
      <!--내용 끝-->
    </div>
    <!-- 매칭 제안 팝업 끝 -->

  </div>
  <!--팝업 끝-->
</template>

<script>
import BoardPagination from '@/components/BoardPagination'
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination]);

import { mapState } from "vuex";
import { fetchData } from "@/utils/fetchData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";
import { postData } from "@/utils/postData";
export default {
  components: {
    Swiper,
    SwiperSlide,
    BoardPagination
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("access_token", ["access_token"]),
    toggleStyle() {
      return this.whichTab === 0 ? { left: "0px" } : { left: "120px" };
    },
    sliceList(){
      return this.list.length == 0 ? [] : this.list.slice( (this.currentPage-1)*this.maxShowList, this.currentPage*this.maxShowList );
    },
  },
  data() {
    return {
      list: [],
      product_list : [],
      matching_list : [],
      whichTab : 0,
      
      showPopup: false,
      popupData: {},

      swiperOption: {
        loop: true,
        navigation: true,
        pagination: true,
      },

      currentPage : 1,    // 현재 페이지
      maxShowList : 10,   // 한 페이지 보일 최대 리스트수
      maxShowPage : 5,    // 한 화면에 보일 최대 페이지수

    };
  },
  mixins: [fetchData, numberFormat, dateFormat, postData],
  watch : {
    whichTab(){
      this.currentPage = 1;
    }
  },
  created() {
    this.fetchProposalData();
  },
  methods: {
    // 제안받은 거래 불러오기
    fetchProposalData() {
      this.fetchData(
        "/getProposalProduct",
        (data) => {
          this.product_list = data.product_list;
          this.matching_list = data.matching_list;
          this.list = this.product_list;
        },
      );
    },
    acceptMatching(matching_yn) {
      const formData = new FormData();
      formData.append("access_token", this.access_token);
      formData.append("matching_yn", matching_yn);
      if(matching_yn){
        formData.append("matching_request_pk", this.popupData.matching_request_pk);
      } else {
        formData.append("request_pk", this.popupData.request_pk);
      }
      this.postData("/saveRequest", formData, (data) => {
        if (data.success) {
          this.$store.commit("alert", "거래가 체결되었습니다.");
          this.fetchProposalData();
          this.closePopup();
        }
      });
    },
    refuseMatching(matching_yn) {
      const formData = new FormData();
      formData.append("access_token", this.access_token);
      formData.append("matching_yn", matching_yn);
      if(matching_yn){
        formData.append("matching_request_pk", this.popupData.matching_request_pk);
      } else {
        formData.append("request_pk", this.popupData.request_pk);
      }
      this.postData("/deleteRequest", formData, (data) => {
        if (data.success) {
          this.$store.commit("alert", "제안을 거부하였습니다.");
          this.fetchProposalData();
          this.closePopup();
        }
      });
    },
    clickTab(tab) {
      this.whichTab = tab;
      this.list = tab === 0 ? this.product_list : this.matching_list;
    },
    // 페이지 변경
    setPage(num){
      this.currentPage = num;
    },
    openPopup(item) {
      this.popupData = item.detail;
      this.popupData.request_token = item.request_token;
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_matching.css"></style>
<style scoped src="@/assets/css/components/commonPopup_v2.css"></style>
