<template>
    <div class="right_con">
        <div class="common_table s_request">
            <ul class="top">
                <li class="w120">공급자</li>
                <li class="product">상품명</li>
                <li class="w100">공급가</li>
                <li class="w100">입찰토큰</li>
                <li class="w70">상태</li>
                <li class="w110">마감일</li>
                <li class="w110">등록일</li>
                <li class="w60">관리</li>
            </ul>
            <ul class="content" v-for="(item, i) in this.sliceList(this.list)" :key="i">
                <li class="w120 sub name">{{ item.provider_name }}</li>
                <li class="product">
                    <p>{{ item.product_name }}</p>
                </li>
                <li class="w100 sub" v-if="item.supply_price_exposure_yn == 'true'">
                    {{ this.numberFormat(item.supply_price) }}
                </li>
                <li class="w100 sub" v-else><p>{{ numberFormat(item.supply_price) }}원</p></li>
                <li class="w100 sub2"><p>{{ this.numberFormat(item.request_token) }}개</p></li>
                <li class="w70 sub3"><p>{{ item.supply_price_exposure_yn }}</p></li>
                <li class="w110 sub4" v-if="item.finish_date">
                    <p>{{ this.dateFormat(item.finish_date) }}</p>
                </li>
                <li class="w110 sub4" v-else><p>-</p></li>
                <li class="w110 sub4">
                    <p>{{ this.dateFormat(item.request_date) }}</p>
                </li>
                <li class="w60 icon" @click="openPopup(item)">
                    <img src="@/assets/images/mypage/icon_manage.svg" alt="관리" />
                </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>제안한 내역이 없습니다.</li>
            </ul>
            <BoardPagination
                :listLength="this.list.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
        </div>
    </div>

    <!--팝업 시작-->
    <div class="screen_filter" v-if="this.which"></div>
    <div class="popup popup_seller" v-if="this.which == 1">
        <button type="button" @click="closePopup">
            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
        </button>
        <div class="inner">
            <!--상품명, 버튼 시작-->
            <div class="product_title">
                <div class="user user_provider">
                    <span>공급자</span>
                    <p>{{ popupData.provider_name }}</p>
                </div>
                <div class="btn_wrap">
                    <button type="button" class="common_button" @click="this.which = 2">
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/icon_add_w.svg" alt="add" />
                        </div>
                        <p>추가입찰</p>
                    </button>
                    <button type="button" class="sub_button" @click="this.which = 3">
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
                        </div>
                        <p>제안취소</p>
                    </button>
                </div>
                <h1>{{ popupData.product_name }}</h1>
            </div>
            <!--상품명, 버튼 끝-->

            <!--내용 시작-->
            <div class="product">
                <div class="slide_wrap">
                    <div v-if="popupData.product_image.length == 0" class="product_image default">
                        <img src="@/assets/images/common/no_image.svg" />
                    </div>
                    <div v-else-if="popupData.product_image.length == 1" class="product_image">
                        <img
                            :src="popupData.product_image[0].path + this.popupData.product_image[0].save_name"
                            :alt="popupData.product_image[0].original_name"
                        />
                    </div>
                    <swiper v-else v-bind="swiperOption">
                        <swiper-slide v-for="(item, i) in popupData.product_image" :key="i">
                            <div class="product_image">
                                <img :src="item.path + item.save_name" :alt="item.original_name" />
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>

                <!-- <span class="line"></span> -->

                <div class="scale_wrap">
                    <div class="scale">
                        <p>최소구매수량</p>
                        <h1>{{ popupData.product_moq }}</h1>
                    </div>
                    <div class="scale">
                        <p>예상마진</p>
                        <h1>{{ popupData.product_margin }}</h1>
                    </div>
                    <div class="scale">
                        <p>공급가</p>
                        <h1>{{ numberFormat(popupData.supply_price) }}<span>원</span></h1>
                    </div>
                    <div class="scale">
                        <p>소비자가</p>
                        <h1>{{ numberFormat(popupData.consumer_price) }}<span>원</span></h1>
                    </div>
                </div>

                <div class="info_wrap">
                    <div class="info">
                        <p>마감일</p>
                        <h1>{{ this.dateFormat(popupData.finish_date) }}</h1>
                    </div>
                    <div class="info">
                        <p>최고 입찰 판매자</p>
                        <h1 v-if="popupData.best_seller">{{ popupData.best_seller }}</h1>
                        <h1 v-else>없음</h1>
                    </div>
                    <div class="info">
                        <p>최고 입찰 토큰</p>
                        <h1>{{ numberFormat(popupData.best_token) }}<span>개</span></h1>
                    </div>
                    <div class="info">
                        <p>내가 입찰한 토큰</p>
                        <h1>{{ numberFormat(popupData.request_token) }}<span>개</span></h1>
                    </div>
                </div>
            </div>
            <!--내용 끝-->
        </div>
    </div>
    <!--팝업 끝-->

    <!--팝업 - 추가입찰 시작-->
    <div class="popup popup_add" v-if="this.which == 2">
        <button type="button" @click="closePopup">
            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
        </button>
        <div class="inner">
            <!--상품명, 버튼 시작-->
            <div class="product_title">
                <div class="user user_provider">
                    <span>공급자</span>
                    <p>{{ popupData.provider_name }}</p>
                </div>
                <h1>{{ popupData.product_name }}</h1>
            </div>
            <!--상품명, 버튼 끝-->

            <!--내용 시작-->
            <div class="product">
                <div class="ask">
                    <div class="ask_icon">
                        <img src="@/assets/images/common/icon_ticket_w.svg" alt="ticket" />
                    </div>
                    <h1>추가 입찰</h1>
                    <p>토큰을 추가하여 입찰하시겠습니까?</p>
                </div>
                <!-- <div class="line"></div> -->
                <div class="scale_wrap">
                    <div class="scale">
                        <p>최고 입찰 토큰</p>
                        <h1>{{ numberFormat(popupData.best_token) }}<span>개</span></h1>
                    </div>
                    <div class="scale">
                        <p>입찰된 토큰</p>
                        <h1>{{ numberFormat(popupData.request_token) }}<span>개</span></h1>
                    </div>
                    <div class="scale">
                        <p>추가 토큰</p>
                        <div class="add_ticket">
                            <span class="minus" @click="meta_token--">-</span>
                            <input type="number" v-model="meta_token" />
                            <span class="plus" @click="meta_token++">+</span>
                        </div>
                    </div>
                    <div class="scale">
                        <p>보유 토큰</p>
                        <h1>{{ numberFormat(popupData.my_token) }}<span>개</span></h1>
                    </div>
                </div>
            </div>
            <!--내용 끝-->

            <!--적용 버튼 시작-->
            <div class="last_btn_wrap">
                <button type="button" class="common_button" @click="addRequest">
                    <!-- <img src="@/assets/images/common/icon_check.svg" alt="all"/> -->
                    <p>적용</p>
                </button>
                <button type="button" class="sub_button" @click="this.which = 1">
                    <!-- <img src="@/assets/images/common/close_w.svg" alt="prev"/> -->
                    <p>이전</p>
                </button>
            </div>
            <!--적용 버튼 끝-->
        </div>
    </div>
    <!--팝업 - 추가입찰 끝-->

    <!--팝업 - 판매제안취소 시작-->
    <div class="popup popup_delete" v-if="this.which == 3">
        <button type="button" @click="closePopup">
            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
        </button>
        <div class="inner">
            <!--상품명, 버튼 시작-->
            <div class="product_title">
                <div class="user">
                    <span>공급자</span>
                    <p>{{ popupData.provider_name }}</p>
                </div>
                <h1>{{ popupData.product_name }}</h1>
            </div>
            <!--상품명, 버튼 끝-->

            <!--내용 시작-->
            <div class="product">
                <div class="ask">
                    <div class="ask_icon">
                        <img src="@/assets/images/common/icon_memo_w.svg" alt="ticket" />
                    </div>
                    <h1>판매제안 취소</h1>
                    <p>판매제안을 취소하시겠습니까?</p>
                </div>
            </div>
            <!--내용 끝-->

            <!--적용 버튼 시작-->
            <div class="last_btn_wrap">
                <button type="button" class="common_button" @click="cancelRequest">
                    <p>확인</p>
                </button>
                <button type="button" class="sub_button" @click="this.which = 1">
                    <p>이전</p>
                </button>
            </div>
            <!--적용 버튼 끝-->
        </div>
    </div>
    <!--팝업 - 판매제안취소 끝-->
</template>

<script>
import BoardPagination from '@/components/BoardPagination'
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination]);

import { fetchData } from '@/utils/fetchData';
import { mapState } from 'vuex';
import { numberFormat } from '@/utils/numberFormat';
import { dateFormat } from '@/utils/dateFormat';
import { postData } from '@/utils/postData';

export default {
    mixins: [fetchData, numberFormat, dateFormat, postData],
    components: {
        Swiper,
        SwiperSlide,
        BoardPagination
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('access_token', ['access_token']),
    },
    watch:{
        meta_token(newVal){
            if(newVal<0){
                this.meta_token = 0;
            } else if(newVal > this.user.meta_token){
                this.meta_token = this.user.meta_token;
            }
        }
    },
    data() {
        return {
            list: [],
            which: 0,
            popupData: {},
            meta_token: 0,

            swiperOption: {
                loop: true,
                navigation: true,
                pagination: true,
            },

            currentPage : 1,    // 현재 페이지
            maxShowList : 10,   // 한 페이지 보일 최대 리스트수
            maxShowPage : 5,    // 한 화면에 보일 최대 페이지수
        };
    },
    created() {
        this.fetchRequestData();
    },
    methods: {
        // 제안한 거래 불러오기
        fetchRequestData() {
            this.fetchData(
                '/getRequestProduct',
                (data) => {
                    this.list = data.list;
                },
            );
        },
        addRequest() {
            const formData = new FormData();
            // formData.append('access_token', this.access_token);
            formData.append('meta_token', this.meta_token);
            formData.append('request_pk', this.popupData.request_pk);
            this.postData('/addRequest', formData, (data) => {
                if (data.success) {
                    this.$store.commit('alert', '추가 입찰하였습니다.');
                    this.fetchRequestData();
                    this.closePopup(0);
                }
            });
        },
        cancelRequest() {
            const formData = new FormData();
            // formData.append('access_token', this.access_token);
            formData.append('request_pk', this.popupData.request_pk);
            this.postData('/deleteRequest', formData, (data) => {
                if (data.success) {
                    this.$store.commit('alert', '거래를 취소하였습니다.');
                    this.fetchRequestData();
                    this.closePopup();
                }
            });
        },
        // 페이지 변경
        setPage(num){
            this.currentPage = num;
        },
        sliceList(arr){
            return arr.length == 0 ? [] : arr.slice( (this.currentPage-1)*this.maxShowList, this.currentPage*this.maxShowList );
        },
        openPopup(item) {
            this.popupData = item.detail;
            this.which = 1;
        },
        closePopup() {
            this.which = 0;
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_matching.css"></style>
<style scoped src="@/assets/css/components/commonPopup_v2.css"></style>
