<template>
    <div class="right_con">
        <div class="common_table p_request">
            <ul class="top">
                <li class="w150">판매자</li>
                <li class="product">상품명</li>
                <li class="w80">공급가</li>
                <li class="w80">입찰토큰</li>
                <li class="w70">신청상태</li>
                <li class="w100">마감일</li>
                <li class="w100">신청일</li>
                <li class="w50">보기</li>
            </ul>
            <ul v-for="(item, i) in sliceList" :key="i" class="content">
                <li class="w150 name">
                    <p>
                        {{ item.detail.seller_name }}
                    </p>
                </li>
                <li class="product">
                    <p>{{ item.product_name }}</p>
                </li>
                <li class="w80 sub">
                    <p>
                        {{ this.numberFormat(item.supply_price) }}원
                    </p>
                </li>
                <li class="w80 sub">
                    <p>
                        {{ this.numberFormat(item.request_token) }}개
                    </p>
                </li>
                <li class="w70 sub">
                    <p>
                        {{ item.state }}
                    </p>
                </li>
                <li class="w100 sub3">
                    <p>
                        {{ item.finish_date ? this.dateFormat(item.finish_date) : '-' }}
                    </p>
                </li>
                <li class="w100 sub3">
                    <p>
                        {{ this.dateFormat(item.request_date) }}
                    </p>
                </li>
                <li class="w50 icon" @click="openPopup(item)">
                    <img src="@/assets/images/common/search.svg" alt="돋보기" />
                </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>제안받은 내역이 없습니다.</li>
            </ul>
            <BoardPagination
                :listLength="this.list.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
        </div>
    </div>

    <!--팝업 시작-->
    <div class="screen_filter" v-if="this.showPopup"></div>
    <div class="popup popup_seller" v-if="this.showPopup">
        <button type="button" @click="closePopup">
            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
        </button>
        <div class="inner">
            <!--상품명, 버튼 시작-->
            <div class="product_title">
                <div class="user user_provider">
                    <span>판매자</span>
                    <p>{{ popupData.seller_name }}</p>
                </div>
                <div class="btn_wrap">
                    <button type="button" class="common_button" @click="acceptMatching">
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/icon_check.svg" alt="all" />
                        </div>
                        <p>거래완료</p>
                    </button>
                </div>
                <h1>{{ popupData.product_name }}</h1>
            </div>
            <!--상품명, 버튼 끝-->

            <!--내용 시작-->
            <div class="product">
                <div class="slide_wrap">
                        <div v-if="popupData.product_image.length == 0" class="product_image default">
                            <img src="@/assets/images/common/no_image.svg"/>
                        </div>
                        <div v-else-if="popupData.product_image.length == 1" class="product_image">
                            <img
                                :src="popupData.product_image[0].path + this.popupData.product_image[0].save_name"
                                :alt="popupData.product_image[0].original_name"
                            />
                        </div>

                        <swiper v-else v-bind="swiperOption">
                            <swiper-slide
                                v-for="(item, i) in popupData.product_image"
                                :key="i"
                            >
                                <div class="product_image">
                                <img
                                    :src="item.path + item.save_name"
                                    :alt="item.original_name"
                                />
                                </div>
                            </swiper-slide>
                        </swiper>
                </div>

                <div class="scale_wrap">
                    <div class="scale">
                        <p>최소구매수량</p>
                        <h1>{{ popupData.product_moq }}</h1>
                    </div>
                    <div class="scale">
                        <p>예상마진</p>
                        <h1>{{ popupData.product_margin }}<span></span></h1>
                    </div>
                    <div class="scale">
                        <p>공급가</p>
                        <h1>{{ numberFormat(popupData.supply_price) }}<span>원</span></h1>
                    </div>
                    <div class="scale">
                        <p>소비자가</p>
                        <h1>{{ numberFormat(popupData.consumer_price) }}<span>원</span></h1>
                    </div>
                </div>

                <div class="info_wrap">
                    <div class="info">
                        <p>마감일</p>
                        <h1>{{ dateFormat(popupData.finish_date) }}</h1>
                    </div>
                    <div class="info">
                        <p>최고 입찰 토큰</p>
                        <h1>{{ numberFormat(popupData.best_token) }}<span>개</span></h1>
                    </div>
                    <div class="info">
                        <p>최고 입찰 판매자</p>
                        <h1>{{ popupData.best_seller }}</h1>
                    </div>
                </div>
            </div>
            <!--내용 끝-->
        </div>
        <!--팝업 끝-->
    </div>
    <!--팝업 끝-->
</template>

<script>
import BoardPagination from '@/components/BoardPagination'
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination]);

import { mapState } from 'vuex';
import { fetchData } from '@/utils/fetchData';
import { numberFormat } from '@/utils/numberFormat';
import { dateFormat } from '@/utils/dateFormat';
import { postData } from '@/utils/postData';

export default {
    mixins: [fetchData, numberFormat, dateFormat, postData],
    components : {
        Swiper,
        SwiperSlide,
        BoardPagination
    },
    data() {
        return {
            showPopup: false,
            popupData: {},
            list: [],
            margin: ['10~20', '20~30', '30~40', '40~50'],
            swiperOption: {
                loop: true,
                navigation: true,
                pagination: true,
            },

            currentPage : 1,    // 현재 페이지
            maxShowList : 10,   // 한 페이지 보일 최대 리스트수
            maxShowPage : 5,    // 한 화면에 보일 최대 페이지수
        };
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('access_token', ['access_token']),
        sliceList(){
            return this.list.length == 0 ? [] : this.list.slice( (this.currentPage-1)*this.maxShowList, this.currentPage*this.maxShowList );
        },
    },
    created() {
        this.fetchListData();
    },
    methods: {
        fetchListData() {
            this.fetchData(
                '/getRequestSeller',
                (data) => {
                    this.list = data.list;
                },
            );
        },
        acceptMatching() {
            const formData = new FormData();
            // formData.append('access_token', this.access_token);
            formData.append('request_pk', this.popupData.request_pk);
            this.postData('/saveRequest', formData, (data) => {
                if (data.success) {
                    this.$store.commit('alert', '거래가 체결되었습니다.');
                    this.fetchListData();
                    this.closePopup();
                }
            });
        },
        // 페이지 변경
        setPage(num){
            this.currentPage = num;
        },
        openPopup(item) {
            this.popupData = item.detail;
            this.showPopup = true;
        },
        closePopup() {
            this.showPopup = false;
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_matching.css"></style>
<style scoped src="@/assets/css/components/commonPopup_v2.css"></style>
