export const phoneFormat = {
  methods: {
    phoneFormat(phoneNum) {
      if (!phoneNum) {
        return;
      }
      return phoneNum.replace(/[^0-9]/g, "") //숫자만
        .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
  },
}