<template>
    <div class="right_con">
            <div class="common_table sample">
                <ul class="top">
                    <li class="product">상품</li>
                    <li class="w80">구매방법</li>
                    <li class="w80">토큰</li>
                    <li class="w100">금액</li>
                    <li class="w70">상태</li>
                    <li class="w110">등록일</li>
                    <li class="w60">관리</li>
                </ul>
                <ul class="content" v-for="(item, i) in this.list" :key="item.sample_pk">
                    <li class="product">
                        <p>
                            {{ item.product_name }}
                        </p>
                    </li>
                    <li class="w80"><p>{{ item.purchase_way }}</p></li>
                    <li class="w80"><p>{{ this.numberFormat(item.token) }}</p></li>
                    <li class="w100"><p>{{ this.numberFormat(item.price) }}</p></li>
                    <li class="w70"><p>{{ item.state }}</p></li>
                    <li class="w110"><p>{{ dateFormat(item.enrollment_date) }}</p></li>
                    <li class="w60 icon" @click="openPopup(i)">
                        <img src="@/assets/images/mypage/icon_manage.svg" alt="관리" />
                    </li>
                </ul>
                <ul class="default" v-if="this.list.length == 0">
                    <li>구매 하신 샘플이 없습니다.</li>
                </ul>
            </div>
        </div>

    <SamplePopup v-if="popupOpen" @close-popup="closePopup" @delete-sample="deletedSample" :sampleData="popupData" />
</template>

<script>
import { mapState } from "vuex";
import { fetchData } from "@/utils/fetchData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";

import SamplePopup from "./Sample/SamplePopup";

export default {
    mixins: [fetchData, numberFormat, dateFormat],
    components: {
        SamplePopup,
    },
    data() {
        return {
            list: [],
            popupOpen: false,
            popupData: {},
        };
    },
    computed: {
        ...mapState("user", ["user"]),
    },
    created() {
        this.fetchSampleData();
    },
    methods: {
        fetchSampleData() {
            this.fetchData(
                "/getSample",
                (data) => {
                    this.list = data.list;
                },
            );
        },
        openPopup(idx) {
            this.popupData = this.list[idx];
            this.popupOpen = true;
        },
        closePopup() {
            this.popupOpen = false;
        },
        deletedSample() {
            this.fetchSampleData();
            this.closePopup();
            this.$store.commit("alert", "샘플 구매가 취소되었습니다");
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_matching.css"></style>
