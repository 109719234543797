<template>
    <div class="right_con">
        <div class="common_table s_supply">
            <ul class="top">
                <li class="w150">공급자</li>
                <li class="product">상품명</li>
                <li class="w80">공급가</li>
                <li class="w80">입찰토큰</li>
                <li class="w70">상태</li>
                <li class="w100">요청일</li>
                <li class="w60">보기</li>
            </ul>
            <ul class="content" v-for="(item, i) in this.sliceList(this.list)" :key="i">
                <li class="w150 sub name">{{ item.supply_name }}</li>
                <li class="product">
                    <p>{{ item.product_name }}</p>
                </li>
                <li class="w80 sub" v-if="item.supply_price_exposure_yn == 'true'">
                    {{ this.numberFormat(item.supply_price) }}
                </li>
                <li class="w80 sub" v-else><p>{{ numberFormat(item.supply_price) }}원</p></li>
                <li class="w80 sub2"><p>{{ this.numberFormat(item.token) }}개</p></li>
                <li class="w70 sub3"><p>{{ item.state }}</p></li>
                <li class="w100 sub4">
                    <p>{{ this.dateFormat(item.enrollment_date) }}</p>
                </li>
                <li class="w60 icon" @click="openPopup(item)">
                    <img src="@/assets/images/common/search.svg" alt="돋보기" />
                </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>제안한 내역이 없습니다.</li>
            </ul>
            <BoardPagination
                :listLength="this.list.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
        </div>
    </div>

    <!--팝업 시작-->
    <div class="screen_filter" v-if="this.which"></div>
    <div class="popup popup_seller" v-if="this.which == 1">
        <button type="button" @click="closePopup">
            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
        </button>
        <div class="inner">
            <!--상품명, 버튼 시작-->
            <div class="product_title">
                <div class="user user_provider">
                    <span>공급자</span>
                    <p>{{ popupData.product.provider_name }}</p>
                </div>
                <div class="btn_wrap">
                    <button type="button" class="common_button" @click="this.$router.push(`/product/detail/${popupData.product.product_pk}`)">
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/search_w.svg" alt="search" />
                        </div>
                        <p>상품 보기</p>
                    </button>
                    <button v-if="popupData.supply_state == '요청'" type="button" class="sub_button" @click="this.which = 3">
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
                        </div>
                        <p>요청취소</p>
                    </button>
                </div>
                <h1>{{ popupData.product.name }}</h1>
            </div>
            <!--상품명, 버튼 끝-->

            <!--내용 시작-->
            <div class="product">
                <div class="slide_wrap">
                    <div v-if="popupData.product_image.length == 0" class="product_image default">
                        <img src="@/assets/images/common/no_image.svg" />
                    </div>
                    <div v-else-if="popupData.product_image.length == 1" class="product_image">
                        <img
                            :src="popupData.product_image[0].path + this.popupData.product_image[0].save_name"
                            :alt="popupData.product_image[0].original_name"
                        />
                    </div>
                    <swiper v-else v-bind="swiperOption">
                        <swiper-slide v-for="(item, i) in popupData.product_image" :key="i">
                            <div class="product_image">
                                <img :src="item.path + item.save_name" :alt="item.original_name" />
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>

                <!-- <span class="line"></span> -->

                <div class="scale_wrap">
                    <div class="scale">
                        <p>구매가능수량</p>
                        <h1>{{ popupData.product.quantity }}</h1>
                    </div>
                    <div class="scale">
                        <p>공급가</p>
                        <h1>{{ popupData.supply_state == '승인' ? `${numberFormat(popupData.product.supply_price)}원` : '비공개' }}</h1>
                    </div>
                    <div class="scale">
                        <p>소비자가</p>
                        <h1>{{ popupData.product.consumer_price_exposure_yn == '비공개' ? '비공개' : `${numberFormat(popupData.product.consumer_price)}원` }}</h1>
                    </div>
                </div>

                <div class="info_wrap">
                    <div class="info">
                        <p>마감일</p>
                        <h1>{{ this.dateFormat(popupData.product.finish_date) }}</h1>
                    </div>
                    <div class="info">
                        <p>내가 제안한 토큰</p>
                        <h1>{{ numberFormat(popupData.token) }}<span>개</span></h1>
                    </div>
                </div>
            </div>
            <!--내용 끝-->
        </div>
    </div>
    <!--팝업 끝-->

    <!--팝업 - 판매제안취소 시작-->
    <div class="popup popup_delete" v-if="this.which == 3">
        <button type="button" @click="closePopup">
            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
        </button>
        <div class="inner">
            <!--상품명, 버튼 시작-->
            <div class="product_title">
                <div class="user user_provider">
                    <span>공급자</span>
                    <p>{{ popupData.product.provider_name }}</p>
                </div>
                <h1>{{ popupData.product.name }}</h1>
            </div>
            <!--상품명, 버튼 끝-->

            <!--내용 시작-->
            <div class="product">
                <div class="ask">
                    <div class="ask_icon">
                        <img src="@/assets/images/common/icon_memo_w.svg" alt="ticket" />
                    </div>
                    <h1>공급가 요청 취소</h1>
                    <p>공급가 요청을 취소하시겠습니까?</p>
                </div>
            </div>
            <!--내용 끝-->

            <!--적용 버튼 시작-->
            <div class="last_btn_wrap">
                <button type="button" class="common_button" @click="cancelRequest">
                    <p>확인</p>
                </button>
                <button type="button" class="sub_button" @click="this.which = 1">
                    <p>이전</p>
                </button>
            </div>
            <!--적용 버튼 끝-->
        </div>
    </div>
    <!--팝업 - 판매제안취소 끝-->
</template>

<script>
import BoardPagination from '@/components/BoardPagination'
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination]);

import { fetchData } from '@/utils/fetchData';
import { mapState } from 'vuex';
import { numberFormat } from '@/utils/numberFormat';
import { dateFormat } from '@/utils/dateFormat';
import { postData } from '@/utils/postData';

export default {
    mixins: [fetchData, numberFormat, dateFormat, postData],
    components: {
        Swiper,
        SwiperSlide,
        BoardPagination
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('access_token', ['access_token']),
    },
    data() {
        return {
            list: [],
            which: 0,
            popupData: {},

            swiperOption: {
                loop: true,
                navigation: true,
                pagination: true,
            },

            currentPage : 1,    // 현재 페이지
            maxShowList : 10,   // 한 페이지 보일 최대 리스트수
            maxShowPage : 5,    // 한 화면에 보일 최대 페이지수
        };
    },
    created() {
        this.fetchListData();
    },
    methods: {
        // 공급가 요청 불러오기
        fetchListData() {
            this.fetchData(
                '/getSupplyProduct',
                (data) => {
                    this.list = data.list.sort((a,b)=>{
                        const a_date = new Date(a.enrollment_date);
                        const b_date = new Date(b.enrollment_date);
                        return a_date > b_date ? -1 : a_date < b_date ? 1 : 0;
                    });
                },
            );
        },
        cancelRequest() {
            const formData = new FormData();
            // formData.append('access_token', this.access_token);
            formData.append('exposure_request_pk', this.popupData.exposure_request_pk);
            this.postData('/deletePriceExposure', formData, (data) => {
                if (data.success) {
                    this.$store.commit('alert', '공급가 요청을 취소하였습니다.');
                    this.fetchListData();
                    this.closePopup();
                }
            });
        },
        // 페이지 변경
        setPage(num){
            this.currentPage = num;
        },
        sliceList(arr){
            return arr.length == 0 ? [] : arr.slice( (this.currentPage-1)*this.maxShowList, this.currentPage*this.maxShowList );
        },
        openPopup(item) {
            this.popupData = item.detail;
            this.popupData.supply_state = item.state;
            this.popupData.token = item.token;
            this.which = 1;
        },
        closePopup() {
            this.which = 0;
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_matching.css"></style>
<style scoped src="@/assets/css/components/commonPopup_v2.css"></style>
