export const changeMetaToken = {
  methods: {
    // 메타토큰 사용
    changeMetaToken(sign, event) {
      // 10-> 0 -> 20으로 바꿀 때 포인터가 맨 뒤로가는거 방지 (replace처리 막기)
      if(sign == '=' && event.target.value == 0 ){
        this.meta_token = 0;
        event.target.value = 0;
        return;
      }
      event.target.value = event.target.value.replace(/[^0-9]/g, '').replace(/(^0+)/, "");
      if (sign == "-") {
        if (this.meta_token >= 1) {
          --this.meta_token;
        }
      } else if (sign == "+") {
        if (this.user.meta_token >= this.meta_token + 1) {
          ++this.meta_token;
        }
      } else {
        if (event.target.value < 0 || event.target.value=="") {
          this.meta_token = 0;
          event.target.value = 0;
        } else if (event.target.value > this.user.meta_token) {
          this.meta_token = this.user.meta_token;
          event.target.value = this.user.meta_token;
        } else {
          this.meta_token = event.target.value;
        }
      }
    },
  },
}
