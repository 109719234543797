<template>
    <div class="right_con">
        <!--profile 시작-->
        <div class="profile">
            <h2>프로필</h2>
            <div class="profile_wrap">
                <div class="profile_image_wrap">
                    <div class="profile_img" v-if="this.user.save_name">
                        <img :src="this.user.path + this.user.save_name" :alt="this.user.original_name" />
                    </div>
                    <div class="profile_img" v-else>
                        <img src="@/assets/images/common/charactor_01.png" alt="프로필사진" />
                    </div>
                </div>
                <div class="text">
                    <div class="user_class">
                        <p>{{ userData.level.content }}</p>
                    </div>
                    <div class="user_name_wrap">
                        <span
                            v-bind:class="
                                this.user.user_division == '판매자'
                                    ? 'label_seller'
                                    : this.user.user_division == '공급자'
                                    ? 'label_provider'
                                    : !this.user.user_division
                                    ? 'label_none'
                                    : ''
                            "
                            >{{ this.user.user_division }}</span
                        >
                        <h1 v-if="this.user.user_division">{{ this.user.name }}</h1>
                        <h1 v-else>닉네임 없음</h1>
                    </div>

                    <div class="toggle_wrap" v-if="this.user.user_division">
                        <div class="switch_toggle">
                            <input type="checkbox" id="active" @change="changeActive" />
                            <label for="active"></label>
                        </div>
                        <p>{{ this.userData.state ? '활동 중' : '비활동' }}</p>
                    </div>
                </div>
            </div>

            <div class="btn_box" v-if="this.user.user_division" @click="goProfilePage">
                <div class="icon_wrap">
                    <img src="@/assets/images/mypage/icon_setting_g.svg" alt="설정" />
                    <img src="@/assets/images/mypage/icon_setting_b.svg" alt="설정" />
                </div>
                <p>프로필 관리</p>
            </div>
        </div>
        <!--profile 끝-->

        <!--결제 현황 시작-->
        <div class="pay">
            <h2>결제 현황</h2>
            <div class="pay_scale_wrap">
                <div class="pay_scale">
                    <img src="@/assets/images/mypage/icon_pay_01.svg" alt="토큰" />
                    <div class="text">
                        <p>보유 토큰</p>
                        <h1 v-if="this.userData.token">{{ this.numberFormat(this.userData.token) }}<span>개</span></h1>
                        <h1 v-else>0<span>개</span></h1>
                    </div>
                    <router-link to="/mypage/MypageBuyToken" class="text_link">
                        <p>구매</p>
                        <span class="gt">></span>
                    </router-link>
                </div>

                <div class="pay_scale">
                    <img src="@/assets/images/mypage/icon_pay_02.svg" alt="요금제" />
                    <div class="text">
                        <p>요금제</p>
                        <h1>{{ this.userData.plans_yn ? '가입' : '미가입' }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <!--결제 현황 끝-->

        <!--매칭 현황 시작-->
        <div class="matching" v-if="this.user.user_division">
            <h2>매칭 현황</h2>
            <div class="match_scale_wrap" v-if="this.user.user_division == '판매자'">
                <div
                    class="match_scale"
                    @click="
                        () => {
                            this.$router.push('/mypage/ManageMatching');
                        }
                    "
                >
                    <p>등록한 매칭</p>
                    <span>{{ this.numberFormat(userData.product_count) }}</span>
                </div>
                <div
                    class="match_scale"
                    @click="
                        () => {
                            this.$router.push('/mypage/RequestSeller');
                        }
                    "
                >
                    <p>제안한 거래</p>
                    <span>{{ this.numberFormat(userData.request_count) }}</span>
                </div>
                <div
                    class="match_scale"
                    @click="
                        () => {
                            this.$router.push('/mypage/ProposalSeller');
                        }
                    "
                >
                    <p>제안받은 거래</p>
                    <span>{{ this.numberFormat(userData.response_count) }}</span>
                </div>
                <div
                    class="match_scale"
                    @click="
                        () => {
                            this.$router.push('/mypage/CompleteSeller');
                        }
                    "
                >
                    <p>완료된 거래</p>
                    <span>{{ this.numberFormat(userData.complete_count) }}</span>
                </div>
            </div>
            <div class="match_scale_wrap" v-else-if="this.user.user_division == '공급자'">
                <div
                    class="match_scale"
                    @click="
                        () => {
                            this.$router.push('/mypage/ManageProduct');
                        }
                    "
                >
                    <p>등록한 상품</p>
                    <span>{{ this.numberFormat(userData.product_count) }}</span>
                </div>
                <div
                    class="match_scale"
                    @click="
                        () => {
                            this.$router.push('/mypage/RequestProvider');
                        }
                    "
                >
                    <p>제안한 거래</p>
                    <span>{{ this.numberFormat(userData.request_count) }}</span>
                </div>
                <div
                    class="match_scale"
                    @click="
                        () => {
                            this.$router.push('/mypage/ProposalProvider');
                        }
                    "
                >
                    <p>제안받은 거래</p>
                    <span>{{ this.numberFormat(userData.response_count) }}</span>
                </div>
                <div
                    class="match_scale"
                    @click="
                        () => {
                            this.$router.push('/mypage/CompleteProvider');
                        }
                    "
                >
                    <p>완료된 거래</p>
                    <span>{{ this.numberFormat(userData.complete_count) }}</span>
                </div>
            </div>
            <div class="match_scale_wrap" v-else>
                <div class="match_scale" v-for="i in 4" :key="i">
                    <p>-</p>
                    <span>-</span>
                </div>
            </div>
        </div>
        <!--매칭 현황 끝-->

        <!--광고 현황 시작-->
        <div class="matching ad" v-if="this.user.user_division == '공급자'">
            <h2>광고 현황</h2>
            <div class="match_scale_wrap">
                <div class="match_scale">
                    <p>신청</p>
                    <span>{{ userData.ad_request_count }}</span>
                </div>
                <div class="match_scale">
                    <p>검수</p>
                    <span>{{ userData.ad_inspection_count }}</span>
                </div>
                <div class="match_scale">
                    <p>승인</p>
                    <span>{{ userData.ad_approval_count }}</span>
                </div>
                <div class="match_scale">
                    <p>종료</p>
                    <span>{{ userData.ad_end_count }}</span>
                </div>
            </div>
        </div>
        <!--광고 현황 끝-->

        <!--AI 매칭 알림 시작-->
        <div class="ai_matching" style="margin: 0" v-if="this.user.user_division">
            <h2>AI매칭 알림 설정</h2>
            <div class="match_setting_wrap">
                <!--상품매칭 설정 시작-->
                <div class="match_setting">
                    <div class="top">
                        <p>상품 매칭 알림</p>
                        <div class="switch_toggle" v-if="this.user.user_division == '판매자'">
                            <input type="checkbox" id="ai_match" />
                            <label for="ai_match"></label>
                        </div>
                    </div>

                    <div class="setting" v-if="this.user.user_division == '판매자'">
                        <div class="on">
                            <label>
                                <input type="checkbox" />
                                <p>상품명 노출영역 상품명 노출영역 상품명 노출영역</p>
                            </label>
                            <label>
                                <input type="checkbox" />
                                <p>상품명 노출영역</p>
                            </label>
                            <label>
                                <input type="checkbox" />
                                <p>상품명 노출영역</p>
                            </label>
                        </div>
                    </div>

                    <div class="setting" v-if="this.user.user_division == '공급자'">
                        <div class="off">
                            <img src="@/assets/images/mypage/icon_stop.svg" alt="멈춤 표시" />
                            <p>매칭이 되지 않는 항목입니다.</p>
                        </div>
                    </div>

                    <a href="javascript:void(0)" v-if="this.user.user_division == '판매자'">+</a>
                </div>
                <!--상품매칭 설정 끝-->

                <!--공급자 매칭 설정 시작-->
                <div class="match_setting">
                    <div class="top">
                        <p>공급자 매칭 알림</p>
                        <div class="switch_toggle" v-if="this.user.user_division == '판매자'">
                            <input type="checkbox" id="prodider_match" />
                            <label for="prodider_match"></label>
                        </div>
                    </div>

                    <div class="setting" v-if="this.user.user_division == '판매자'">
                        <div class="on">
                            <label>
                                <input type="checkbox" />
                                <p>상품명 노출영역 상품명 노출영역 상품명 노출영역</p>
                            </label>
                            <label>
                                <input type="checkbox" />
                                <p>상품명 노출영역</p>
                            </label>
                            <label>
                                <input type="checkbox" />
                                <p>상품명 노출영역</p>
                            </label>
                        </div>
                    </div>

                    <div class="setting" v-if="this.user.user_division == '공급자'">
                        <div class="off">
                            <img src="@/assets/images/mypage/icon_stop.svg" alt="멈춤 표시" />
                            <p>매칭이 되지 않는 항목입니다.</p>
                        </div>
                    </div>

                    <a href="javascript:void(0)" v-if="this.user.user_division == '판매자'">+</a>
                </div>
                <!--공급자 매칭 설정 끝-->

                <!--판매자 매칭 설정 시작-->
                <div class="match_setting">
                    <div class="top">
                        <p>판매자 매칭 알림</p>
                        <div class="switch_toggle" v-if="this.user.user_division == '공급자'">
                            <input type="checkbox" id="seller_match" />
                            <label for="seller_match"></label>
                        </div>
                    </div>

                    <div class="setting" v-if="this.user.user_division == '공급자'">
                        <div class="on">
                            <label v-for="(item,idx) in this.aiMatchingData.seller" :key="idx">
                                <input type="checkbox">
                            </label>
                            <!-- <label>
                                <input type="checkbox" />
                                <p>상품명 노출영역 상품명 노출영역 상품명 노출영역</p>
                            </label>
                            <label>
                                <input type="checkbox" />
                                <p>상품명 노출영역</p>
                            </label>
                            <label>
                                <input type="checkbox" />
                                <p>상품명 노출영역</p>
                            </label> -->
                        </div>
                    </div>

                    <div class="setting" v-if="this.user.user_division == '판매자'">
                        <div class="off">
                            <img src="@/assets/images/mypage/icon_stop.svg" alt="멈춤 표시" />
                            <p>매칭이 되지 않는 항목입니다.</p>
                        </div>
                    </div>

                    <a href="javascript:void(0)" v-if="this.user.user_division == '공급자'">+</a>
                </div>
                <!--판매자 매칭 설정 끝-->
            </div>
        </div>
        <!--AI 매칭 설정 끝-->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { fetchData } from '@/utils/fetchData';
import { postData } from '@/utils/postData';
import { numberFormat } from '@/utils/numberFormat';

export default {
    mixins: [fetchData, postData, numberFormat],
    data() {
        return {
            levelList: ['basic', 'expert', 'goodman', 'highclass', 'toplevel'],
            levelIndex: 0,
            userData: {
                // 매칭 현황 데이터
                product_count: 0, // 등록한 상품
                request_count: 0, // 제안한 거래
                response_count: 0, // 제안받은 거래
                complete_count: 0, // 완료된 거래

                // 광고 현황
                ad_request_count: 0, // 신청
                ad_inspection_count: 0, // 검수
                ad_approval_count: 0, // 승인
                ad_end_count: 0, // 종료
                level: {
                    content: '',
                    icon: '',
                    level: '',
                    level_pk: 0,
                    use_yn: '',
                },
                plans_yn: false,
                state: false,
                token: 0,
            },
            aiMatchingData: {
                goods: [],
                provider: [],
                seller: [],
                isSellerOn: false,
            },
        };
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('access_token', ['access_token']),
    },
    mounted() {
        this.fetchUserData();
    },
    methods: {
        fetchUserData() {
            this.fetchData(
                '/getMypage',
                (data) => {
                    this.userData = data.data;
                    this.userData = {
                        ...this.userData,
                        state: data.data.state === '정상' ? true : false,
                        plans_yn: data.data.state == 'true' ? true : false,
                    };

                    // 체크박스 활성
                    document.querySelector('div.user_class').classList.add(data.data.level.icon);
                    if(this.user.user_division){
                        document.querySelector('input#active').checked = data.data.state === '정상' ? true : false;
                    }

                    // document.querySelector("input#seller_match").checked =
                    //   this.aiMatchingData.isSellerOn;
                },
            );
        },
        goProfilePage() {
            this.$router.push('/mypage/MypagePartnerInfo');
            // if (this.user.user_division === "판매자") {
            //   this.$router.push("/mypage/ProfileSeller");
            // } else {
            //   this.$router.push("/mypage/ProfileProvider");
            // }
        },
        changeActive(event) {
            this.userData.state = event.target.checked;
            const formData = new FormData();
            // formData.append('access_token', this.access_token);
            formData.append('state', this.userData.state ? '정상' : '비활성');

            this.postData('/saveUserState', formData);
        },
        checkIsSellerOn(event) {
            this.aiMatchingData.isSellerOn = event.target.checked;
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_home.css"></style>
