<template>
    <div class="payManage cardManage">
        <!--등록한 카드 시작-->
        <div class="card_wrap">
            <h1>등록한 카드<span>최대 3장</span></h1>
            <div class="inner">
                <template v-for="card in cardList" :key="card.card_pk">
                    <div
                        class="card"
                        :class="card.card_division"
                        @click="clickHandler($event, card.card_pk)"
                        @mouseleave="mouseoutHandler($event)"
                    >
                        <h6 v-if="card.basic_yn">기본</h6>
                        <span class="card_opacity"></span>
                        <div class="btn_wrap">
                            <button type="button" class="common_button" @click="setDefaultCard(card.card_pk)">
                                <div class="icon_wrap">
                                    <img src="@/assets/images/common/icon_done_w.svg" alt="done" />
                                </div>
                                <p>기본 설정</p>
                            </button>
                            <button
                                v-show="!card.basic_yn"
                                type="button"
                                class="common_button"
                                @click="removeCard(card.card_pk)"
                            >
                                <div class="icon_wrap">
                                    <img src="@/assets/images/common/icon_delete_w.svg" alt="delete" />
                                </div>
                                <p>카드 삭제</p>
                            </button>
                        </div>
                    </div>
                </template>
                <div v-if="cardList.length < 3" class="card card_add" @click="addCard"></div>
            </div>
        </div>
        <!--등록한 카드 끝-->

        <!--신규 카드 등록 시작-->
        <div class="register_wrap" v-show="clickedAddCard || updateCard">
            <h1>{{ this.updateCard ? '카드 정보 수정' : '신규 카드 등록' }}<span>카드 정보 입력</span></h1>

            <!--left 시작-->
            <div class="left fill">
                <!-- <h1>
                {{ this.updateCard ? "카드 정보 수정" : "신규 카드 등록"
                }}<span>카드 정보 입력</span>
            </h1> -->
                <!--사업자 유형 시작-->
                <div class="fill_radio">
                    <h2>사업자 유형</h2>
                    <div class="type">
                        <label>
                            <input type="radio" name="card_register" v-model="isPersonalCard" value="개인" />
                            <p id="personal_card" :class="personalCardClass" @click="this.isPersonalCard = '개인'">
                                개인
                            </p>
                        </label>
                        <label>
                            <input type="radio" name="card_register" v-model="isPersonalCard" value="법인" />
                            <p id="corporation_card" :class="corpCardClass" @click="this.isPersonalCard = '법인'">
                                법인
                            </p>
                        </label>
                    </div>
                </div>
                <!--사업자 유형 끝-->

                <!--카드번호 시작-->
                <div class="fill_number fill_card_num">
                    <h2>카드번호</h2>
                    <input type="text" placeholder="1111-2222-3333-4444" v-model="cardNumber" maxlength="19" />
                </div>
                <!--카드번호 끝-->

                <!--유효기간 시작-->
                <div class="fill_number fill_card_date">
                    <h2>유효기간</h2>
                    <input type="text" placeholder="MM" v-model="monthOfExpired" maxlength="2" />
                    <span>/</span>
                    <input type="text" placeholder="YY" v-model="yearOfExpired" maxlength="2" />
                </div>
                <!--유효기간 끝-->

                <!--비밀번호 시작-->
                <div class="fill_number fill_password">
                    <h2>비밀번호</h2>
                    <input type="password" placeholder="앞 2자리" v-model="cardPassword" maxlength="2" />
                </div>
                <!--비밀번호 끝-->

                <!--이름 시작-->
                <div class="fill_text fill_name">
                    <h2>이름</h2>
                    <input type="text" placeholder="카드 명의로 된 이름을 적어주세요." v-model="ownerName" />
                </div>
                <!--이름 끝-->

                <!-- <div class="agree">
                <label>
                    <input type="checkbox" v-model="agreement" />
                    서비스 이용약관에 동의합니다.
                </label>
            </div> -->
            </div>
            <!--left 끝-->
            <div class="line"></div>

            <!--right 시작-->
            <div class="right fill">
                <h2>카드사 선택</h2>
                <!-- 카드 목록 시작-->
                <div class="card_category_wrap">
                    <div class="card_box" v-for="(card, idx) in cardCategory" :key="idx">
                        <span class="">
                            <img src="@/assets/images/common/icon_done_w.svg" />
                        </span>
                        <div class="card card_category" :class="card" :value="card" @click="selectCard($event, card)">
                            <!-- <span class="card_opacity"></span> -->
                        </div>
                    </div>
                </div>
                <!--카드 목록 끝-->
            </div>
            <!--right 끝-->

            <div class="last_wrap">
                <div class="agree">
                    <label>
                        <input type="checkbox" v-model="agreement" />
                        서비스 이용약관에 동의합니다.
                    </label>
                </div>
                <div class="btn_wrap">
                    <button type="submit" class="common_button" @click="submit">
                        {{ this.updateCard ? '수정하기' : '등록하기' }}
                    </button>
                    <button type="submit" class="sub_button" @click="close">닫기</button>
                </div>
            </div>
        </div>
        <!--신규 카드 등록 끝-->
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { businessRegFormat } from '@/utils/businessRegFormat';
import { fetchData } from '@/utils/fetchData';
import { postData } from '@/utils/postData';

export default {
    mixins: [businessRegFormat, fetchData, postData],
    data() {
        return {
            isPersonalCard: '개인', // 개인 카드:true, 법인 카드:false

            // 법인 카드
            // isCorporationPublic: false, // 법인 카드 중 개별:false, 공용:true

            // input values
            cardNumber: '',
            monthOfExpired: '',
            yearOfExpired: '',

            birthday: '', // 2022-01-24
            businessNumber: '',

            cardPassword: '',
            ownerName: '',
            selectedCard: '',
            selectedCardPk: '',
            selectedCardDefault: false,
            // 이용약관 동의
            agreement: false,
            // 카드 목록
            cardCategory: [
                'card_bc',
                'card_city',
                'card_hana',
                'card_hyundai',
                'card_ibk',
                'card_kb',
                'card_lotte',
                'card_nh',
                'card_samsung',
                'card_sh',
                'card_shinhan',
                'card_woori',
            ],
            // my card list
            cardList: [],

            // event 관련
            isClicked: false,
            clickedAddCard: false,
            updateCard: false,
        };
    },
    watch: {
        businessNumber(newNum) {
            this.businessNumber = this.businessRegFormat(newNum);
        },
        cardNumber(newNum) {
            this.cardNumber = this.cardNumberFormat(newNum);
        },
        monthOfExpired(newNum) {
            const replaced = this.checkNum(newNum);

            if (Number(replaced) > 12) {
                this.monthOfExpired = '12';
            } else {
                this.monthOfExpired = replaced;
            }
        },
        yearOfExpired(newNum) {
            this.yearOfExpired = this.checkNum(newNum);
        },
        cardPassword(newPassword) {
            this.newPassword = this.checkNum(newPassword);
        },
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('access_token', ['access_token']),

        personalCardClass() {
            return this.isPersonalCard === '개인' ? 'on' : '';
        },
        corpCardClass() {
            return this.isPersonalCard === '개인' ? '' : 'on';
        },
        corpPrivateCardClass() {
            return this.isCorporationPublic ? '' : 'on';
        },
        corpPublicCardClass() {
            return this.isCorporationPublic ? 'on' : '';
        },
        toggleStyle() {
            return this.isPersonalCard === '개인' ? { left: '0px' } : { left: '120px' };
        },
        division() {
            return this.isPersonalCard === '개인' ? '개인' : '법인';
        },
    },
    created() {
        this.fetchCardData();
    },
    methods: {
        fetchCardData() {
            this.fetchData(
                '/getCard',
                (data) => {
                    this.cardList = data.list;

                    if (this.cardList.length > 3) {
                        this.cardList.splice(3, 1);
                    }
                    if (this.cardList.length === 0) {
                        this.clickedAddCard = true;
                    } else {
                        this.cardList.forEach((item) => {
                            item.basic_yn = item.basic_yn === 'true' ? true : false;
                        });
                    }
                },
            );
        },
        checkNum(num) {
            if (!num) {
                return '';
            }
            return num.replace(/[^0-9]/g, '');
        },
        cardNumberFormat(num) {
            if (!num) {
                return '';
            }
            return num.replace(/[^0-9]/g, '').replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');
        },
        openUpdateForm(card_pk) {
            const selectedCard = this.cardList.find((item) => item.card_pk == card_pk);
            this.selectedCardPk = card_pk;
            this.selectedCardDefault = selectedCard.basic_yn;
            this.isPersonalCard = selectedCard.division;
            this.cardNumber = selectedCard.number;
            this.monthOfExpired = selectedCard.expiration_month;
            this.yearOfExpired = selectedCard.expiration_year;
            this.cardPassword = '';
            this.selectedCard = selectedCard.card_division;

            document.querySelectorAll('.card_box span').forEach((el) => {
                el.classList.remove('choose');
                el.style.removeProperty('z-index');
                el.nextElementSibling.style.removeProperty('opacity');
            });
            const el = document.querySelector(`.card_category.${selectedCard.card_division}`);

            //el.parentElement.style.setProperty("opacity", 0.5);
            el.previousElementSibling.classList.add('choose');
            el.previousElementSibling.style.setProperty('z-index', 20);
            el.style.setProperty('opacity', 0.7);
            // el.style.setProperty("border", "2px solid var(--point-color)");
            this.ownerName = selectedCard.name;
        },
        clickHandler(event, card_pk) {
            this.updateCard = true;
            this.openUpdateForm(card_pk);

            event.currentTarget.querySelector('span.card_opacity').style.setProperty('display', 'block');

            event.currentTarget.querySelector('.btn_wrap').style.setProperty('display', 'flex');
        },
        mouseoutHandler(event) {
            event.currentTarget.querySelector('span.card_opacity').style.setProperty('display', 'none');
            event.currentTarget.querySelector('.btn_wrap').style.setProperty('display', 'none');
        },
        addCard() {
            this.clearAddInput();
            this.clickedAddCard = true;
            this.updateCard = false;
            this.selectedCardDefault = false;
            this.selectedCard = '';
            this.selectedCardPk = '';
            document.querySelectorAll('.card_box').forEach((el) => {
                el.style.removeProperty('opacity');
                el.firstChild.style.removeProperty('border');
            });
        },
        removeCard(card_pk) {
            if (this.cardList.find((item) => item.card_pk === card_pk).basic_yn === true) {
                this.$store.commit('alert', '기본 카드로 설정된 카드는 삭제하실 수 없습니다.');
            } else {
                this.$store.commit('confirm', {
                    content: '정말로 삭제하시겠습니까?',
                    callback: () => {
                        const formData = new FormData();
                        // formData.append('access_token', this.access_token);
                        formData.append('card_pk', card_pk);
                        this.postData('/deleteCard', formData, () => {
                            this.$store.commit('alert', '카드가 삭제되었습니다.');
                            this.cardList = this.cardList.filter((card) => card.card_pk !== card_pk);
                            this.close();
                        });
                    },
                });
            }
        },
        updateDefault(card_pk) {
            const card = this.cardList.find((item) => item.card_pk === card_pk);
            card.basic_yn = true;

            this.cardList.forEach((item) => {
                if (item.card_pk === card_pk) {
                    item.basic_yn = true;
                } else {
                    item.basic_yn = false;
                }
            });
            /** TODO : set default card api */
            const formData = new FormData();
            // formData.append('access_token', this.access_token);
            formData.append('card_pk', card_pk);
            formData.append('basic_yn', true);

            this.postData('/saveBasicCard', formData, () => {
                this.clickedAddCard = false;
                this.updateCard = false;
                this.clearAddInput();
            });
        },
        setDefaultCard(card_pk) {
            if (this.cardList.find((item) => item.card_pk === card_pk).basic_yn) {
                this.$store.commit('alert', '이미 기본 카드로 설정되어있습니다.');
            } else {
                this.$store.commit('confirm', {
                    content: '기본 카드로 설정하시겠습니까?',
                    callback: () => {
                        this.updateDefault(card_pk);
                    },
                });
            }
        },
        selectCard(event, card) {
            this.selectedCard = card;
            document.querySelectorAll('.card_box span').forEach((el) => {
                el.classList.remove('choose');
                el.style.removeProperty('z-index');
                el.nextElementSibling.style.removeProperty('opacity');
            });
            //event.currentTarget.parentElement.style.setProperty("opacity", 0.6);
            event.currentTarget.style.setProperty('opacity', 0.7);
            event.currentTarget.previousElementSibling.classList.add('choose');
            event.currentTarget.previousElementSibling.style.setProperty('z-index', 20);
        },
        checkFormValid() {
            return (
                this.division.length > 0 &&
                this.selectedCard.length > 0 &&
                this.ownerName.length > 0 &&
                this.cardNumber.length > 0 &&
                this.monthOfExpired.length > 0 &&
                this.yearOfExpired.length > 0 &&
                this.cardPassword.length > 0 &&
                this.agreement
            );
        },
        clearAddInput() {
            this.isPersonalCard = '개인'; //personal, business
            this.ownerName = '';
            this.cardNumber = '';
            this.yearOfExpired = '';
            this.monthOfExpired = '';
            this.cardPassword = '';
            this.selectedCard = '';
            this.selectedCardDefault = false;
            this.selectedCardPk = '';
        },
        close() {
            this.clearAddInput();
            this.updateCard = false;
            this.clickedAddCard = false;
        },
        submit() {
            if (this.selectedCardDefault) {
                this.$store.commit('alert', '기본 카드로 설정 된 카드는 수정할 수 없습니다.');
            } else if (this.checkFormValid()) {
                const formData = new FormData();
                // formData.append('access_token', this.access_token);
                formData.append('card_pk', this.selectedCardPk);
                formData.append('division', this.division); //personal, business
                formData.append('card_division', this.selectedCard);
                formData.append('number', this.cardNumber);
                formData.append('expiration_month', this.monthOfExpired);
                formData.append('expiration_year', this.yearOfExpired);
                formData.append('name', this.ownerName);
                formData.append('password', this.cardPassword);
                formData.append('basic_yn', false);
                
                this.postData('/saveCard', formData, () => {
                    this.clickedAddCard = false;
                    this.clearAddInput();
                    this.$store.commit('alert', '카드가 등록 되었습니다.');
                    this.fetchCardData();
                });
            } else {
                if (this.division.length === 0) {
                    this.$store.commit('alert', '카드 종류(개인,법인)를 선택해주세요.');
                } else if (this.cardNumber.length < 19) {
                    this.$store.commit('alert', '카드 번호를 입력해주세요.');
                } else if (this.monthOfExpired.length <= 1 || this.yearOfExpired.length <= 1) {
                    this.$store.commit('alert', '유효 기간을 입력해주세요.');
                } else if (this.cardPassword.length < 2) {
                    this.$store.commit('alert', '카드 비밀번호를 입력해주세요.');
                } else if (this.ownerName.length <= 1) {
                    this.$store.commit('alert', '소유자 이름을 입력해주세요.');
                } else if (this.selectedCard.length === 0) {
                    this.$store.commit('alert', '카드사를 선택해주세요.');
                } else if (this.cardNumber.length < 16) {
                    this.$store.commit('alert', '카드 번호를 입력해주세요.');
                } else if (!this.agreement) {
                    this.$store.commit('alert', '서비스 이용약관에 동의해주세요.');
                }
            }
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_pay.css"></style>
