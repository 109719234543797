<template>
    <!-- 제안한 거래 시작 -->
    <div class="right_con">
        <div class="toggle_wrap">
            <div class="toggle" :style="toggleStyle"></div>
            <ul class="main_menu">
                <li :class="this.whichTab === 0 ? 'on' : ''" @click="clickTab(0)">상품 제안</li>
                <li :class="this.whichTab === 0 ? '' : 'on'" @click="clickTab(1)">매칭 제안</li>
            </ul>
        </div>
        <div class="common_table p_request" v-if="this.whichTab == 0">
            <ul class="top">
                    <li class="w150">판매자</li>
                    <li class="product">상품명</li>
                    <li class="w80">공급가</li>
                    <li class="w80">입찰토큰</li>
                    <li class="w70">신청상태</li>
                    <li class="w100">마감일</li>
                    <li class="w100">등록일</li>
                    <li class="w50">관리</li>
            </ul>
            <ul v-for="(item, i) in sliceList" :key="i" class="content">
                    <li class="w150 name">
                        <p>
                            {{ item.seller_name }}
                        </p>        
                    </li>
                    <li class="product">
                        <p>{{ item.product_name }}</p>
                    </li>
                    <li class="w80">
                        <p>
                            {{ this.numberFormat(item.supply_price) }}원
                        </p>        
                    </li>
                    <li class="w80">
                        <p>
                            {{ this.numberFormat(item.request_token) }}개
                        </p>
                    </li>
                    <li class="w70">
                        <p>
                            {{ item.state }}
                        </p>    
                    </li>
                    <li class="w100 sub3">
                        <p>
                            {{ item.finish_date ? this.dateFormat(item.finish_date) : '-' }}
                        </p>
                    </li>
                    <li class="w100 sub3">
                        <p>
                            {{ this.dateFormat(item.request_date) }}
                        </p>
                    </li>
                    <li class="w50 icon" @click="openPopup(item)">
                        <img src="@/assets/images/mypage/icon_manage.svg" alt="관리" />
                    </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>제안한 내역이 없습니다.</li>
            </ul>
        </div>

        <div class="common_table p_request" v-else>
            <ul class="top">
                    <li class="w150">판매자</li>
                    <li class="product">상품명</li>
                    <li class="w80">상품단가</li>
                    <li class="w80">입찰토큰</li>
                    <li class="w70">신청상태</li>
                    <li class="w100">마감일</li>
                    <li class="w100">등록일</li>
                    <li class="w50">관리</li>
            </ul>
            <ul v-for="(item, i) in sliceList" :key="i" class="content">
                    <li class="w150">
                        <p>
                            {{ item.seller_name }}
                        </p>
                    </li>
                    <li class="product">
                        <p>{{ item.matching_name }}</p>
                    </li>
                    <li class="w80"><p>{{ item.price }}</p></li>
                    <li class="w80">{{ this.numberFormat(item.request_token) }}개</li>
                    <li class="w70">{{ item.state }}</li>
                    <li class="w100">
                        {{ item.finish_date ? this.dateFormat(item.finish_date) : '-' }}
                    </li>
                    <li class="w100">
                        {{ this.dateFormat(item.request_date) }}
                    </li>
                    <li class="w50 icon" @click="openPopup(item)">
                        <img src="@/assets/images/mypage/icon_manage.svg" alt="관리" />
                    </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>제안한 내역이 없습니다.</li>
            </ul>
        </div>

            <BoardPagination
                :listLength="this.list.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
    </div>
    <!-- 제안한 거래 끝 -->

    <!--팝업 시작-->
    <div class="screen_filter" v-if="this.showPopup"></div>
    <div class="popup popup_seller" v-if="this.showPopup">
        <button type="button" @click="closePopup">
            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
        </button>
        
        <!-- 상품 제안 팝업 시작 -->
        <div class="inner" v-if="this.whichTab == 0">
            <!--상품명, 버튼 시작-->
            <div class="product_title">
                <div class="user">
                    <span>판매자</span>
                    <p>{{ popupData.seller_name }}</p>
                </div>
                <div class="btn_wrap">
                    <button type="button" class="sub_button" @click="cancelReques(false)">
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/icon_close.svg" alt="close" />
                        </div>
                    <p>제안취소</p>
                    </button>
                </div>
                <h1>{{ popupData.product_name }}</h1>
            </div>
            <!--상품명, 버튼 끝-->

            <!--내용 시작-->
            <div class="product">
                <div class="slide_wrap">
                        <div
                        v-if="popupData.product_image.length == 0"
                        class="product_image default"
                        >
                        <img src="@/assets/images/common/no_image.svg"/>
                        </div>
                        <div v-else-if="popupData.product_image.length == 1"
                        class="product_image"
                        >
                        <img
                            :src="popupData.product_image[0].path + this.popupData.product_image[0].save_name"
                            :alt="popupData.product_image[0].original_name"
                        />
                        </div>
                        <swiper v-else v-bind="swiperOption">
                        <swiper-slide
                            v-for="(item, i) in popupData.product_image"
                            :key="i"
                        >
                            <div class="product_image">
                            <img
                                :src="item.path + item.save_name"
                                :alt="item.original_name"
                            />
                            </div>
                        </swiper-slide>
                        </swiper>
                </div>
                <div class="scale_wrap">
                    <div class="scale">
                        <p>최소구매수량</p>
                        <h1>{{ popupData.product_moq }}</h1>
                    </div>
                    <div class="scale">
                        <p>예상마진</p>
                        <h1>{{ popupData.product_margin }}<span></span></h1>
                    </div>
                    <div class="scale">
                        <p>공급가</p>
                        <h1>{{ numberFormat(popupData.supply_price) }}<span>원</span></h1>
                    </div>
                    <div class="scale">
                        <p>소비자가</p>
                        <h1>
                            {{ popupData.price === '매칭등록 안됨' ? popupData.consumer_price : numberFormat(popupData.consumer_price)
                            }}<span>원</span>
                        </h1>
                    </div>
                </div>

                <span class="line"></span>

                <div class="info_wrap">
                    <div class="info">
                        <p>마감일</p>
                        <h1>{{ this.dateFormat(popupData.finish_date) }}</h1>
                    </div>
                    <div class="info">
                        <p>최고 입찰 공급자</p>
                        <h1 v-if="popupData.best_provider">{{ popupData.best_provider }}</h1>
                        <h1 v-else>없음</h1>
                    </div>
                    <div class="info">
                        <p>최고 입찰 토큰</p>
                        <h1>{{ numberFormat(popupData.best_token) }}<span>개</span></h1>
                    </div>
                    <div class="info">
                        <p>내가 입찰한 토큰</p>
                        <h1>{{ numberFormat(popupData.request_token) }}<span>개</span></h1>
                    </div>
                </div>
            </div>
            <!--내용 끝-->
        </div>
        <!-- 상품 제안 팝업 끝 -->

        <!-- 매칭 제안 팝업 시작 -->
        <div class="inner" v-if="this.whichTab == 1">
            <!--상품명, 버튼 시작-->
            <div class="product_title">
                <div class="user user_seller">
                    <span>판매자</span>
                    <p>{{ popupData.seller_name }}</p>
                </div>
                <div class="btn_wrap">
                    <button type="button" class="common_button" @click="cancelRequest(true)">
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
                        </div>
                        <p>제안취소</p>
                    </button>
                </div>
                <h1>{{ popupData.matching_name }}</h1>
            </div>
            <!--상품명, 버튼 끝-->

            <!--내용 시작-->
            <div class="product">
                <div class="slide_wrap">
                    <div
                        v-if="popupData.matching_image.length == 0"
                        class="product_image default"
                    >
                    <img src="@/assets/images/common/no_image.svg"/>
                    </div>
                    <div
                        v-else-if="popupData.matching_image.length == 1"
                        class="product_image"
                    >
                    <img
                        :src="popupData.matching_image[0].path + this.popupData.matching_image[0].save_name"
                        :alt="popupData.matching_image[0].original_name"
                    />
                    </div>
                    <swiper v-else v-bind="swiperOption">
                        <swiper-slide
                            v-for="(item, i) in popupData.matching_image"
                            :key="i"
                        >
                            <div class="product_image">
                            <img
                                :src="item.path + item.save_name"
                                :alt="item.original_name"
                            />
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>

                <div class="scale_wrap">
                    <div class="scale">
                        <p>최소구매수량</p>
                        <h1>{{ popupData.moq }}</h1>
                    </div>
                    <div class="scale">
                        <p>판매가능시점</p>
                        <h1>{{ popupData.start }}<span></span></h1>
                    </div>
                    <div class="scale">
                        <p>상품단가</p>
                        <h1>{{ popupData.price }}</h1>
                    </div>
                </div>

                <span class="line"></span>

                <div class="info_wrap">
                    <div class="info">
                        <p>마감일</p>
                        <h1>{{ this.dateFormat(popupData.finish_date) }}</h1>
                    </div>
                    <div class="info">
                        <p>제안일</p>
                        <h1>{{ this.dateFormat(popupData.request_date) }}</h1>
                    </div>
                    <div class="info">
                        <p>제안한 토큰</p>
                        <h1>{{ numberFormat(popupData.request_token) }}<span>개</span></h1>
                    </div>
                </div>
            </div>
            <!--내용 끝-->
        </div>
        <!-- 매칭 제안 팝업 끝 -->

    </div>
    <!--팝업 끝-->
</template>

<script>
import BoardPagination from '@/components/BoardPagination'
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination]);

import { mapState } from 'vuex';
import { fetchData } from '@/utils/fetchData';
import { numberFormat } from '@/utils/numberFormat';
import { dateFormat } from '@/utils/dateFormat';
import { postData } from '@/utils/postData';

export default {
    mixins: [fetchData, numberFormat, dateFormat, postData],
    components: {
        Swiper,
        SwiperSlide,
        BoardPagination,
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('access_token', ['access_token']),
        toggleStyle() {
            return this.whichTab === 0 ? { left: "0px" } : { left: "120px" };
        },
        sliceList(){
            return this.list.length == 0 ? [] : this.list.slice( (this.currentPage-1)*this.maxShowList, this.currentPage*this.maxShowList );
        },
    },
    watch : {
        whichTab(){
            this.currentPage = 1;
        }
    },
    data() {
        return {
            list: [],
            product_list : [],
            matching_list : [],
            whichTab : 0,

            showPopup: false,
            popupData: {},
            swiperOption: {
                loop: true,
                navigation: true,
                pagination: true,
            },

            currentPage : 1,    // 현재 페이지
            maxShowList : 10,   // 한 페이지 보일 최대 리스트수
            maxShowPage : 5,    // 한 화면에 보일 최대 페이지수
        };
    },
    created() {
        this.fetchListData();
    },
    methods: {
        fetchListData() {
            this.fetchData(
                '/getProposalSeller',
                (data) => {
                    this.product_list = data.product_list;
                    this.matching_list = data.matching_list;
                    this.list = data.product_list;
                },
            );
        },
        cancelRequest(matching_yn) {
            const formData = new FormData();
            // formData.append('access_token', this.access_token);
            formData.append('matching_yn', matching_yn);
            if(matching_yn){
                formData.append('matching_request_pk', this.popupData.matching_request_pk);
            } else {
                formData.append('request_pk', this.popupData.request_pk);
            }
            this.postData('/deleteRequest', formData, (data) => {
                if (data.success) {
                    this.$store.commit('alert', '거래를 취소하였습니다.');
                    this.fetchListData();
                    this.closePopup();
                }
            });
        },
        clickTab(tab) {
            this.whichTab = tab;
            this.list = tab === 0 ? this.product_list : this.matching_list;
        },
        // 페이지 변경
        setPage(num){
            this.currentPage = num;
        },
        openPopup(item) {
            this.popupData = item.detail;
            this.popupData.request_token = item.request_token;
            this.showPopup = true;
        },
        closePopup() {
            this.showPopup = false;
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_matching.css"></style>
<style scoped src="@/assets/css/components/commonPopup_v2.css"></style>
