<template>
    <div class="div_wrap" style="padding-bottom: 6rem">
        <div v-show="purchased" class="buytoken">
            <h1 class="title">바이셀 토큰 구매 확인</h1>
            <div class="last_wrap">
                <div>
                    <p>은행 : {{ this.nameOfBank }}</p>
                    <p>이름 : {{ this.nameOfAccountHolder }}</p>
                    <p>계좌번호 : {{ this.accountNumber }}</p>
                </div>
                <br />
                <button type="button" class="common_button" @click="this.$router.push('/mypage/myToken')">
                    확인
                </button>
            </div>
        </div>
        <div v-show="!purchased" class="buytoken">
            <h1 class="title">바이셀 토큰 구매<span>최소 구매 수량 3개</span></h1>

            <div class="left">
                <!--구매수량 시작-->
                <div class="buy_wrap">
                    <h2>구매 수량</h2>
                    <div class="buy_number">
                        <div class="fill_number">
                            <input type="text" :value="numberFormat(tokenToBuy)" @input="inNumber" maxlength="11" />
                            <p>개</p>
                        </div>
                        <div class="btn_wrap">
                            <button type="button" class="sub_button" @click="countUpToken(1)">
                                <p>+ 1</p>
                            </button>
                            <button type="button" class="sub_button" @click="countUpToken(5)">
                                <p>+ 5</p>
                            </button>
                            <button type="button" class="sub_button" @click="countUpToken(10)">
                                <p>+ 10</p>
                            </button>
                            <button type="button" class="sub_button" @click="countUpToken(50)">
                                <p>+ 50</p>
                            </button>
                        </div>
                    </div>
                </div>
                <!--구매수량 끝-->

                <!--구매금액 시작-->
                <div class="buy_wrap align_center">
                    <h2>구매 금액</h2>
                    <div class="buy_price">
                        <p>&#8361;</p>
                        <span>{{ totalPrice }}</span>
                    </div>
                </div>
                <!--구매금액 끝-->

                <!--구매 후 토큰 시작-->
                <div class="buy_wrap align_center">
                    <h2>구매 후 토큰</h2>
                    <div class="buy_after">
                        <p>{{ numberFormat(this.afterBuyToken) }}<span>개</span></p>
                    </div>
                </div>
                <!--구매 후 토큰 끝-->
            </div>

            <div class="line"></div>

            <!--결제수단 시작-->
            <div class="buy_wrap right">
                <h2>결제 수단</h2>
                <div class="pay_wrap">
                    <div class="pay_type_wrap">
                        <div id="pay_card" class="pay_type" @click="changePurchaseWay('카드')">
                            <div class="icon_wrap">
                                <img src="@/assets/images/mypage/icon_tap_07_b.svg" alt="카드" />
                            </div>
                            <p>카드</p>
                        </div>

                        <div id="pay_bank" class="pay_type on" @click="changePurchaseWay('무통장')">
                            <div class="icon_wrap">
                                <img src="@/assets/images/mypage/icon_deposit.svg" alt="무통장입금" />
                            </div>
                            <p>무통장 입금</p>
                        </div>
                    </div>

                    <div class="pay_fill_wrap fill">
                        <!--카드 선택 / 카드 등록 했을 때 시작-->
                        <div v-if="purchaseWay === '카드' && card_registered" class="pay_fill card type">
                            <div class="fill_radio">
                                <label>
                                    <input type="radio" name="card" />
                                    카드사
                                    <span>기본</span>
                                </label>
                                <label>
                                    <input type="radio" name="card" />
                                    카드사
                                </label>
                                <label>
                                    <input type="radio" name="card" />
                                    카드사
                                </label>
                            </div>
                        </div>
                        <!--카드 선택 / 카드 등록 했을 때 끝-->

                        <!--카드 선택 / 카드 등록 안했을 때 시작-->
                        <div v-if="purchaseWay === '카드' && !card_registered" class="pay_fill no_card">
                            <p>등록된 카드가 없습니다.</p>
                            <button type="button" class="sub_button" @click="this.$router.push('/mypage/cardManage')">
                                카드 등록하기
                            </button>
                        </div>
                        <!--카드 선택 / 카드 등록 안했을 때 끝-->

                        <!--무통장 입금 선택 시작-->
                        <div v-if="purchaseWay === '무통장'" class="pay_fill account fill">
                            <div class="fill_text">
                                <input type="text" placeholder="은행명" v-model="nameOfBank" disabled />
                            </div>
                            <div class="fill_text">
                                <input type="text" placeholder="계좌번호" :value="accountNumber" disabled />
                            </div>
                            <div class="fill_text">
                                <input type="text" placeholder="예금주" v-model="nameOfAccountHolder" disabled />
                            </div>
                        </div>
                        <!--무통장 입금 선택 끝-->
                    </div>
                </div>
            </div>
            <!--결제수단 끝-->

            <div class="last_wrap">
                <!--이용약관 시작-->
                <div class="fill_check">
                    <label>
                        <input type="checkbox" v-model="agreement" />
                        전자금융거래 이용약관에 동의합니다.
                    </label>
                </div>
                <!--이용약관 끝-->

                <button type="button" class="common_button" @click="buyToken">구매하기</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { fetchData } from '@/utils/fetchData';
import { postData } from '@/utils/postData';
import { numberFormat } from '@/utils/numberFormat';

const PRICE_PER_TOKEN = 10000;
export default {
    mixins: [fetchData, postData, numberFormat],
    data() {
        return {
            tokenToBuy: '0', // 구매 할 토큰 개수
            purchaseWay: '무통장', // 'card' : 카드, 'bank' : 무통장

            myToken: 0,
            // card data
            card_registered: true,
            myCardList: [],

            // bank data
            nameOfBank: '',
            accountNumber: '',
            nameOfAccountHolder: '',

            paySelected: true,
            payCard: '',
            agreement: false,

            purchased: false,
        };
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('access_token', ['access_token']),

        // 토큰 최소 개수 검사
        isValidNumberOfToken() {
            return Number(this.tokenToBuy) >= 3 ? true : false;
        },
        isCardRegistered() {
            return this.purchaseWay === '카드' && this.card_registered ? true : false;
        },
        afterBuyToken() {
            return Number(this.myToken) + Number(this.tokenToBuy);
        },
        totalPrice() {
            return this.numberFormat(this.tokenToBuy * PRICE_PER_TOKEN);
        },
    },
    //computed
    watch: {
        tokenToBuy() {
            if (this.tokenToBuy === '') {
                this.tokenToBuy = '0';
            } else {
                const newToken = this.tokenToBuy.replace(/[^0-9]/g, '');

                newToken.length > 1 && newToken[0] === '0'
                    ? (this.tokenToBuy = newToken.substring(1, newToken.length))
                    : (this.tokenToBuy = newToken);
            }
        },
    },
    // life cyles
    created() {
        this.fetchBasicData();
        this.fetchMypage();
        this.fetchTokenData();
        this.fetchCardData();
    },
    mounted() {
        document.querySelector('#token_submenu').classList.add('router-link-active');
    },
    beforeUnmount() {
        document.querySelector('#token_submenu').classList.remove('router-link-active');
    },
    //methods
    methods: {
        fetchBasicData() {
            this.fetchData(
                '/getBasic?page=mypagebuytoken',
                (data) => {
                    this.nameOfBank = data.bank;
                    this.accountNumber = data.accnum;
                    this.nameOfAccountHolder = data.accholder;
                },
                {},
            );
        },
        fetchMypage() {
            this.fetchData(
                '/getMypage',
                (data) => {
                    this.myToken = data.data.token;
                },
            );
        },
        fetchTokenData() {
            this.fetchData(
                '/getToken',
                // (data) => {
                //     console.log('getToken', data);
                // },
            );
        },
        fetchCardData() {
            this.fetchData(
                '/getCard',
                (data) => {
                    this.myCardList = data.list;
                    if (data.list.length === 0) {
                        this.card_registered = false;
                    }
                },
            );
        },
        countUpToken(count) {
            this.tokenToBuy = String(Number(this.tokenToBuy) + count);
        },
        countDownToken(count) {
            this.tokenToBuy = String(Number(this.tokenToBuy) - count);
        },
        inNumber(event) {
            this.tokenToBuy = event.target.value;
        },
        changePaymentClicked(method) {
            if (method === '카드') {
                this.paySelected = false;
                document.querySelector('.pay_wrap .pay_type#pay_card').classList.add('on');
                document.querySelector('.pay_wrap .pay_type#pay_bank').classList.remove('on');
            } else {
                this.paySelected = true;
                document.querySelector('.pay_wrap .pay_type#pay_card').classList.remove('on');
                document.querySelector('.pay_wrap .pay_type#pay_bank').classList.add('on');
            }
        },
        changePurchaseWay(method) {
            if (method === '카드') {
                this.$store.commit('alert', '카드 결제는 아직 제공되지 않습니다. 불편을 드려 죄송합니다.');
            } else {
                this.purchaseWay = method;
                this.changePaymentClicked(method);
            }
        },
        buyToken() {
            if (this.isValidNumberOfToken && this.agreement && this.paySelected) {
                this.$store.commit('confirm', {
                    content: `토큰 ${this.tokenToBuy}개를 구매 하시겠습니까?`,
                    callback: () => {
                        const formData = new FormData();
                        // formData.append('access_token', this.access_token);
                        formData.append('meta_token', this.tokenToBuy);
                        formData.append('purchase_way', this.purchaseWay);

                        this.postData('/saveToken', formData, () => {
                            if (this.purchaseWay === '무통장') {
                                this.$store.commit('alert', '토큰 구매가 완료되었습니다. (무통장 입금)');
                                this.purchased = true;
                            } else {
                                this.$store.commit('alert', '토큰 구매가 완료되었습니다. (카드)');
                                this.$router.push('/mypage/myToken');
                            }
                        });
                    },
                });
            } else {
                if (!this.isValidNumberOfToken) {
                    this.$store.commit('alert', '토큰의 최소 구매수량은 3개 입니다.');
                } else if (!this.agreement) {
                    this.$store.commit('alert', '토큰 구매를 위해서 전자금융거래 이용약관에 동의하셔야 합니다.');
                } else if (!this.paySelected) {
                    this.$store.commit('alert', '결제 수단을 선택해주세요.');
                }
            }
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_pay.css"></style>
