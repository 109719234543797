<template>
    <div>
        <div class="screen_filter"></div>
        <!-- 상품 팝업 시작 -->
        <div class="popup popup_ad">
            <button type="button" @click="closePopup(false)">
                <img src="@/assets/images/common/icon_close_w.svg" alt="close" />
            </button>

            <!--샘플구매 (공개) 시작-->
            <div class="inner">
                <div class="product_title">
                    <div class="account_container">
                        <p>구매방법 : {{popupData.purchase_way}}</p>
                        <p>{{popupData.purchase_way=='토큰' ? '개수' : '가격'}} : {{ popupData.purchase_way=='토큰' ? numberFormat(popupData.token) : numberFormat(popupData.price)}}</p>
                    </div>
                    <div class="btn_wrap">
                        <button @click="cancelAd(popupData.ad_pk)" :disabled="popupData.state != '대기'" class="common_button" >
                            <div class="icon_wrap">
                                <img src="@/assets/images/common/icon_close_w.svg" alt="close" >
                            </div>
                            <p>{{popupData.state == '승인' ? '승인완료' : popupData.state == '취소' ? '취소완료' : '신청취소' }}</p>
                        </button>
                    </div>
                </div>

                <div class="product">
                    <div class="scale_wrap">
                        <!-- <div class="scale">
                            <p>입찰토큰</p>
                            <h1>{{ numberFormat(popupData.request_token) }}<span>개</span></h1>
                        </div> -->
                        <div class="scale">
                            <p>광고위치</p>
                            <h1>{{ this.popupData.division }}</h1>
                        </div>
                        <div class="scale">
                            <p>시작일</p>
                            <h1>{{ this.dateFormat(popupData.start_date) }}</h1>
                        </div>
                        <div class="scale">
                            <p>마감일</p>
                            <h1>{{ this.dateFormat(popupData.finish_date) }}</h1>
                        </div>
                    </div>
                    <div class="ask">
                        <img v-if="popupData.path" :src="popupData.path+popupData.save_name" :alt="popupData.original_name"/>
                        <img v-else class="default" src="@/assets/images/common/no_image.svg" alt="no_image">
                        <p>{{popupData.content}}</p>
                    </div>
                </div>
            </div>
            <!--샘플구매 (공개) 끝-->
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import { postData } from "@/utils/postData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";

export default {
    mixins: [postData, numberFormat,dateFormat],
    props: {
        popupData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapState("user", ["user"]),
    },
    methods: {
        closePopup(refresh=false){
            this.$emit('closePopup', refresh);
        },
        cancelAd(ad_pk){
            if(this.popupData.state == '대기'){
                this.postData('/saveAd', {
                    ad_pk : ad_pk,
                }, ()=>{
                    this.$store.commit('alert', '광고 신청이 취소되었습니다.');
                    this.closePopup(true);
                })
            }
            
        }
    }
};
</script>
<style scoped src="@/assets/css/components/commonPopup_v2.css"></style>
