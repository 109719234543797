`<template>
    <div class="right_con ad_register">
        <div v-if="this.user.user_division == '공급자'" class="product_select">
            <h2>상품선택 <strong>*</strong> <span>(매칭중인 상품만 가능합니다)</span></h2>
            <div class="product_wrapper">
                <div
                    v-for="(item) in this.list" :key="item.product_pk" 
                    @click="productSelect($event, item)"
                    class="product">
                    <img v-if="item.path" :src="item.path+item.save_name" />
                    <img v-else src="@/assets/images/common/no_image.svg" alt="no image">
                    <div>
                        <img src="@/assets/images/common/icon_check_w.svg" alt="check" />
                    </div>
                    <p>{{item.name}}</p>
                </div>
                <div v-if="this.list.length == 0">선택할 상품이 없습니다.</div>
            </div>
        </div>

        <div class="fill" v-if="this.list.length != 0 || this.user.user_division == '판매자'">
            <h2>광고 정보 입력</h2>
            <div class="left">
                <div class="fill_select">
                    <h3>광고 영역 선택 <strong>*</strong></h3>
                    <select v-model="division">
                        <option value="상단">상단</option>
                        <option value="중앙">중앙</option>
                        <option value="회원가입">회원가입</option>
                    </select>
                </div>

                <div class="fill_explain">
                    <h3>광고 희망 내용 <strong>*</strong></h3>
                    <textarea v-model="content"></textarea>
                </div>

                <div class="fill_image">
                    <template v-if="this.division=='상단'">
                        <h3>배경 희망 이미지 <span>(최대 2.5MB)</span></h3>
                        <div class="product_image">
                            <div class="image_wrapper">
                                <img
                                    v-if="!this.top_imageThumb"
                                    src="@/assets/images/register/icon_image_add.svg"
                                    class="add_image"
                                    alt="더하기"
                                    @click="$refs.topImageInput.click()"
                                />
                                <img v-if="this.top_imageThumb" :src="top_imageThumb" alt="top_imageThumb" />
                                <span v-if="this.top_imageThumb" @click="deleteImage('top')">
                                    <img src="@/assets/images/register/icon_image_remove.svg" alt="빼기" />
                                </span>
                            </div>
                            <p v-if="top_image" @click="deleteImage('top')">
                                <span>{{ top_image.name }}</span>
                                <span>{{ Math.round(top_image.size / 1024 *10)/10 }}KB</span>
                            </p>
                            <p v-else class="default" @click="$refs.topImageInput.click()"><span>이미지를 선택해 주세요</span></p>
                        </div>
                        <input
                            type="file"
                            style="display: none"
                            @change="onImageSelected($event, 'top')"
                            accept="image/*"
                            capture="camera"
                            ref="topImageInput"
                            @click="this.$refs.topImageInput.value = ''"
                        />
                    </template>
                    <h3>광고 희망 이미지 <span>(최대 2.5MB)</span></h3>
                    <div class="product_image">
                        <div class="image_wrapper">
                            <img
                                v-if="!this.imageThumb"
                                src="@/assets/images/register/icon_image_add.svg"
                                class="add_image"
                                alt="더하기"
                                @click="$refs.imageInput.click()"
                            />
                            <img v-if="this.imageThumb" :src="imageThumb" alt="imageThumb" />
                            <span v-if="this.imageThumb" @click="deleteImage('main')">
                                <img src="@/assets/images/register/icon_image_remove.svg" alt="빼기" />
                            </span>
                        </div>
                        <p v-if="image" @click="deleteImage('main')">
                            <span>{{ image.name }}</span>
                            <span>{{ Math.round(image.size / 1024 *10)/10 }}KB</span>
                        </p>
                        <p v-else class="default" @click="$refs.imageInput.click()"><span>이미지를 선택해 주세요</span></p>
                    </div>
                    <input
                        type="file"
                        style="display: none"
                        @change="onImageSelected($event, 'main')"
                        accept="image/*"
                        capture="camera"
                        ref="imageInput"
                        @click="this.$refs.imageInput.value = ''"
                    />
                    <p class="description">* 이미지를 등록하지 않으면 관리자가 임의로 이미지를 선택합니다.</p>
                </div>
            </div>

            <div class="line"></div>

            <div class="right">
                <!-- <div class="purchase_way">
                    <label>
                        <input type="radio" v-model="purchase_way" value="토큰" />
                        토큰
                    </label>
                    <label>
                        <input type="radio" v-model="purchase_way" value="무통장" />
                        무통장입금
                    </label>
                </div> -->
                <div class="fill_date">
                    <div class="date">
                        <h3>시작일</h3>
                        <input
                            id="start_date1"
                            type="date"
                            data-placeholder="YYYY-MM-DD"
                            required
                            area-required="true"
                            v-model="start_date"
                            :min="this.min_start_date"
                            max="3000-12-31"
                        />
                    </div>
                    <div class="date">
                        <h3>마감일</h3>
                        <input
                            id="finish_date"
                            type="date"
                            data-placeholder="YYYY-MM-DD"
                            required
                            area-required="true"
                            v-model="finish_date"
                            :min="this.min_start_date"
                            max="3000-12-31"
                        />
                    </div>
                    <p class="description">* 광고 승인까지 시간이 걸리니 감안하여 설정해주시기 바랍니다.</p>
                    <p class="basicToken">1일당 광고비용 : 토큰 {{this.basicToken}}개</p>
                </div>
                
                <div class="fill_number">
                    <div v-if="purchase_way=='무통장'" class="scale" >
                        <h3>광고 가격(원)</h3>
                        <div class="scale_token">
                            <input type="text" v-model="price" maxlength="15" />
                        </div>
                    </div>
                    <template v-else>
                        <div class="scale">
                            <!-- <h3>광고 가격(토큰)</h3> -->
                            <h3>사용 토큰</h3>
                            <div class="token_number">
                                <span>{{ meta_token }}</span>
                                <p>개</p>
                            </div>
                            <!-- <div class="scale_token price">
                                <button class="btn_op" @click="changeMetaToken('-', $event)">-</button>
                                <input type="text" :value="meta_token" @input="changeMetaToken('=', $event)" />
                                <button class="btn_op" @click="changeMetaToken('+', $event)">+</button>
                            </div> -->
                        </div>

                        <div class="scale scale_ticket">
                            <h3>보유 토큰</h3>
                            <div class="token_number">
                                <span>{{ numberFormat(user.meta_token) }}</span>
                                <p>개</p>
                                <button class="sub_button" @click="$router.push('/mypage/MypageBuyToken')">
                                    토큰 충전
                                </button>
                            </div>
                           
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="last_btn_wrap">
            <button id="btn_cancel-sample" class="common_button" @click="saveAd">
                <p>광고 신청</p>
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { fetchData } from "@/utils/fetchData";
import { postData } from "@/utils/postData";
import { numberFormat } from "@/utils/numberFormat";
import { changeMetaToken } from "@/utils/changeMetaToken";
import { dateFormat } from "@/utils/dateFormat";

export default {
    mixins: [ fetchData, postData, numberFormat, changeMetaToken, dateFormat],
    watch: {
        price(num){
            this.price = this.numberFormat(num);
        },
        start_date(){
            if(this.meta_token <= 0){
                this.$store.commit('alert', '마감일은 시작일보다 커야 합니다.');
                this.finish_date = this.start_date;
            }
        },
        finish_date(){
            if(this.meta_token <= 0){
                this.$store.commit('alert', '마감일은 시작일보다 커야 합니다.');
                this.finish_date = this.start_date;
            }
        }
    },
    computed: {
        ...mapState("user", ["user"]),
        // 사용 토큰 계산
        meta_token(){
            function toDateFormat(str=''){  // yyyy-mm-dd 형식을 Date 형식으로 변환
                return new Date(str.slice(0,4), str.slice(5,7), str.slice(8,10));
            }
            if(this.start_date && this.finish_date){
                const start = toDateFormat(this.start_date);
                const finish = toDateFormat(this.finish_date);
                return Math.floor((finish-start) / (1000*60*60*24) + 1) *this.basicToken;
            }
            return 1;
        },
    },
    data() {
        return {
            basicToken: 0,
            list: [],
            product_pk : null,
            title: '',
            division: '상단',
            purchase_way: "토큰",
            content: "",
            price: 0,
            start_date: '',
            finish_date: '',
            image: null,
            top_image: null,
            imageThumb: null,
            top_imageThumb: null,

            min_start_date: ''
        };
    },
    created() {
        this.fetchListData();
        this.dateSet();
    },
    methods: {
        fetchListData() {
            // 최소사용토큰 가져오기
            this.fetchData(
                "/getAdBasicToken",
                (data) => {
                    this.basicToken = data.token;

                    // 공급자는 자신의 상품도 가져오기
                    if(this.user.user_division == '공급자'){
                        this.fetchData(
                            "/getMyProduct",
                            (data) => {
                                this.list = data.list;
                                this.list = this.list.filter(el=>el.state != '마감');
                                this.list.sort((a, b) => new Date(b.finish_date) - new Date(a.finish_date));
                            },
                        );
                    }
                },
            );
        },
        // 처음에 시작일, 마감일 세팅
        dateSet(){
            const date = new Date();
            this.min_start_date = `${date.getFullYear()}-${date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1): date.getMonth()+1}-${ date.getDate() < 10 ? '0'+date.getDate() : date.getDate()}`;
            this.start_date = this.min_start_date;
            this.finish_date = this.min_start_date;
        },
        productSelect(event, item){
            const curEl = event.currentTarget.classList;
            if(curEl.contains('on')){
                this.product_pk = null;
                this.title = '';
                curEl.remove('on')
            } else {
                this.product_pk = item.product_pk;
                this.title = item.name;
                const products = document.querySelectorAll('.product_wrapper .product');
                for(let i=0;i<products.length; i++){
                    products[i].classList.remove('on');
                }
                curEl.add('on')
            }
        },
        // 상품 이미지 추가
        onImageSelected(event, which) {
            // 파일이 존재하는지
            if (event.target.files && event.target.files[0]) {
                // 2MB보다 크면 안받도록
                if (event.target.files[0].size > 1048576 * 2.5) {
                    this.$store.commit("alert", "2.5MB 이하 이미지를 선택해 주세요");
                }
                // 이미지일 때만 this.image에 넣음
                else if (
                    !event.target.files[0].type.includes("image") ||
                    event.target.files[0].type.includes("gif")
                ) {
                    this.$store.commit("alert", "이미지만 선택해주세요. (.gif 제외)");
                } else {
                    if(which == 'top') { this.top_image = event.target.files[0] }
                    else { this.image = event.target.files[0] }

                    const reader = new FileReader();
                    reader.onload = (event) => {
                        if(which == 'top') { this.top_imageThumb = event.target.result }
                        else { this.imageThumb = event.target.result }
                        
                    };
                    reader.readAsDataURL(event.target.files[0]);
                }
            }
        },
        // 상품 이미지 삭제
        deleteImage(which) {
            if(which=='top'){
                this.top_image = null;
                this.top_imageThumb = null;
            } else {
                this.image = null;
                this.imageThumb = null;
            }
        },
        saveAd(){
            if(this.user.user_division == '공급자' && !this.product_pk){
                this.$store.commit('alert', '광고로 노출할 상품을 선택해 주세요');
            } else if(!this.content){
                this.$store.commit('alert', '광고내용을 입력해주세요');
            } else if(!this.start_date){
                this.$store.commit('alert', '광고 시작일을 입력해주세요');
            } else if(!this.finish_date){
                this.$store.commit('alert', '광고 마감일을 입력해주세요');
            } else {
                const formData = new FormData();
                formData.append('division', this.division);
                formData.append('purchase_way', this.purchase_way);
                formData.append('content', this.content);
                formData.append('price', this.price ? this.price.replaceAll(',', '') : 0);
                formData.append('meta_token', this.meta_token);
                formData.append('title', this.title ?? this.user.name);
                formData.append('start_date', this.start_date);
                formData.append('finish_date', this.finish_date);
                if(this.division == '상단'){
                    formData.append('top_image', this.top_image);
                }
                formData.append('image', this.image);
                if(this.user.user_division == '공급자'){
                    formData.append('product_pk', this.product_pk);
                    formData.append('ad_division', 'P');
                } else{
                    formData.append('ad_division', 'S');
                }
                this.postData('/saveAd', formData, ()=>{
                    this.$store.commit('alert', '광고등록이 완료되었습니다.');
                    // if(this.purchase_way == '토큰'){
                    //     this.$store.commit('user/metaTokenUse', this.meta_token);
                    // }
                    this.$router.push('/mypage/AdDetail');
                })
            }
        }
    },

    
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_ad.css"></style>
