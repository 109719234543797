<template>
    <div class="right_con">
        <div class="common_table p_request">
            <ul class="top">
                <li class="w150">판매자</li>
                <li class="product">상품명</li>
                <li class="w140">공급가</li>
                <li class="w140">입찰토큰</li>
                <li class="w80">신청상태</li>
                <li class="w120">등록일</li>
            </ul>
            <ul v-for="(item, i) in sliceList" :key="i" class="content">
                <li class="w150 name">
                    <p>
                        {{ item.seller_name }}
                    </p>
                </li>
                <li class="product">
                    <p>{{ item.product_name }}</p>
                </li>
                <li class="w140 sub">
                    <p>
                        {{ this.numberFormat(item.supply_price) }}원
                    </p>
                </li>
                <li class="w140 sub">
                    <p>
                        {{ this.numberFormat(item.token) }}개
                    </p>
                </li>
                <li class="w80 sub">
                    <p>
                        {{ item.state }}
                    </p>
                </li>
                <li class="w120 sub3">
                    <p>
                        {{ item.enrollment_date ? this.dateFormat(item.enrollment_date) : '-' }}
                    </p>
                </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>제안받은 내역이 없습니다.</li>
            </ul>
            <BoardPagination
                :listLength="this.list.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
        </div>
    </div>

</template>

<script>
import BoardPagination from '@/components/BoardPagination'

import { mapState } from 'vuex';
import { fetchData } from '@/utils/fetchData';
import { numberFormat } from '@/utils/numberFormat';
import { dateFormat } from '@/utils/dateFormat';
import { postData } from '@/utils/postData';

export default {
    mixins: [fetchData, numberFormat, dateFormat, postData],
    components : {
        BoardPagination
    },
    data() {
        return {
            showPopup: false,
            list: [],
            margin: ['10~20', '20~30', '30~40', '40~50'],

            currentPage : 1,    // 현재 페이지
            maxShowList : 10,   // 한 페이지 보일 최대 리스트수
            maxShowPage : 5,    // 한 화면에 보일 최대 페이지수
        };
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('access_token', ['access_token']),
        sliceList(){
            return this.list.length == 0 ? [] : this.list.slice( (this.currentPage-1)*this.maxShowList, this.currentPage*this.maxShowList );
        },
    },
    created() {
        this.fetchListData();
    },
    methods: {
        fetchListData() {
            this.fetchData(
                '/getSupplySeller',
                (data) => {
                    this.list = data.list.sort((a,b)=>{
                        const a_date = new Date(a.enrollment_date);
                        const b_date = new Date(b.enrollment_date);
                        return a_date > b_date ? -1 : a_date < b_date ? 1 : 0;
                    });
                },
            );
        },
        acceptMatching() {
            const formData = new FormData();
            // formData.append('access_token', this.access_token);
            formData.append('request_pk', this.popupData.request_pk);
            this.postData('/saveRequest', formData, (data) => {
                if (data.success) {
                    this.$store.commit('alert', '거래가 체결되었습니다.');
                    this.fetchListData();
                    this.closePopup();
                }
            });
        },
        // 페이지 변경
        setPage(num){
            this.currentPage = num;
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_matching.css"></style>
<style scoped src="@/assets/css/components/commonPopup_v2.css"></style>
