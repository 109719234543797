<template>
    <div class="right_con">
        <div class="common_table s_manage">
            <ul class="top">
                <li class="product">희망상품명</li>
                <li class="w240">카테고리</li>
                <li class="w80">상태</li>
                <li class="w100">마감일</li>
                <li class="w100">등록일</li>
                <li class="w60">관리</li>
            </ul>
            <ul v-for="(item, i) in this.sliceList(this.list)" :key="i" class="content">
                <li class="product">
                    <p>{{ item.name }}</p>
                </li>
                <li class="w240 sub"><p>{{ item.category }}</p></li>
                <li class="w80 sub2"><p>{{ item.state }}</p></li>
                <li class="w100 sub3" v-if="item.finish_date">
                    <p>
                        {{ this.dateFormat(item.finish_date) }}
                    </p>
                </li>
                <li class="w100 sub3" v-else><p>-</p></li>
                <li class="w100 sub3">
                    <p>
                        {{ this.dateFormat(item.enrollment_date) }}
                    </p>
                </li>
                <li class="w60 icon" @click="openPopup(item)">
                    <img src="@/assets/images/mypage/icon_manage.svg" alt="관리" />
                </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>등록한 매칭이 없습니다.</li>
            </ul>
                <div class="button_wrapper">
                <button
                    class="sub_button addNew"
                    @click="()=>{this.$router.push('/register/seller')}"
                >
                    새 매칭 등록
                </button>
            </div>
            <BoardPagination
                :listLength="this.list.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
        </div>
    </div>
</template>

<script>
import BoardPagination from "@/components/BoardPagination";
import { fetchData } from "@/utils/fetchData";
import { postData } from "@/utils/postData";
import { mapState } from "vuex";
import { dateFormat } from "@/utils/dateFormat";
import { numberFormat } from "@/utils/numberFormat";

export default {
    mixins: [fetchData, dateFormat, numberFormat, postData],
    components: {
        BoardPagination,
    },
    data() {
        return {
            list: [],
            popupData: {
                list: [],
                request_count: 0,
                name: "",
            },
            showPopup: false,

            currentPage: 1, // 현재 페이지
            maxShowList: 10, // 한 페이지 보일 최대 리스트수
            maxShowPage: 5, // 한 화면에 보일 최대 페이지수
        };
    },
    computed: {
        ...mapState("user", ["user"]),
    },
    mounted() {
        this.fetchMatchingData();
    },
    methods: {
        // 매칭 데이터 불러오기
        fetchMatchingData() {
            this.fetchData(
                "/getMyMatching",
                (data) => {
                    this.list = data.list;
                },
            );
        },
        openPopup(item) {
            // TODO : get data through api
            this.popupData = {
                matching_pk: item.matching_pk,
                state: item.state === "매칭중" ? true : false,
                finish_date: item.finish_date ? item.finish_date.slice(0, 10) : "-",
                original_finish_date: item.finish_date ? item.finish_date.slice(0, 10) : "-",
                name: item.name,
                request_count: 0,
            };
            this.$store.commit("setUpdate", true);
            this.$store.dispatch("UPDATE_MATCHING", {
                pk: item.matching_pk,
                state: item.state === "매칭중" ? true : false,
            });
            // this.showPopup = true;
        },
        closePopup() {
            this.showPopup = false;
            this.popupData = {};
        },
        // 페이지 변경
        setPage(num) {
            this.currentPage = num;
        },
        sliceList(arr) {
            return arr.length == 0
                ? []
                : arr.slice((this.currentPage - 1) * this.maxShowList, this.currentPage * this.maxShowList);
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_matching.css"></style>
<style scoped src="@/assets/css/components/commonPopup.css"></style>
<style scoped>
.content_title {
    box-sizing: border-box;
    background-color: #fff;
    font-size: 2rem;
}
.content_date {
    padding: 1rem 0rem;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    box-sizing: border-box;
    border: 1px solid var(--base-color1);
    border-radius: var(--border-radius);
    font-family: "Noto Sans KR", sans-serif;
    font-size: var(--font-small);
    font-weight: var(--font-w-e-light);
    color: var(--primary-color1);
    cursor: pointer;
    background-color: #fff;
}
.popup {
    width: 800px;
}
.popup .inner .product {
    justify-content: center;
}
.popup .inner .product .list_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    margin-left: 100px;
}
.list_wrap .toggle_wrap .switch_toggle {
    display: flex;
    width: 150px;
    padding: 1rem 0rem;
}
.list_wrap .toggle_wrap .switch_toggle p {
    margin-left: 1rem;
    font-size: var(--font-medium);
}
</style>
