<template>
  <div>
    <div class="screen_filter"></div>
    <!-- 상품 팝업 시작 -->
    <div class="popup popup_sample">
      <button type="button" @click="closeSamplePopup">
        <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
      </button>

      <!--샘플구매 (공개) 시작-->
      <div class="inner">
            <div class="product_title">
                <div class="user user_provider">
                    <span>공급자</span>
                    <p>{{ sampleData.provider_name }}</p>
                </div>
                <h1>{{ sampleData.product_name }}</h1>
            </div>

            <div class="product">
                <div class="ask">
                    <div class="ask_icon">
                        <img src="@/assets/images/common/icon_token_w.svg" alt="token" />
                    </div>
                    <h1>샘플 구매 관리</h1>
                    <p>샘플 구매 요청 내역입니다.</p>
                </div>

                <div class="scale_wrap">
                    <div class="scale">
                        <p>요청한 샘플</p>
                        <h1>
                            {{ this.numberFormat(sampleData.quantity) }}<span>개</span>
                        </h1>
                    </div>
                    <div class="scale">
                        <p>샘플 가격(원)</p>
                        <h1>{{ this.numberFormat(sampleData.price) }}<span>원</span></h1>
                    </div>
                    <div class="scale">
                        <p>샘플 가격(토큰)</p>
                        <h1>{{ this.numberFormat(sampleData.token) }}<span>개</span></h1>
                    </div>
                </div>
            </div>

            <div class="last_btn_wrap">
                <button
                    id="btn_cancel-sample"
                    type="button"
                    class="common_button"
                    @click="cancelSample"
                    disabled
                >
                    <p>구매 취소</p>
                </button>
                <button type="button" class="sub_button" @click="closeSamplePopup">
                    <p>닫기</p>
                </button>
            </div>
      </div>
      <!--샘플구매 (공개) 끝-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { postData } from "@/utils/postData";
import { numberFormat } from "@/utils/numberFormat";

export default {
  mixins: [postData, numberFormat],
  props: {
    sampleData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("access_token", ["access_token"]),
  },
  mounted() {
    const btnEl = document.querySelector("#btn_cancel-sample");
    if (this.sampleData.state === "대기") {
      btnEl.disabled = false;
    }
  },
  methods: {
    // 팝업 닫기
    closeSamplePopup() {
      this.$emit("close-popup");
    },
    deleteSample() {
      const formData = new FormData();
      formData.append("access_token", this.access_token);
      formData.append("sample_pk", this.sampleData.sample_pk);

      this.postData("/deleteSample", formData, () => {
        this.$emit("delete-sample");
      });
    },
    //샘플 구매 취소
    cancelSample() {
      if (this.sampleData.state === "대기") {
        this.$store.commit("confirm", {
          content: "샘플 구매를 취소하시겠습니까?",
          callback: () => {
            this.deleteSample();
          },
        });
      }
    },
  },
};
</script>
<style scoped src="@/assets/css/components/commonPopup_v2.css"></style>