<template>
  <div class="right_con">
    <div class="toggle_wrap">
        <div class="toggle" :style="toggleStyle"></div>
            <ul class="main_menu">
                <li :class="this.whichTab === 0 ? 'on' : ''" @click="clickTab(0)">상품 제안</li>
                <li :class="this.whichTab === 0 ? '' : 'on'" @click="clickTab(1)">매칭 제안</li>
            </ul>
        </div>
        <div class="common_table s_complete_01" v-if="this.whichTab == 0">
            <ul class="top">
                <li class="w80">구분</li>
                <li class="w120">판매자</li>
                <li class="product">상품명</li>
                <li class="w120">공급가</li>
                <li class="w70">입찰토큰</li>
                <li class="w110">등록일</li>
                <li class="w110">완료일</li>
                <li class="w60">보기</li>
            </ul>
            <ul class="content" v-for="(item, i) in this.sliceList" :key="i">
                <li class="w80 sub" v-if="this.whichTab == 0">
                    <p>
                        {{ item.division }}
                    </p>
                </li>
                <li class="w120 name_s sub"><p>{{ item.seller_name }}</p></li>
                <li class="product">
                    <p>{{ this.whichTab === 0 ? item.product_name : item.matching_name }}</p>
                </li>
                <li class="w120 sub">
                    <p>
                        {{ this.whichTab === 0 ? this.numberFormat(item.supply_price)+'원' : item.price }}
                    </p>
                </li>
                <li class="w70 sub3">
                    <p>
                        {{ this.numberFormat(item.request_token) }}개
                    </p>        
                </li>
                <li class="w110 sub4">
                    <p>
                        {{ this.dateFormat(item.request_date) }}
                    </p>
                </li>
                <li class="w110 sub4">
                    <p>
                        {{ item.complete_date ? this.dateFormat(item.complete_date) : '-' }}
                    </p>
                </li>
                <li class="w60 icon" @click="openPopup(item)">
                    <img src="@/assets/images/common/search.svg" alt="돋보기" />
                </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>완료된 내역이 없습니다.</li>
            </ul>
        
            <BoardPagination
                :listLength="this.list.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
        </div>

        <div class="common_table s_complete_02" v-else>
            <ul class="top">
                <!-- <li class="w80">구분</li> -->
                <li class="w120">판매자</li>
                <li class="product">상품명</li>
                <li class="w120">상품단가</li>
                <li class="w70">입찰토큰</li>
                <li class="w110">등록일</li>
                <li class="w110">완료일</li>
                <li class="w60">보기</li>
            </ul>
            <ul class="content" v-for="(item, i) in this.sliceList" :key="i">
                <!-- <li class="w80" v-if="this.whichTab == 0">
                    <p>
                        {{ item.division }}
                    </p>
                </li> -->
                <li class="w120 sub"><p>{{ item.seller_name }}</p></li>
                <li class="product">
                    <p>{{ this.whichTab === 0 ? item.product_name : item.matching_name }}</p>
                </li>
                <li class="w120 name_s sub">
                    <p>
                        {{ this.whichTab === 0 ? this.numberFormat(item.supply_price)+'원' : item.price }}
                    </p>
                </li>
                <li class="w70 sub3">
                    <p>
                        {{ this.numberFormat(item.request_token) }}개
                    </p>        
                </li>
                <li class="w110 sub4">
                    <p>
                        {{ this.dateFormat(item.request_date) }}
                    </p>
                </li>
                <li class="w110 sub4">
                    <p>
                        {{ item.complete_date ? this.dateFormat(item.complete_date) : '-' }}
                    </p>
                </li>
                <li class="w60 icon" @click="openPopup(item)">
                    <img src="@/assets/images/common/search.svg" alt="돋보기" />
                </li>
            </ul>
            <ul class="default" v-if="this.list.length == 0">
                <li>완료된 내역이 없습니다.</li>
            </ul>
        
            <BoardPagination
                :listLength="this.list.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
        </div>
    </div>

  <!--팝업 시작-->
  <div class="screen_filter" v-if="this.showPopup"></div>
  <div class="popup popup_seller popup_seller_com" v-if="this.showPopup">
    <button type="button" @click="closePopup">
      <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
    </button>
    <div class="inner">
        <!--상품명, 버튼 시작-->
        <div class="product_title">
            <div class="user user_seller">
                <span>판매자</span>
                <p>{{ popupData.seller_name }}</p>
            </div>

            <div class="btn_wrap">
                <router-link :to="`/seller/detail/` + popupData.seller_pk">
                    <button type="button" class="sub_button" style="margin: 0 1rem">
                    <div class="icon_wrap">
                        <img src="@/assets/images/common/search.svg" alt="돋보기" />
                    </div>
                    <p>판매자 보기</p>
                    </button>
                </router-link>
            </div>

            <h1>{{ this.whichTab == 0 ? popupData.product_name : popupData.matching_name }}</h1>
        </div>
        <!--상품명, 버튼 끝-->

      <!--내용 시작-->
      <div class="product">
        <div class="ask">
          <div class="ask_icon">
            <img
              src="@/assets/images/common/icon_matching_w.svg"
              alt="matching"
            />
          </div>
          <h1>거래 체결</h1>
          <p>
            <span>{{ popupData.seller_name }}</span> 님과 매칭에
            성공하였습니다.<br />
            거래처 정보를 확인하시고 거래를 진행해주세요.
          </p>
        </div>

        <!-- <span class="line"></span> -->

        <div class="scale_wrap">
          <div class="scale">
            <p>최소구매수량</p>
            <h1>{{ popupData.product_moq ? popupData.product_moq : popupData.moq }}</h1>
          </div>
          <div class="scale">
            <p>공급가</p>
            <h1>{{ popupData.supply_price ? `${numberFormat(popupData.supply_price)}원` : popupData.price }}</h1>
          </div>
          <div class="scale">
            <p>입찰토큰</p>
            <h1>{{ numberFormat(popupData.request_token) }}<span>개</span></h1>
          </div>
          <div class="scale">
            <p>완료일</p>
            <h1>
              {{
                this.dateFormat(popupData.complete_date)
              }}
            </h1>
          </div>
        </div>

        <div class="info_wrap contact_wrap">
          <div class="info">
            <p>이메일</p>
            <h1 :title="popupData.seller_email">
              {{ popupData.seller_email }}
            </h1>
          </div>
          <div class="info">
            <p>연락처</p>
            <h1>{{ popupData.seller_contact }}</h1>
          </div>
          <div class="info">
            <p>홈페이지</p>
            <h1
              class="info_url"
              v-if="popupData.seller_url"
              :title="popupData.seller_url"
            >
              {{ popupData.seller_url }}
            </h1>
            <h1 v-else>없음</h1>
          </div>
        </div>
      </div>
      <!--내용 끝-->
    </div>
  </div>
  <!--팝업 끝-->
</template>

<script>
import BoardPagination from '@/components/BoardPagination'
import { mapState } from "vuex";
import { fetchData } from "@/utils/fetchData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";

export default {
  mixins: [fetchData, numberFormat, dateFormat],
  components : {
    BoardPagination,
  },
  data() {
    return {
      showPopup: false,
      list: [],
      matching_list: [],
      product_list: [],
      popupData: {},

      whichTab : 0,

      currentPage : 1,    // 현재 페이지
      maxShowList : 10,   // 한 페이지 보일 최대 리스트수
      maxShowPage : 5,    // 한 화면에 보일 최대 페이지수
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    sliceList(){
      return this.list.length == 0 ? [] : this.list.slice( (this.currentPage-1)*this.maxShowList, this.currentPage*this.maxShowList );
    },
    toggleStyle() {
      return this.whichTab === 0 ? { left: "0px" } : { left: "120px" };
    },
  },
  watch : {
    whichTab(){
      this.currentPage = 1;
    }
  },
  created() {
    this.fetchListData();
  },
  methods: {
    fetchListData() {
      this.fetchData(
        "/getCompleteSeller",
        (data) => {
          this.matching_list = data.matching_list.sort((a,b)=>{
            const a_date = new Date(a.complete_date);
            const b_date = new Date(b.complete_date);
            return a_date > b_date ? -1 : 1;
          });
          this.product_list = data.product_list;
          this.list = this.product_list;
        },
      );
    },
    clickTab(tab) {
      this.whichTab = tab;
      this.list = tab === 0 ? this.product_list : this.matching_list;
    },
    // 페이지 변경
    setPage(num){
      this.currentPage = num;
    },
    openPopup(item) {
      this.popupData = item.detail;
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_matching.css"></style>
<style scoped src="@/assets/css/components/commonPopup_v2.css"></style>
