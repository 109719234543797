<template>
    <div class="left_tab">
        <ul class="menu" id="scrollHorizontal">
            <!--마이페이지 홈 시작-->
            <li>
                <router-link to="/mypage" id="home" class="mainMenu">
                    <div class="icon_wrap">
                        <img src="@/assets/images/mypage/icon_tap_01.svg" alt="홈" />
                    </div>
                    <p>마이페이지 홈</p>
                </router-link>
            </li>
            <!--마이페이지 홈 끝-->

            <li class="line"></li>

            <!--회원정보관리 시작-->
            <li>
                <router-link to="/mypage/MypageUserInfo" id="userinfo" class="mainMenu">
                    <div class="icon_wrap">
                        <img src="@/assets/images/mypage/icon_tap_02.svg" alt="정보" />
                    </div>
                    <p>회원정보 관리</p>
                </router-link>
            </li>
            <!--회원정보관리 끝-->

            <li v-if="this.user.user_division == '판매자' || this.user.user_division == '공급자'" class="line"></li>

            <!--프로필관리 시작-->
            <li>
                <router-link
                    to="/mypage/MypagePartnerInfo"
                    id="profile"
                    class="mainMenu"
                    v-if="this.user.user_division"
                >
                    <div class="icon_wrap">
                        <img src="@/assets/images/mypage/icon_tap_03.svg" alt="프로필" />
                    </div>
                    <p>프로필 관리</p>
                </router-link>
            </li>
            <!--프로필관리 끝-->

            <li v-if="this.user.user_division == '판매자' || this.user.user_division == '공급자'" class="line"></li>

            <!--거래관리 시작-->
            <li>
                <router-link
                    :to="dealTab"
                    id="deal"
                    class="mainMenu"
                    :class="this.routerLinkActive"
                    v-if="this.user.user_division"
                >
                    <div class="icon_wrap">
                        <img src="@/assets/images/mypage/icon_tap_05.svg" alt="거래" />
                    </div>
                    <p>거래 관리</p>
                </router-link>
                <nav class="submenu" v-if="this.user.user_division == '판매자' && this.tab === 'deal'">
                    <router-link to="/mypage/ManageMatching">
                        매칭 관리
                    </router-link>
                    <router-link to="/mypage/SupplySeller">
                        공급가 요청
                    </router-link>
                    <router-link to="/mypage/RequestSeller">
                        제안한 거래
                    </router-link>
                    <router-link to="/mypage/ProposalSeller">
                        제안받은 거래
                    </router-link>
                    <router-link to="/mypage/CompleteSeller">
                        완료된 거래
                    </router-link>
                </nav>
                <nav class="submenu" v-else-if="this.user.user_division == '공급자' && this.tab === 'deal'">
                    <router-link to="/mypage/ManageProduct">
                        상품 관리
                    </router-link>
                    <router-link to="/mypage/SupplyProvider">
                        공급가 요청
                    </router-link>
                    <router-link to="/mypage/RequestProvider">
                        제안한 거래
                    </router-link>
                    <router-link to="/mypage/ProposalProvider">
                        제안받은 거래
                    </router-link>
                    <router-link to="/mypage/CompleteProvider">
                        완료된 거래
                    </router-link>
                </nav>
            </li>
            <!--거래관리 끝-->

            <li v-if="this.user.user_division == '판매자' || this.user.user_division == '공급자'" class="line"></li>

            <!--광고관리 시작-->
            <li v-if="this.user.user_division == '공급자' || this.user.user_division == '판매자'">
                <router-link id="ad" class="mainMenu" :class="adRouterLinkActive" to="/mypage/AdRegistration">
                    <div class="icon_wrap">
                        <img src="@/assets/images/mypage/icon_tap_06.svg" alt="광고" />
                    </div>
                    <p>광고 관리</p>
                </router-link>
                <nav class="submenu" v-if="this.tab == 'ad'">
                    <router-link to="/mypage/AdRegistration">
                        광고 신청
                    </router-link>
                    <router-link to="/mypage/AdDetail">
                        광고 현황
                    </router-link>
                </nav>
            </li>
            <!--광고관리 끝 (공급자만)-->

            <li v-if="this.user.user_division == '판매자' || this.user.user_division == '공급자'" class="line"></li>

            <!--샘플구매관리 시작 (판매자만)-->
            <li v-if="this.user.user_division == '판매자'">
                <router-link
                    to="/mypage/SampleSeller"
                    id="token"
                    class="mainMenu"
                    :class="sampleRouterLinkActive"
                    v-if="this.user.user_division"
                >
                    <div class="icon_wrap">
                        <img src="@/assets/images/mypage/icon_tap_08.svg" alt="광고" />
                    </div>
                    <p>샘플 관리</p>
                </router-link>
            </li>
            <!--샘플구매관리 끝 (판매자만)-->

            <li v-if="this.user.user_division == '판매자'" class="line"></li>
            <li v-if="this.user.user_division == '공급자'" class="line"></li>

            <!--결제관리 시작-->
            <li>
                <router-link
                    to="/mypage/myToken"
                    id="token"
                    class="mainMenu"
                    :class="payRouterLinkActive"
                    v-if="this.user.user_division"
                >
                    <div class="icon_wrap">
                        <img src="@/assets/images/mypage/icon_tap_07.svg" alt="결제" />
                    </div>
                    <p>결제 관리</p>
                </router-link>
                <ul class="submenu" v-if="this.tab === 'token'">
                    <router-link to="/mypage/myToken" id="token_submenu">
                        My 토큰
                    </router-link>
                    <router-link to="/mypage/cardManage">
                        카드 관리
                    </router-link>
                    <router-link to="/mypage/accountManage" v-if="this.user.user_division == '공급자'">
                        계좌 관리
                    </router-link>
                </ul>
            </li>
            <!--결제관리 끝-->

            <li v-if="this.user.user_division == '공급자'" class="line"></li>

            <!--스토어 관리 시작 (공급자)-->
            <li v-if="this.user.user_division == '공급자'">
                <div id="store" class="mainMenu" @click="this.$store.commit('alert', '준비중입니다.')">
                    <div class="icon_wrap">
                        <img src="@/assets/images/mypage/icon_tap_store.svg" alt="스토어" />
                    </div>
                    <p>스토어 관리</p>
                </div>
            </li>
            <!--스토어 관리 끝 (공급자)-->
            
            <li v-if="!this.user.user_division" class="line"></li>
            <li v-if="!this.user.user_division">
                <router-link
                    to="/register/partner"
                    class="mainMenu"
                >
                    <div class="icon_wrap">
                        <img src="@/assets/images/mypage/icon_tap_09.svg" alt="광고" />
                    </div>
                    <p>파트너등록</p>
                </router-link>
            </li>

            <li class="line"></li>
            <li>
                <div id="store" class="mainMenu" style="margin-left: 3rem" @click="goSurvey()">
                    <div class="icon_wrap">
                        <img src="@/assets/images/mypage/icon_tap_store.svg" alt="트렌디언서" />
                    </div>
                    <p style="width: 150px; text-align: center">트렌디언서 Survey</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "MypageLeftTab",
    data() {
        return {
            // tab: "", //'deal', 'token','ad','',
        };
    },
    computed: {
        ...mapState("user", ["user"]),
        dealTab() {
            if (this.user.user_division === "판매자") {
                return "/mypage/ManageMatching";
            } else {
                return "/mypage/ManageProduct";
            }
        },
        tab() {
            if (
                this.$route.path === "/mypage/ManageProduct" ||
                this.$route.path === "/mypage/SupplyProvider" ||
                this.$route.path === "/mypage/RequestProvider" ||
                this.$route.path === "/mypage/RequestSeller" ||
                this.$route.path === "/mypage/CompleteProvider" ||
                this.$route.path === "/mypage/ProposalProvider" ||
                this.$route.path === "/mypage/SupplySeller" ||
                this.$route.path === "/mypage/CompleteSeller" ||
                this.$route.path === "/mypage/ProposalSeller" ||
                this.$route.path === "/mypage/ManageMatching"
            ) {
                return "deal";
            } else if (
                this.$route.path === "/mypage/myToken" ||
                this.$route.path === "/mypage/cardManage" ||
                this.$route.path === "/mypage/accountManage" ||
                this.$route.path === "/mypage/MypageBuyToken"
            ) {
                return "token";
            } else if (this.$route.path === "/mypage/AdRegistration" || this.$route.path === "/mypage/AdDetail") {
                return "ad";
            }

            return "";
        },
        routerLinkActive() {
            return this.$route.path === "/mypage/ManageMatching" ||
                this.$route.path === "/mypage/SupplyProvider" ||
                this.$route.path === "/mypage/ManageProduct" ||
                this.$route.path === "/mypage/RequestProvider" ||
                this.$route.path === "/mypage/ProposalProvider" ||
                this.$route.path === "/mypage/CompleteProvider" ||
                this.$route.path === "/mypage/SupplySeller" ||
                this.$route.path === "/mypage/RequestSeller" ||
                this.$route.path === "/mypage/ProposalSeller" ||
                this.$route.path === "/mypage/CompleteSeller"
                ? "router-link-active"
                : "";
        },
        payRouterLinkActive() {
            return this.$route.path === "/mypage/cardManage" ||
                this.$route.path === "/mypage/myToken" ||
                this.$route.path === "/mypage/accountManage" ||
                this.$route.path === "/mypage/MypageBuyToken"
                ? "router-link-active"
                : "";
        },
        sampleRouterLinkActive() {
            return this.$route.path === "/mypage/SampleSeller" ? "router-link-active" : "";
        },
        adRouterLinkActive() {
            return this.$route.path === "/mypage/AdDetail" ? "router-link-active" : "";
        },
    },
    mounted(){
        const horizontal = document.getElementById('scrollHorizontal');
        horizontal.addEventListener('wheel', this.scrollHorizontal);
    },
    beforeUnmount(){
        const horizontal = document.getElementById('scrollHorizontal');
        horizontal.removeEventListener('wheel', this.scrollHorizontal);
    },
    methods: {
        goSurvey() {
            window.open("https://docs.google.com/forms/d/1t1TcdK5W3ND4sEJynI0ZbFk1M5hYRhvSzZgHm97fbPc/edit");
        },
        scrollHorizontal(event){
            event.preventDefault();
            const horizontal = document.getElementById('scrollHorizontal');
            horizontal.scrollLeft += event.deltaY;
        }
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_leftTab.css"></style>
