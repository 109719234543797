export const nickNameFormat = {
    methods: {
        nickNameFormat(words) {
            if (typeof words != 'string') {
                return;
            }

            const regex = /^[가-힣a-zA-Z0-9~!?@#$%^&*+=()[\]/'",.<>:;_-]+$/

            // 4byte 이상, 15자 이하
            if (getByte(words) >= 4 && words.length <= 15 && regex.test(words)) {
                return true;
            } else {
                return false;
            }

            // 바이트 수 계산
            function getByte(str) {
                const strLength = str.length;
                let strByteLength = 0;
                for (let i = 0; i < strLength; i++) {
                    if (escape(str.charAt(i)).length >= 4)
                        strByteLength += 2;
                    else if (escape(str.charAt(i)) == "%A7")
                        strByteLength += 2;
                    else
                    if (escape(str.charAt(i)) != "%0D")
                        strByteLength++;
                }
                return strByteLength;
            }
        }
    },
}