<template>
    <div class="payManage myToken">
        <div class="token_wrap">
            <h1>My 바이셀토큰</h1>
            <div class="pay_wrap">
                <div class="text">
                    <img src="@/assets/images/main/illu_token.svg" alt="토큰" />
                    <p>{{ numberFormat(token) }}<span>개</span></p>
                </div>
                <router-link to="/mypage/MypageBuyToken"
                    ><span></span> <button type="button" class="sub_button">토큰충전</button></router-link
                >
            </div>
        </div>

        <div class="list_wrap">
            <div class="toggle_wrap">
                <div class="toggle" :style="toggleStyle"></div>
                <ul class="main_menu">
                    <li :class="this.history_tab === 0 ? 'on' : ''" @click="clickTab(0)">적립 내역</li>
                    <li :class="this.history_tab === 0 ? '' : 'on'" @click="clickTab(1)">사용 내역</li>
                </ul>
            </div>

            <div class="filter_wrap fill">
                <div class="fill_select">
                    <select v-model="search_category">
                        <option value="">구분 (전체)</option>
                        <option
                            v-for="item in this.history_tab === 0 ? buy_category : use_category"
                            :value="item"
                            :key="item.toString()"
                        >
                            {{ item }}
                        </option>
                    </select>
                </div>

                <div class="fill_date_wrap">

                    <div class="fill_date">
                        <input
                            type="date"
                            data-placeholder="YYYY-MM-DD"
                            required
                            area-required="true"
                            v-model="start_date"
                            id="start_date"
                        />
                    </div>
                    <span>~</span>
                    <div class="fill_date">
                        <input
                            type="date"
                            data-placeholder="YYYY-MM-DD"
                            required
                            area-required="true"
                            v-model="finish_date"
                            id="finish_date"
                        />
                    </div>

                </div>

                <button type="button" class="sub_button" @click="search">적용</button>
            </div>

            <div class="common_table" v-if="history_tab === 0">
                <ul class="top">
                    <li class="w60">No.</li>
                    <li class="product">구분</li>
                    <li class="w100">수단</li>
                    <li class="w100">수량</li>
                    <li class="w100">상태</li>
                    <li class="w120">일시</li>
                </ul>
                <ul class="content" v-for="(history, idx) in this.sliceList(this.tokenHistory)" :key="String(history.idx)">
                    <li class="w60 rank">{{ tokenHistory.length - (currentPage-1)*maxShowList - idx }}</li>
                    <li class="product">{{ history.token_division }}</li>
                    <li class="w100 sub">
                        {{ history.purchase_way ? history.purchase_way : "-" }}
                    </li>
                    <li class="w100 sub">{{ numberFormat(history.token) }} 개</li>
                    <li class="w100 sub">
                        {{ history.state }}
                    </li>
                    <li class="w120 sub">{{ dateFormat(history.enrollment_date) }}</li>
                </ul>
            </div>

            <div class="common_table common_table2" v-else>
                <ul class="top">
                    <li class="w40">No.</li>
                    <li class="product">구분</li>
                    <li class="w100">수량</li>
                    <li class="w120">일시</li>
                </ul>
                <ul class="content" v-for="(history, idx) in this.sliceList(this.tokenHistory)" :key="String(history.idx)">
                    <li class="w40 rank">{{ tokenHistory.length - (currentPage-1)*maxShowList - idx }}</li>
                    <li class="product"><p>{{ history.token_division }}</p></li>
                    <li class="w100 sub">{{ numberFormat(history.token) }} 개</li>
                    <li class="w120 sub">{{ dateFormat(history.enrollment_date) }}</li>
                </ul>
            </div>

            <BoardPagination
                :listLength="this.tokenHistory.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
        </div>
    </div>
</template>

<script>
import BoardPagination from '@/components/BoardPagination'

import { mapState } from "vuex";
import { fetchData } from "@/utils/fetchData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";

export default {
    components : {
        BoardPagination,
    },
    mixins: [fetchData, numberFormat, dateFormat],
    data() {
        return {
            token: 0,

            search_category: "",
            buy_category: [],
            use_category: [],

            tokenHistory: [],
            buyHistory: [],
            useHistory: [],
            start_date: "", //반년 전
            finish_date: "",
            history_tab: 0,

            currentPage : 1,    // 현재 페이지
            maxShowList : 10,   // 한 페이지 보일 최대 리스트수
            maxShowPage : 5,    // 한 화면에 보일 최대 페이지수
        };
    },
    computed: {
        ...mapState("user", ["user"]),
        toggleStyle() {
            return this.history_tab === 0 ? { left: "0px" } : { left: "120px" };
        },
    },
    watch : {
        history_tab(){
            this.currentPage = 1;
        }
    },
    created() {
        this.fetchUserData();
        this.fetchTokenData();
        const today = new Date();
        this.finish_date = today.toISOString().slice(0, 10);
        const lastHalfYear = new Date(today.setMonth(today.getMonth() - 6)).toISOString().slice(0, 10);
        this.start_date = lastHalfYear;
    },
    methods: {
        fetchTokenData() {
            this.fetchData(
                "/getToken",
                (data) => {
                    const list = data.list;
                    this.buyHistory = list.filter((item) => item.use_division === "적립");
                    this.useHistory = list.filter((item) => item.use_division !== "적립");
                    this.buy_category = data.acc_group.map((item) => item.token_division);
                    this.use_category = data.use_group.map((item) => item.token_division);

                    /**
                     * [{
                     * token_pk,
                     * token,
                     * token_division,
                     * use_division,
                     * enrollment_date,
                     * purchase_way,
                     * id
                     * }]
                     *
                     */
                    //          this.useHistory = data.filter((item) => item.use_division === "사용");
                    //          this.buyHistory = data.filter((item) => item.use_division === "구매");

                    //         this.tokenHistory = this.historyData;
                    this.tokenHistory = this.buyHistory;
                    this.search();
                },
            );
        },
        fetchUserData() {
            this.fetchData(
                "/getMypage",
                (data) => {
                    this.token = data.data.token;
                },
            );
        },
        clickTab(tab) {
            this.search_category = "";
            this.history_tab = tab;
            this.tokenHistory = tab === 0 ? this.buyHistory : this.useHistory;
            this.search();
        },
        searchHistory(search, start_date, finish_date) {
            const startDate = new Date(start_date);
            const finishDate = new Date(finish_date);
            finishDate.setDate(finishDate.getDate() + 1);
            const list = this.history_tab === 0 ? this.buyHistory : this.useHistory;

            if (search === "") {
                return list.filter(
                    (item) =>
                        startDate <= new Date(item.enrollment_date) && finishDate >= new Date(item.enrollment_date),
                );
            } else {
                return list.filter(
                    (item) =>
                        item.token_division.replaceAll(" ", "") === search.replaceAll(" ", "") &&
                        startDate <= new Date(item.enrollment_date) &&
                        finishDate >= new Date(item.enrollment_date),
                );
            }
        },
        // 페이지 변경
        setPage(num){
            this.currentPage = num;
        },
        sliceList(arr){
            return arr.slice( (this.currentPage-1)*this.maxShowList, this.currentPage*this.maxShowList )
        },
        search() {
            this.tokenHistory = this.searchHistory(this.search_category, this.start_date, this.finish_date);
            this.tokenHistory.sort((a, b) => new Date(b.enrollment_date) - new Date(a.enrollment_date));
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_pay.css"></style>
