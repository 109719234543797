<template>
    <div class="mypage_profile fill">
        <!--프로필 정보 시작-->
        <div class="profile_wrap" id="profile_box">
            <h1 class="fill_box_title">프로필 이미지 <strong>(최대 2.5MB)</strong></h1>
            <div class="fill_image">
                <div
                    v-bind:class="
                        this.user.user_division == '판매자' ? 'box_seller_partner_image' : 'box_provider_partner_image'
                    "
                >
                    <img
                        v-if="this.user.path && this.user.save_name && !this.image"
                        :src="this.user.path + this.user.save_name"
                        :alt="this.user.original_name"
                    />
                    <img v-if="this.image" :src="imageThumb" alt="profile" />
                </div>
                <input
                    type="file"
                    style="display: none"
                    @change="onImageSelected($event)"
                    accept="image/*"
                    capture="camera"
                    ref="imageInput"
                    @click="
                        () => {
                            this.$refs.imageInput.value = '';
                        }
                    "
                />
                <div class="btn_add" @click="$refs.imageInput.click()">
                    <img src="@/assets/images/register/icon_camera.svg" alt="카메라" />
                </div>
            </div>

            <h1 class="fill_box_title">프로필 정보입력</h1>
            <div class="fill_text fill_name">
                <h1>닉네임 <span class="star">*</span></h1>
                <input
                    type="text"
                    placeholder="최대 15자 입력 가능 (초성 불가)"
                    id="nick"
                    :value="nick"
                    @input="handleSearchInput"
                />
                <div class="check ok" ref="nickCheck">
                    <img src="@/assets/images/register/icon_check.svg" alt="체크" />
                    <img src="@/assets/images/common/close_w.svg" alt="체크" />
                </div>
            </div>
            <div class="fill_explain">
                <h1>소개글 <span class="star">*</span></h1>
                <textarea
                    id="introduce"
                    placeholder="전화번호 / 이메일 / 웹사이트 등을 입력하셔서 노출을 원하시면 입점 광고비가 청구되거나 토큰에서 차감됩니다. 회원님의 사업 소개만 입력해주세요. &#10;(최대 500자)"
                    v-model="introduce"
                    @input="introduce = $event.target.value"
                    maxlength="500"
                ></textarea>
            </div>
            <div class="fill_text">
                <h1>핸드폰번호 <span class="star">*</span></h1>
                <input type="text" id="contact" placeholder="ex) 010-1234-5678" maxlength="13" v-model="contact" />
            </div>

            <h1 class="fill_box_title url_title">홈페이지 주소</h1>
            <div v-if="this.user.user_division === '판매자'" class="type" style="margin-bottom 1rem">
                <label> <input type="checkbox" v-model="isInfluencer" /> 인플루언서</label>
            </div>

            <div class="fill_text">
                <h1>URL 주소</h1>
                <input
                    id="pageurl"
                    type="text"
                    :placeholder="
                        isInfluencer
                            ? '(필수) SNS 사이트 또는 유튜브 등 주소를 등록해주세요.'
                            : '(선택) SNS 사이트 또는 유튜브 등 주소를 등록해주세요.'
                    "
                    name="url"
                    v-model="url"
                />
            </div>
        </div>
        <!--프로필 정보 끝-->

        <!--사업자 정보, 해시태그 시작-->
        <div class="fill_double">
            <div class="fill_business" id="business_box">
                <h1 class="fill_box_title">사업자 정보 입력</h1>
                <div class="fill_text fill_cno">
                    <h1>사업자 번호<span class="star">*</span></h1>
                    <input type="text" id="cno" placeholder="123-12-12345" v-model="cno" maxlength="12" />
                </div>

                <h1 class="fill_box_title">사업자 유형<span class="star">*</span></h1>
                <div class="fill_radio fill_check fill_ctype">
                    <div class="type">
                        <label v-for="(item, i) in this.basicData.businessman_division" :key="i">
                            <input
                                v-if="i == 0"
                                type="radio"
                                id="business_type"
                                name="business_type"
                                :value="item.businessman_division_pk"
                                v-model="ctype"
                                @click="ctypeCheck($event)"
                            />
                            <input
                                v-else
                                type="radio"
                                name="business_type"
                                :value="item.businessman_division_pk"
                                v-model="ctype"
                                @click="ctypeCheck($event)"
                            />
                            {{ item.content }}
                        </label>
                    </div>
                </div>

                <h1 class="fill_box_title">사업자등록증 인증 <strong>(최대 500MB)</strong></h1>
                <div class="fill_file">
                    <button @click="$refs.fileInput.click()">파일선택</button>
                    <input
                        type="file"
                        style="display: none"
                        @change="onFileSelected"
                        ref="fileInput"
                        @click="
                            () => {
                                this.$refs.fileInput.value = '';
                            }
                        "
                    />
                    <div v-if="this.fileName" class="fill_file_list">
                        <p>{{ this.fileName }}</p>
                        <button
                            type="button"
                            class="btn_delete"
                            @click="
                                () => {
                                    this.file = null;
                                    this.fileName = '';
                                }
                            "
                        >
                            <img src="@/assets/images/common/close_w.svg" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="fill_hashTag">
                <h1 class="fill_box_title">해시태그(검색키워드) 정보</h1>
                <div class="fill_text">
                    <h1>해시태그</h1>
                    <input
                        type="text"
                        placeholder="키워드를 검색하시고 Enter키를 눌러주세요"
                        name="keyword"
                        @keyup.enter="addHashTag($event)"
                    />
                    <div class="hashtag">
                        <div class="hashtag_word" v-for="(item, i) in this.hashtag" :key="i">
                            <span>{{ item }}</span>
                            <img src="@/assets/images/common/close.svg" @click="deleteHashTag(i)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--사업자 정보, 해시태그 끝-->

        <!-- 공통 - 판매 카테고리 시작 -->
        <div class="fill_product_type fill_check" id="category_box">
            <h1 class="fill_box_title" v-if="this.user.user_division == '판매자'">
                판매 카테고리<span class="star">*</span><strong>(최대 3개)</strong>
            </h1>
            <h1 class="fill_box_title" v-else>공급 카테고리<span class="star">*</span><strong>(최대 3개)</strong></h1>
            <div class="type">
                <template v-if="this.user.user_division == '공급자'">
                    <label
                        v-for="(item, i) in this.basicData.category.filter((el) => el.level == 1)"
                        :key="i"
                        :id="`category${i}`"
                    >
                        <input
                            type="checkbox"
                            :id="`partner_category${item.category_pk}`"
                            name="partner_category"
                            @change="categoryCheck($event, item.category_pk)"
                        />
                        {{ item.content }}
                    </label>
                </template>
                <template v-else>
                    <label
                        v-for="(item, i) in this.basicData.category.filter((el) => el.level == 2)"
                        :key="i"
                        :id="`category${i}`"
                    >
                        <input
                            type="checkbox"
                            :id="`partner_category${item.category_pk}`"
                            name="partner_category"
                            v-model="item.checked"
                            @change="categoryCheck($event, item.category_pk)"
                        />
                        {{ item.content }}
                    </label>
                </template>
            </div>
        </div>
        <!-- 공통 - 판매 카테고리 끝 -->

        <!-- 판매자 - 선호 판매 방법 시작-->
        <div class="fill_way fill_check" id="way_box" v-if="this.user.user_division == '판매자'">
            <h1 class="fill_box_title">선호 판매 방법<span class="star">*</span></h1>
            <div class="type">
                <label v-for="(item, i) in this.basicData.way" :key="i" :id="`way${i}`">
                    <input
                        type="checkbox"
                        :id="`partner_way${item.way_pk}`"
                        name="partner_way"
                        v-model="item.checked"
                        @change="wayCheck($event, item.way_pk)"
                    />
                    {{ item.user_content }}
                </label>
            </div>
        </div>
        <!-- 판매자 - 선호 판매 방법 끝-->

        <!-- 공통 - 경력 정보 시작 -->
        <div class="fill_career" id="career_box">
            <h1 class="fill_box_title">경력 정보</h1>
            <div class="fill_select">
                <h1>판매 경력<span class="star">*</span></h1>
                <select class="career" v-model="career" id="career">
                    <option value="">선택</option>
                    <template v-if="this.user.user_division == '판매자'">
                        <option v-for="(item, i) in this.basicData.career" :key="i" :value="item.career_pk">
                            {{ item.sale_user_content }}
                        </option>
                    </template>
                    <template v-else>
                        <option v-for="(item, i) in this.basicData.career" :key="i" :value="item.career_pk">
                            {{ item.supply_user_content }}
                        </option>
                    </template>
                </select>
            </div>
        </div>
        <!-- 공통 - 경력정보 끝 -->

        <!-- 판매자 - 예산정보 시작 -->
        <div class="fill_budget" id="budget_box" v-if="this.user.user_division == '판매자'">
            <h1 class="fill_box_title">예산 정보</h1>
            <div class="fill_select">
                <h1>준비 예산<span class="star">*</span></h1>
                <select v-model="budget" id="budget" @change="onChangeBudget">
                    <option value="">선택</option>
                    <option v-for="(item, i) in this.basicData.budget" :key="i" :value="item.budget_pk">
                        {{ item.content }}
                    </option>
                </select>
            </div>
        </div>
        <!-- 판매자 - 예산정보 끝 -->

        <!-- 공통 - 활동 지역 시작-->
        <div class="fill_place fill_check" id="area_box">
            <h1 class="fill_box_title">활동 지역<span class="star">*</span></h1>
            <div class="box_partner_place">
                <div class="type national">
                    <label class="check_all">
                        <input type="checkbox" id="allNational" @click="checkAll($event, '국내')" />
                        국내
                    </label>
                    <label v-for="(item, i) in this.basicData.area.filter((el) => el.division == '국내')" :key="i">
                        <input
                            class="area_check"
                            type="checkbox"
                            name="national"
                            :id="`partner_area${item.area_pk}`"
                            :value="item.area_pk"
                            v-model="item.checked"
                            @click="areaCheck($event, '국내')"
                        />
                        {{ item.content }}
                    </label>
                </div>

                <div class="type international">
                    <label class="check_all">
                        <input type="checkbox" id="allInternational" @click="checkAll($event, '전세계')" />
                        전세계
                    </label>
                    <label v-for="(item, i) in this.basicData.area.filter((el) => el.division == '전세계')" :key="i">
                        <input
                            class="area_check"
                            type="checkbox"
                            name="international"
                            :id="`partner_area${item.area_pk}`"
                            :value="item.area_pk"
                            v-model="item.checked"
                            @click="areaCheck($event, '전세계')"
                        />
                        {{ item.content }}
                    </label>
                </div>
            </div>
        </div>
        <!-- 공통 - 활동 지역 끝-->

        <!-- 공급자 - 은행 계좌 시작 -->
        <div class="fill_bank" id="bank_box" v-if="this.user.user_division == '공급자'">
            <h1 class="fill_box_title fill_bank_title">은행 계좌 정보</h1>

            <div class="fill_select">
                <h1>은행<span class="star">*</span></h1>
                <select class="bank" v-model="bank" id="bank">
                    <option value="">선택</option>
                    <option v-for="(item, i) in this.basicData.bank" :key="i" :value="item.bank_pk">
                        {{ item.content }}
                    </option>
                </select>
            </div>

            <div class="fill_text fill_accholder">
                <h1>예금주<span class="star">*</span></h1>
                <input
                    type="text"
                    id="accholder"
                    placeholder="ex) 홍길동"
                    v-model="accholder"
                    maxlength="15"
                />
            </div>

            <div class="fill_text fill_accnum">
                <h1>계좌 번호<span class="star">*</span></h1>
                <input
                    type="text"
                    id="accnum"
                    placeholder="숫자만 입력해주세요"
                    v-model="accnum"
                    maxlength="18"
                />
            </div>
        </div>
        <!-- 공급자 - 은행 계좌 끝 -->

        <!-- 판매자 - 판매채널 시작-->
        <div class="fill_channel fill_check" v-if="this.user.user_division == '판매자'" id="channel_box">
            <h1 class="fill_box_title">판매 채널<span class="star">*</span><strong>(최대 3개)</strong></h1>
            <div class="type">
                <label v-for="item in this.basicData.channel" :key="item.channel_pk">
                    <input
                        type="checkbox"
                        name="channel"
                        v-model="item.checked"
                        @click="channelCheck(item.channel_pk)"
                        :id="`partner_channel${item.channel_pk}`"
                    />
                    {{ item.content }} <span></span>
                </label>
            </div>
        </div>
        <!--판매채널 끝-->

        <div class="btn_wrap">
            <button type="button" class="common_button" @click="submit">저장</button>
            <button type="button" class="sub_button" @click="goMypageHome">취소</button>
        </div>
    </div>
</template>

<script>
import { nickNameFormat } from "@/utils/nickNameFormat";
import { phoneFormat } from "@/utils/phoneFormat";
import { businessRegFormat } from "@/utils/businessRegFormat";
import { fetchData } from "@/utils/fetchData";
import { postData } from "@/utils/postData";
import { mapState } from "vuex";

export default {
    created() {
        this.fetchBasicData();
    },
    data() {
        return {
            // 보낼 데이터
            image: null, // 공통 - 프로필이미지
            imageThumb: null, // 공통 - 프로필이미지 썸네일
            nick: "", // 공통 - 닉네임 (*)
            introduce: "", // 공통 - 소개글 (*)
            contact: "", // 공통 - 핸드폰번호 (*)
            url: "", // 공통 - 홈페이지 url
            cno: "", // 공통 - 사업자 번호 (*)
            ctype: "", // 공통 - 사업자 유형 (*)
            file: null, // 공통 - 사업자 등록증인증
            fileName: "",
            hashtag: [], // 공통 - 해시태그
            category: [], // 공통 - 판매/공급 상품 분야 (*)
            career: "", // 공통 - 경력 정보 (*)
            area: [], // 공통 - 활동 지역 (*)

            isInfluencer: false, //판매자 - 인플루언서
            way: [], // 판매자 - 판매 방법 (*)
            budget: "", // 판매자 - 예산 정보 (*)
            channel: [], // 판매자 - 판매 채널 (*)

            bank: "", // 공급자 - 은행(*)
            accholder: "", // 공급자 - 예금주(*)
            accnum: "", // 공급자 - 계좌번호(*)

            pre_partnerData: {}, // 새로 입력된 값 있는지 여부 체크용

            bounce: null, // debounce timeout ID
            nickCheck: true,
            basicData: {
                area: [],
                category: [],
                channel: [],
                budget: [],
                career: [],
                start: [],
                way: [],
            },
        };
    },
    mixins: [nickNameFormat, phoneFormat, businessRegFormat, fetchData, postData],
    computed: {
        ...mapState("user", ["user"]),
        ...mapState("access_token", ["access_token"]),
    },
    watch: {
        // 핸드폰 번호에 '-' 넣기
        contact(newPhone) {
            this.contact = this.phoneFormat(newPhone);
        },
        // 매출비중 보고 총 매출비중 구함
        salesPercent: {
            deep: true,
            handler(newVal) {
                this.totalPercent = newVal.reduce((pre, cur) => Number(pre) + Number(cur));
                if (this.totalPercent < 100) {
                    document.getElementById("all_percent").classList.add("yet");
                } else {
                    document.getElementById("all_percent").classList.remove("yet");
                }
            },
        },
        // 사업자 등록번호
        cno(newNum) {
            this.cno = this.businessRegFormat(newNum);
        },
    },
    methods: {
        // 기본 목록 데이터 불러오기
        fetchBasicData() {
            this.fetchData("/getBasic?page=partner", (data) => {
                this.basicData = data;
                this.fetchPartnerData();
            });
        },
        // 파트너 정보 불러오기
        fetchPartnerData() {
            this.fetchData(
                "/getPartnerInfo",
                (data) => {
                    const partnerData = data.data;
                    this.pre_partnerData = partnerData;
                    //this.image = partnerData.path + partnerData.save_name;
                    this.imageThumb = partnerData.path + partnerData.save_name;
                    this.nick = partnerData.name;
                    this.introduce = partnerData.introduce ? partnerData.introduce : "";
                    this.contact = partnerData.contact ? partnerData.contact : "";
                    this.url = partnerData.url ? partnerData.url : "";
                    this.cno = partnerData.businessman_number;
                    this.ctype = partnerData.businessman_division_pk;
                    this.file = partnerData.businessman_path + partnerData.businessman_save_name;
                    this.fileName = partnerData.businessman_original_name;
                    this.hashtag = partnerData.hashtag.map((item) => item.content);
                    this.isInfluencer = partnerData.influencer_yn === "true" ? true : false;
                    if (partnerData.category.length != 0) {
                        partnerData.category.forEach((item) => this.category.push(item));
                        this.basicData.category.forEach((data) => {
                            if (this.category.find((item) => item.category_pk === data.category_pk)) {
                                document.querySelector("#partner_category" + data.category_pk).checked = true;
                                document
                                    .querySelector("#partner_category" + data.category_pk)
                                    .parentElement.classList.add("selected");
                            }
                        });
                        const category = [];
                        this.category.forEach((data) => {
                            category.push(data.category_pk);
                        });
                        this.category = category;
                    }

                    if (partnerData.way.length != 0) {
                        partnerData.way.forEach((item) => this.way.push(item));
                        this.basicData.way.forEach((data) => {
                            if (this.way.find((item) => item.way_pk === data.way_pk)) {
                                document.querySelector("#partner_way" + data.way_pk).checked = true;
                                document
                                    .querySelector("#partner_way" + data.way_pk)
                                    .parentElement.classList.add("selected");
                            }
                        });

                        const way = [];
                        this.way.forEach((data) => {
                            way.push(data.way_pk);
                        });
                        this.way = way;
                    }

                    this.career = partnerData.career;
                    this.budget = partnerData.budget;
                    if (partnerData.area.length != 0) {
                        partnerData.area.forEach((item) => this.area.push(item));
                        this.basicData.area.forEach((data) => {
                            if (this.area.find((item) => item.area_pk === data.area_pk)) {
                                document.querySelector("#partner_area" + data.area_pk).checked = true;
                                document
                                    .querySelector("#partner_area" + data.area_pk)
                                    .parentElement.classList.add("selected");
                            }
                        });
                        const area = [];
                        this.area.forEach((data) => {
                            area.push(data.area_pk);
                        });
                        this.area = area;
                    }
                    if (partnerData.channel.length != 0) {
                        partnerData.channel.forEach((item) => this.channel.push(item));
                        this.channel.forEach((data) => {
                            if (this.basicData.channel.find((item) => item.channel_pk === data.channel_pk)) {
                                document.querySelector("#partner_channel" + data.channel_pk).checked = true;
                                document
                                    .querySelector("#partner_channel" + data.channel_pk)
                                    .parentElement.classList.add("selected");
                                document.querySelector(
                                    "#partner_channel" + data.channel_pk,
                                ).nextElementSibling.innerText = `${data.seq}순위`;
                            }
                        });
                    }
                    this.bank = partnerData.bank ? partnerData.bank : "";
                    this.accholder = partnerData.accholder;
                    this.accnum = partnerData.accnum;
                },
            );
        },
        verifyNick(newNickname) {
            this.fetchData(
                "/verifyNick",
                (data) => {
                    this.nickCheck = data.success;
                    if (data.success && this.nickNameFormat(this.nick)) {
                        this.$refs.nickCheck.classList.add("ok");
                    } else {
                        this.$refs.nickCheck.classList.remove("ok");
                    }
                },
                {
                    nick: newNickname,
                },
            );
        },
        handleSearchInput(e) {
            this.nick = e.target.value;
            if (this.nick.length !== 0) {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    if (this.user.name === this.nick) {
                        this.nickCheck = true;
                        this.$refs.nickCheck.classList.add("ok");
                    } else {
                        this.verifyNick(this.nick);
                    }
                }, 500);
            } else {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.$refs.nickCheck.classList.remove("ok");
                    this.nickCheck = false;
                }, 500);
            }
        },
        // 프로필 이미지 선택
        onImageSelected(event) {
            // 파일이 존재하는지
            if (event.target.files && event.target.files[0]) {
                // 이미지일 때만 this.image에 넣음
                if (event.target.files[0].type.includes("image") && !event.target.files[0].type.includes("gif")) {
                    // 2MB보다 크면 안받도록
                    if (event.target.files[0].size > 1048576 * 2.5) {
                        this.$store.commit("alert", "2.5MB 이하 이미지를 선택해 주세요");
                    } else {
                        this.image = event.target.files[0];
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            this.imageThumb = event.target.result;
                        };
                        reader.readAsDataURL(event.target.files[0]);
                    }
                } else {
                    this.$store.commit("alert", "이미지를 선택해주세요. (.gif 제외)");
                }
            }
        },
        // 판매 상품 선택
        categoryCheck(event, pk) {
            const element = document.getElementById(`partner_category${pk}`);
            // 체크 해제일 경우 해당 pk 배열에서 제거
            if (!event.currentTarget.checked) {
                this.category.splice(this.category.indexOf(pk), 1);
                element.parentElement.classList.remove("selected");
            } else {
                // 3개 이하일 때만 추가
                if (this.category.length < 3) {
                    this.category.push(pk);
                    element.parentElement.classList.add("selected");
                } else {
                    event.currentTarget.checked = false;
                }
            }
        },
        // 판매/공급 방식 선택
        wayCheck(event, pk) {
            const element = document.getElementById(`partner_way${pk}`);
            // 체크 해제일 경우 해당 pk 배열에서 제거
            if (!event.currentTarget.checked) {
                this.way.splice(this.way.indexOf(pk), 1);
                element.parentElement.classList.remove("selected");
            } else {
                this.way.push(pk);
                element.parentElement.classList.add("selected");
            }
        },
        // 판매 체널 선택
        channelCheck(pk) {
            const element = document.getElementById(`partner_channel${pk}`);

            // 체크 해제일 경우 해당 pk 배열에서 제거
            if (!element.checked) {
                element.parentElement.classList.remove("selected");
                element.nextElementSibling.innerText = "";
                element.checked = false;

                var tmp;
                tmp = this.channel.filter((item) => item.channel_pk !== pk);

                this.channel = tmp.concat();

                this.channel.forEach((data, i) => {
                    document.getElementById(`partner_channel${data.channel_pk}`).nextElementSibling.innerText = `${
                        i + 1
                    }순위`;
                });
            } else {
                // 3개 이하일 때만 추가
                if (this.channel.length < 3) {
                    element.parentElement.classList.add("selected");
                    element.nextElementSibling.innerText = `${this.channel.length + 1}순위`;
                    this.channel.push({ channel_pk: pk });
                } else {
                    element.checked = false;
                }
            }
        },
        // 사업자 유형 선택
        ctypeCheck(event) {
            this.ctype = event.currentTarget.value;
        },
        // 사업등록증 인증
        onFileSelected(event) {
            // 파일이 존재하는지
            if (event.target.files && event.target.files[0]) {
                // 500MB보다 크면 안받도록
                if (event.target.files[0].size > 1048576 * 500) {
                    this.$store.commit("alert", "500MB 이하 파일을 선택해 주세요");
                } else {
                    this.file = event.target.files[0];
                    this.fileName = this.file.name;
                }
            }
        },
        // 해시태그 추가
        addHashTag(event) {
            // 공백 엔터시 예외
            if (event.currentTarget.value != "") {
                // 이미 있으면 제외
                if (!this.hashtag.includes(event.currentTarget.value)) {
                    this.hashtag.push(event.currentTarget.value);
                } else {
                    this.$store.commit("alert", "이미 입력한 해시태그 입니다.");
                }
            }
            event.currentTarget.value = "";
        },
        // 해시태그 제거
        deleteHashTag(i) {
            this.hashtag.splice(i, 1);
        },
        // 활동 지역 전부 체크
        checkAll(event, division) {
            if (division == "국내") {
                const national = document.querySelectorAll('[name="national"]');
                if (event.currentTarget.checked) {
                    national.forEach((el) => {
                        el.checked = true;
                    });
                    this.basicData.area.forEach((item) => {
                        if (item.division === "국내") {
                            item.checked = true;
                            document.querySelector("#allNational").parentElement.classList.add("selected");
                            document
                                .querySelector("#partner_area" + item.area_pk)
                                .parentElement.classList.add("selected");
                        }
                    });
                } else {
                    national.forEach((el) => {
                        el.checked = false;
                    });
                    this.basicData.area.forEach((item) => {
                        if (item.division === "국내") {
                            item.checked = false;
                            document.querySelector("#allNational").parentElement.classList.remove("selected");
                            document
                                .querySelector("#partner_area" + item.area_pk)
                                .parentElement.classList.remove("selected");
                        }
                    });
                }
            } else {
                const international = document.querySelectorAll('[name="international"]');
                if (event.currentTarget.checked) {
                    international.forEach((el) => {
                        el.checked = true;
                    });
                    this.basicData.area.forEach((item) => {
                        if (item.division === "전세계") {
                            item.checked = true;
                            document.querySelector("#allInternational").parentElement.classList.add("selected");
                            document
                                .querySelector("#partner_area" + item.area_pk)
                                .parentElement.classList.add("selected");
                        }
                    });
                } else {
                    international.forEach((el) => {
                        el.checked = false;
                    });
                    this.basicData.area.forEach((item) => {
                        if (item.division === "전세계") {
                            item.checked = false;
                            document
                                .querySelector("#partner_area" + item.area_pk)
                                .parentElement.classList.remove("selected");
                            document.querySelector("#allInternational").parentElement.classList.remove("selected");
                        }
                    });
                }
            }
        },
        // 활동 지역 선택
        areaCheck(event, division) {
            this.basicData.area.forEach((item) => {
                if (item.area_pk === Number(event.currentTarget.value)) {
                    item.checked = event.currentTarget.checked;
                    this.area.push(item.area_pk);
                    document.querySelector("#partner_area" + item.area_pk).parentElement.classList.add("selected");
                }
            });
            if (!event.currentTarget.checked) {
                event.target.parentElement.classList.remove("selected");
            }

            if (!event.currentTarget.checked) {
                if (division == "국내") {
                    document.getElementById("allNational").checked = false;
                    document.querySelector(".national .check_all").classList.remove("selected");
                } else {
                    document.getElementById("allInternational").checked = false;
                    document.querySelector(".international .check_all").classList.remove("selected");
                }
            }
        },
        // 등록하기
        submit() {
            // 활동 지역 array 추가
            const area_check = document.querySelectorAll(".area_check");
            this.area = [];
            for (let i = 0; i < area_check.length; i++) {
                if (area_check[i].checked) {
                    this.area.push(area_check[i].value * 1);
                }
            }
            // 필수 입력 체크 공통
            if (!this.nick) {
                this.$store.commit("alert", "닉네임을 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("profile_box"));
                document.getElementById("nick").focus();
            } else if (!this.nickNameFormat(this.nick)) {
                this.$store.commit("alert", "유효한 닉네임을 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("profile_box"));
                document.getElementById("nick").focus();
            } else if (!this.nickCheck) {
                this.$store.commit("alert", "이미 사용중인 닉네임입니다.");
                this.toggleRequiredBox(document.getElementById("profile_box"));
                document.getElementById("nick").focus();
            } else if (!this.introduce) {
                this.$store.commit("alert", "소개글을 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("profile_box"));
                document.getElementById("introduce").focus();
            } else if (this.contact.length < 13) {
                this.$store.commit("alert", "핸드폰번호를 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("profile_box"));
                document.getElementById("contact").focus();
            } else if (
                this.user.user_division == "판매자" &&
                this.isInfluencer &&
                this.url.replaceAll(" ", "").length === 0
            ) {
                this.$store.commit("alert", "인플루언서 판매자는 홈페이지 주소를 입력하셔야 합니다.");
                this.toggleRequiredBox(document.getElementById("profile_box"));
                document.getElementById(`pageurl`).focus();
            } else if (this.cno.length < 12) {
                this.$store.commit("alert", "사업자번호를 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("business_box"));
                document.getElementById("cno").focus();
            } else if (!this.ctype) {
                this.$store.commit("alert", "사업자 유형을 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("business_box"));
                document.getElementById("business_type").focus();
            } else if (this.category.length === 0) {
                this.$store.commit("alert", "상품 분야를 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("category_box"));
                document.getElementById("category1").focus();
            } else if (!this.career) {
                this.$store.commit("alert", "경력정보를 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("career_box"));
                document.getElementById("career").focus();
            } else if (this.area.length == 0) {
                this.$store.commit("alert", "활동 지역을 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("area_box"));
                document.getElementById(`partner_area${this.basicData.area[0].area_pk}`).focus();
            } else {
                // 판매자일 경우

                if (this.user.user_division == "판매자") {
                    if (this.way.length === 0) {
                        this.$store.commit("alert", "선호 판매 방법을 선택해주세요.");
                        this.toggleRequiredBox(document.getElementById("way_box"));
                        document.getElementById("way1").focus();
                    } else if (!this.budget) {
                        this.$store.commit("alert", "예산 정보를 선택해주세요.");
                        this.toggleRequiredBox(document.getElementById("budget_box"));
                        document.getElementById("budget").focus();
                    } else if (this.channel.length == 0) {
                        this.$store.commit("alert", "판매 채널을 선택해주세요.");
                        this.toggleRequiredBox(document.getElementById("channel_box"));
                        document.getElementById(`channel${this.basicData.channel[0].channel_pk}`).focus();
                    } else {
                        const channel = [];
                        this.channel.forEach((data) => {
                            channel.push(data.channel_pk);
                        });

                        const formData = new FormData();
                        formData.append("access_token", this.access_token); // 공통 - 유저 토큰
                        formData.append("user_division", this.user.user_division); // 공통 - 유형 선택 (*)
                        formData.append("image", this.image); // 공통 - 프로필이미지
                        formData.append("nick", this.nick); // 공통 - 닉네임 (*)
                        formData.append("introduce", this.introduce); // 공통 - 소개글 (*)
                        formData.append("contact", this.contact); // 공통 - 핸드폰번호 (*)
                        formData.append("url", this.url); // 공통 - 홈페이지 url
                        formData.append("cno", this.cno); // 공통 - 사업자 번호 (*)
                        formData.append("ctype", this.ctype); // 공통 - 사업자 유형 (*)
                        formData.append("file", this.file); // 공통 - 사업자 등록증인증
                        formData.append("hashtag", this.hashtag.toString()); // 공통 - 해시태그
                        formData.append("category", this.category.toString()); // 공통 - 판매 상품 분야 (*)
                        formData.append("career", this.career); // 공통 - 경력 정보 (*)
                        formData.append("area", this.area.toString()); // 공통 - 활동 지역 (*)

                        formData.append("way", this.way.toString()); // 판매자 - 판매 방법 (*)
                        formData.append("budget", this.budget); // 판매자 - 예산 정보 (*)
                        formData.append("channel", channel.toString()); // 판매자 - 판매 채널 (*)
                        formData.append("influencer_yn", this.isInfluencer);

                        this.toggleRequiredBox(null);

                        this.postData("/savePartner", formData, (data) => {
                            this.$store.commit("setCheckPass", true);
                            this.$store.commit("alert", "파트너 프로필 정보를 저장하였습니다.");
                            this.$store.commit("user/signin", data.user);
                        });
                    }
                } else {
                    if (!this.bank) {
                        this.$store.commit("alert", "사용 계좌의 은행을 선택해주세요.");
                        this.toggleRequiredBox(document.getElementById("bank_box"));
                        document.getElementById("bank").focus();
                    } else if (!this.accholder) {
                        this.$store.commit("alert", "예금주를 입력해주세요.");
                        this.toggleRequiredBox(document.getElementById("bank_box"));
                        document.getElementById("accholder").focus();
                    } else if (!this.accnum) {
                        this.$store.commit("alert", "계좌번호를 입력해주세요.");
                        this.toggleRequiredBox(document.getElementById("bank_box"));
                        document.getElementById("accnum").focus();
                    } else {
                        this.toggleRequiredBox(null);

                        const formData = new FormData();
                        formData.append("access_token", this.access_token); // 공통 - 유저 토큰
                        formData.append("user_division", this.user.user_division); // 공통 - 유형 선택 (*)
                        formData.append("image", this.image); // 공통 - 프로필이미지
                        formData.append("nick", this.nick); // 공통 - 닉네임 (*)
                        formData.append("introduce", this.introduce); // 공통 - 소개글 (*)
                        formData.append("contact", this.contact); // 공통 - 핸드폰번호 (*)
                        formData.append("url", this.url); // 공통 - 홈페이지 url
                        formData.append("cno", this.cno); // 공통 - 사업자 번호 (*)
                        formData.append("ctype", this.ctype); // 공통 - 사업자 유형 (*)
                        formData.append("file", this.file); // 공통 - 사업자 등록증인증
                        formData.append("hashtag", this.hashtag.toString()); // 공통 - 해시태그
                        formData.append("category", this.category.toString()); // 공통 - 판매 상품 분야 (*)
                        formData.append("career", this.career); // 공통 - 경력 정보 (*)
                        formData.append("area", this.area.toString()); // 공통 - 활동 지역 (*)

                        formData.append("bank", this.bank); // 공급자 - 은행 (*)
                        formData.append("accholder", this.accholder); // 공급자 - 예금주 (*)
                        formData.append("accnum", this.accnum); // 공급자 - 계좌 번호 (*)
                        this.postData("/savePartner", formData, (data) => {
                            this.$store.commit("setCheckPass", true);
                            this.$store.commit("alert", "파트너 프로필 정보를 저장하였습니다.");
                            this.$store.commit("user/signin", data.user);
                        });
                    }
                }
            }
        },
        toggleRequiredBox(element = null) {
            const profileBox = document.getElementById("profile_box");
            const businessBox = document.getElementById("business_box");
            const categoryBox = document.getElementById("category_box");
            const careerBox = document.getElementById("career_box");
            const budgetBox = document.getElementById("budget_box");
            const wayBox = document.getElementById("way_box");
            const areaBox = document.getElementById("area_box");
            const channelBox = document.getElementById("channel_box");
            const bankBox = document.getElementById("bank_box");

            let requiredBoxes = [];
            // v-if로 가려지면 선택을 못하게되어 null이 들어옴
            if (this.user.user_division == "판매자") {
                requiredBoxes = [
                    profileBox,
                    businessBox,
                    categoryBox,
                    wayBox,
                    careerBox,
                    budgetBox,
                    areaBox,
                    channelBox,
                ];
            } else {
                requiredBoxes = [profileBox, businessBox, categoryBox, careerBox, areaBox, bankBox];
            }

            requiredBoxes.forEach((el) => el.classList.remove("required_box"));

            if (element != null) {
                element.classList.add("required_box");
            }
        },
        onChangeBudget(event) {
            this.budget = event.currentTarget.value;
        },
    },
};
</script>

<style scoped src="@/assets/css/components/Mypage/mypage_profile.css"></style>
